import React from 'react';

import { Header, List, Segment } from 'semantic-ui-react';

const Terms: React.FC = () => {
	return (
		<>
			<Segment basic size="large" style={{ padding: 0 }}>
				PONTO DO ESPORTE GESTAO ESPORTIVA LTDA., sociedade empresária limitada,
				inscrita no CNPJ sob o nº 21.435.248/0001-55, com sede na Cidade de São
				Paulo, Estado de São Paulo, na Av. Mofarrej, 706, apt 103, Vila
				Leopoldina, CEP 05311-000 (“Ponto do Esporte”), é a pessoa jurídica de
				direito privado responsável pela operação de uma plataforma dedicada à
				gestão e organização de eventos esportivos. Oferecemos serviços como
				administração de torneios, gestão de ligas e classificações, e alocação
				de espaços para prática esportiva. (“Plataforma”).
			</Segment>

			<Segment basic size="large" style={{ padding: 0 }}>
				Por intermédio destes Termos de Uso (“Termos”), a Ponto do Esporte
				apresenta aos usuários (“Usuários” ou “Usuário”) as condições essenciais
				para o uso dos serviços oferecidos na Plataforma.
			</Segment>

			<Segment basic size="large" style={{ padding: 0 }}>
				Ao utilizar a Plataforma ou os serviços ofertados pela Ponto do Esporte,
				os Usuários aceitam e se submetem às condições destes Termos de Uso e da
				Política de Privacidade.
			</Segment>

			<Header content="Disposições Gerais" dividing size="medium" />

			<Segment basic size="large" style={{ padding: 0 }}>
				<p>
					A plataforma Ponto do Esporte, funcionando como um Software as a
					Service (SaaS), é uma solução digital inovadora destinada à gestão e
					organização de eventos esportivos.
				</p>

				<p>
					O acesso e uso da Plataforma Ponto do Esporte são concedidos mediante
					a adesão a estes Termos de Uso, momento em que são geradas as
					credenciais de acesso do usuário.
				</p>

				<p>
					Na plataforma Ponto do Esporte, as modalidades de acesso disponíveis
					para gestão e organização de eventos esportivos incluem:
				</p>

				<List bulleted>
					<List.Item>
						Acesso como Gestor: Permite gerenciar e organizar eventos
						esportivos, ligas, e alocar locais para a prática esportiva. Os
						gestores podem cadastrar eventos, definir as regras, realizar
						inscrições, e gerenciar participantes.
					</List.Item>
					<List.Item>
						Acesso como Participante: Voltado para esportistas e equipes que
						desejam participar dos eventos e ligas disponíveis na plataforma. Os
						participantes podem se inscrever nos eventos, conferir cronogramas,
						e acompanhar atualizações.
					</List.Item>
				</List>

				<p>
					Cada modalidade de acesso é projetada para atender às necessidades
					específicas dos usuários, seja na organização e administração de
					eventos esportivos ou na participação ativa como atleta ou equipe.
				</p>

				<p>
					Ao se cadastrar na Plataforma Ponto do Esporte, o usuário declara ter
					lido, compreendido e aceitado integralmente estes Termos de Uso, tendo
					acesso completo às funcionalidades e serviços oferecidos na
					plataforma, sem qualquer distinção ou restrição.
				</p>

				<p>
					O não exercício ou a não execução pela Ponto do Esporte de qualquer
					direito ou disposição destes Termos não constitui renúncia a tais
					direitos ou disposições. Qualquer renúncia de direitos ou cláusulas
					somente será eficaz se realizada por escrito e assinada por um
					representante devidamente autorizado da Ponto do Esporte.
				</p>
			</Segment>

			<Header content="Cadastro" dividing size="medium" />

			<Segment basic size="large" style={{ padding: 0 }}>
				<p>
					O cadastro na Ponto do Esporte é realizado diretamente na Plataforma,
					através do preenchimento dos dados pessoais requisitados.
				</p>

				<p>
					Para acessar certos serviços, é necessário criar uma conta, fornecendo
					dados básicos como nome, telefone, e-mail e senha. Informações
					adicionais podem ser adicionadas ao perfil pelo usuário.
				</p>

				<p>
					Ao criar uma conta na Ponto do Esporte, o usuário compromete-se a
					fornecer informações verdadeiras e atualizadas, sendo responsável pela
					autenticidade destes dados. Os usuários são responsáveis civil e
					criminalmente pela veracidade e exatidão dos dados cadastrados.
				</p>

				<p>
					A Ponto do Esporte não garante a veracidade, exatidão ou atualização
					das informações fornecidas pelos usuários, mas se reserva o direito de
					verificar a identidade dos usuários e solicitar dados e documentos
					adicionais conforme necessário.
				</p>

				<p>
					Os usuários devem notificar imediatamente a Ponto do Esporte sobre
					qualquer suspeita ou conhecimento de uso não autorizado de suas
					contas. São responsáveis pelas operações realizadas em suas contas,
					uma vez que o acesso só é possível através de um sistema de
					verificação exclusivo.
				</p>

				<p>
					A Ponto do Esporte não se responsabiliza por usos indevidos da conta
					decorrentes do uso de funcionalidades como “lembrar senha” em
					dispositivos próprios ou de terceiros.
				</p>

				<p>
					É proibida a transferência, venda ou aluguel da conta. Usuários com
					cadastros cancelados por infrações às políticas da Ponto do Esporte ou
					à legislação não podem criar novos cadastros. Não é permitida a venda,
					aluguel ou transferência de quaisquer créditos ou vantagens dentro da
					plataforma.
				</p>

				<p>
					Cada usuário pode ter apenas um cadastro na Ponto do Esporte. Em caso
					de múltiplos cadastros por um mesmo usuário, a Ponto do Esporte tem o
					direito de inabilitar e excluir estes cadastros sem aviso prévio.
				</p>
			</Segment>

			<Header
				content="Isenções e limitações de responsabilidade da Ponto do Esporte"
				dividing
				size="medium"
			/>

			<Segment basic size="large" style={{ padding: 0 }}>
				<p>
					A Ponto do Esporte respeita e obedece a todas as leis e regulamentos
					aplicáveis às suas atividades e espera que todos os seus Usuários
					façam o mesmo. Entretanto, a Ponto do Esporte não pode garantir e não
					assume nenhuma responsabilidade pela conformidade de seus Usuários com
					quaisquer leis ou regulamentos. Ao utilizar a Plataforma, o Usuário
					declara estar ciente, entender e concordar que é exclusiva e
					totalmente responsável pelo cumprimento de todas e quaisquer leis,
					normas, regulamentos e obrigações legais e fiscais que possam ser
					aplicadas para seu uso.
				</p>

				<p>
					A Ponto do Esporte não garante a exatidão nem a autenticidade das
					informações e conteúdos fornecidos pelos Usuários e não tem nenhuma
					obrigação de verificá-los ou monitorá-los. Entretanto, a Ponto do
					Esporte reserva-se o direito de fazê-lo, diretamente ou através de
					fornecedores ou terceiros capacitados, para garantir a qualidade dos
					serviços prestados e a conformidade com estes Termos ou para cumprir
					com a lei aplicável ou com ordem de algum órgão competente. Estas
					verificações incluem, mas não se limitam a: (i) verificar a exatidão,
					autenticidade e atualidade de informações de identificação pessoal;
					(ii) verificar antecedentes acadêmicos, profissionais, criminais, de
					crédito, de reputação online ou qualquer outra informação que possa
					auxiliar a Ponto do Esporte a avaliar a capacidade ou probabilidade do
					Usuário honrarem com as responsabilidades assumidas perante a Ponto do
					Esporte, os Usuários; (iii) contatar referências profissionais ou
					pessoais fornecidas ou não pelo Usuário; e (iv) cessar, visualizar e
					monitorar todo e qualquer conteúdo postado ou transmitido pelo Usuário
					através da Plataforma. O Usuário, ao aceitar os presentes Termos,
					autoriza expressamente a realização da verificação por quaisquer das
					formas estabelecidas acima.
				</p>

				<p>
					Caso a Ponto do Esporte componha o polo passivo de interpelação
					judicial cujos fatos fundem-se em ações do Usuário, este será chamado
					ao processo devendo arcar com todos os ônus que daí decorram.
				</p>

				<p>
					A Plataforma pode conter links para outros websites. Isto não implica
					nenhuma relação de sociedade, de supervisão, de cumplicidade, de
					endosso ou solidariedade da Ponto do Esporte para com esses sites,
					seus conteúdos, produtos ou serviços. O Usuário reconhece e concorda
					que a Ponto do Esporte não é responsável pela disponibilidade ou
					exatidão de tais sites ou recursos ou pelos conteúdos, produtos ou
					serviços contidos ou disponibilizados através de tais sites ou
					recursos. O Usuário assume exclusiva e total responsabilidade por
					todos os riscos e eventuais danos e/ou prejuízos decorrentes do uso de
					quaisquer desses sites ou de seus conteúdos, produtos ou serviços.
				</p>

				<p>
					A Ponto do Esporte não se responsabiliza por/pela(o): (i) quaisquer
					vícios, defeitos técnicos e/ou operacionais (problemas, bugs e
					glitches) ou funcionamentos indevidos que ocorrerem nos dispositivos e
					equipamentos dos Usuários ou de terceiros e sejam resultantes do uso
					regular da Plataforma; (ii) qualquer dano direto ou indireto
					ocasionado por eventos de terceiros, como ataque de hackers, falhas no
					sistema, no servidor ou na conexão à Internet, inclusive por ações de
					softwares maliciosos, como vírus, cavalos de Tróia e outros que
					possam, de algum modo, danificar o equipamento ou a conexão dos
					Usuários em decorrência do acesso, utilização ou navegação na
					Plataforma, bem como a transferência de dados, arquivos, imagens,
					textos contidos neste; (iii) navegação dos Usuários em links externos
					contidos na Plataforma, sendo seus deveres a leitura de eventuais
					Termos de Uso e Política de Privacidade do portal acessado e agir
					conforme o determinado; (iv) verificar, controlar, aprovar ou garantir
					a adequação ou exatidão das informações ou dados disponibilizados em
					tais links, não sendo, portanto, responsável por prejuízos, perdas ou
					danos ocorridos pela visita a tais sites, cabendo ao interessado
					verificar a confiabilidade das informações e dados ali exibidos antes
					de tomar alguma decisão ou praticar algum ato; (v) disponibilidade
					integral e ininterrupta da Plataforma, cujo correto funcionamento
					depende do acesso e tráfego de dados entre terminais de computador dos
					Usuários e os servidores pertencentes ou contratados pela Ponto do
					Esporte, não possuindo nenhuma gerência e não sendo responsável por
					eventuais falhas no tráfego desses dados e no acesso à Plataforma
					decorrentes de falhas na rede mundial de computadores ou da própria
					rede e serviços de telecomunicação prestados por provedores de acesso
					à Internet; e (vi) ressarcir seus Usuários por quaisquer gastos com
					ligações telefônicas, pacotes de dados, SMS, mensagens, e-mails,
					correspondência ou qualquer outro valor despendido pelo Usuário em
					razão de contato com a Ponto do Esporte ou quaisquer outros Usuários,
					por qualquer motivo que seja.
				</p>

				<p>
					Os Usuários não possuem qualquer direito para exigir a disponibilidade
					da Plataforma conforme melhor lhes convêm, tampouco poderão pleitear
					indenização ou reparação de danos em caso de a Plataforma permanecer
					fora do ar, independentemente da motivação.
				</p>

				<p>
					A eventual remoção, bloqueio ou suspensão de qualquer conteúdo ou
					funcionalidade da Plataforma em decorrência de alguma reclamação
					deverá ser sempre compreendida como demonstração de boa-fé e intenção
					de solução amigável de conflitos, jamais como reconhecimento de culpa
					ou de qualquer infração pela Ponto do Esporte a direito de terceiro.
				</p>

				<p>
					A Ponto do Esporte se reserva o direito de auxiliar e cooperar com
					qualquer autoridade judicial ou órgão governamental, podendo enviar
					informações cadastrais de seus Usuários.
				</p>
			</Segment>

			<Header content="Propriedade Intelectual" dividing size="medium" />

			<Segment basic size="large" style={{ padding: 0 }}>
				<p>
					O uso indevido e a reprodução total ou parcial dos seguintes itens são
					proibidos, salvo por autorização expressa da Ponto do Esporte: (i) o
					uso comercial da expressão "Ponto do Esporte" como marca, nome
					empresarial ou nome de domínio; (ii) os conteúdos das telas relativas
					à Plataforma da Ponto do Esporte; e (iii) os programas, bancos de
					dados, redes, arquivos que permitem que o Usuário acesse a Plataforma
					e use sua conta são todos de propriedade da Ponto do Esporte e estão
					protegidos pelas leis e tratados internacionais de direito autoral,
					marcas, patentes, modelos e desenhos industriais. O uso indevido e a
					reprodução total ou parcial dos referidos itens são proibidos, salvo
					por autorização expressa da Ponto do Esporte.
				</p>

				<p>
					Todas as outras marcas comerciais, marcas de serviço, logotipos, nomes
					comerciais e quaisquer outras designações proprietárias são marcas
					comerciais ou marcas registradas de suas respectivas partes.
				</p>

				<p>
					Desde que em conformidade com estes Termos, a Ponto do Esporte concede
					ao Usuário uma licença limitada, não exclusiva e intransferível, para
					(i) acessar e visualizar qualquer conteúdo que um Usuário postar,
					transferir, publicar, apresentar ou transmitir para ser
					disponibilizado através da Plataforma (“Conteúdo de Usuário”) para o
					qual o Usuário tem permissão de acesso, exclusivamente para fins
					pessoais e não comerciais. O Usuário não tem direito de sublicenciar
					os direitos de licença concedidos nesta seção. O Usuário se compromete
					a não usar, copiar, adaptar, modificar, preparar trabalhos derivados
					com base, distribuir, licenciar, vender, transferir, exibir
					publicamente, executar publicamente, transmitir ou explorar a
					Plataforma, Serviços ou Conteúdos, exceto se expressamente permitido
					nestes Termos. O Usuário se compromete a não remover, alterar ou
					ocultar quaisquer direitos autorais, marca registrada, marca de
					serviço ou outros direitos de propriedade incorporadas ou acompanhando
					a Plataforma. Nenhuma licença ou direitos são concedidos por
					implicação ou sob quaisquer direitos de propriedade intelectual
					pertencentes ou controlados pela Ponto do Esporte ou de seus
					licenciadores, exceto para as licenças e direitos expressamente
					concedidos nestes Termos; e (ii) acessar e visualizar qualquer
					conteúdo que a Ponto do Esporte disponibiliza através do site,
					incluindo qualquer conteúdo licenciado de terceiros e excluindo
					Conteúdo de Usuário (“Conteúdo da Ponto do Esporte”), exclusivamente
					para fins pessoais e não comerciais.
				</p>

				<p>
					Qualquer intromissão, tentativa de, ou atividade que viole ou
					contrarie as leis de direito de propriedade intelectual e/ou as
					proibições estipuladas nestes Termos, tornarão o responsável passível
					das ações legais pertinentes, bem como das sanções aqui previstas,
					sendo ainda responsável pelas indenizações por eventuais danos
					causados.
				</p>
			</Segment>

			<Header content="Vedações" dividing size="medium" />

			<Segment basic size="large" style={{ padding: 0 }}>
				<p>
					Fica vedada qualquer tentativa, direta ou indireta, bem-sucedida ou
					não, de realizar alguma das atividades listadas abaixo. A constatação
					de tal intento tornará o autor, bem como seus mandantes, defensores,
					encorajadores e ajudantes, passível das sanções previstas nestes
					Termos, das ações legais pertinentes e da responsabilidade de
					indenizar quaisquer partes afetadas por eventuais danos causados.
				</p>

				<p>
					Em caso de constatada a prática de qualquer das atividades abaixo
					listadas, o Usuário estará sujeito à suspensão e/ou ao cancelamento de
					seu acesso à Plataforma, bem como poderão ser adotadas medidas
					administrativas e/ou judiciais em face do Usuário.
				</p>

				<p>
					Em caso de descumprimento de qualquer dos itens abaixo listados, a
					Ponto do Esporte não se responsabilizará por eventuais perdas e danos
					que venham a ser suportados pelas partes envolvidas.
				</p>

				<p>
					Tais atividades incluem, mas não se limitam a: (i) incomodar, agredir,
					caluniar, injuriar, difamar, perseguir ou assediar qualquer outro
					Usuário; (ii) infringir os direitos de qualquer pessoa ou entidade,
					incluindo, sem limitação, os direitos de propriedade intelectual,
					privacidade, publicidade ou contratual, ressalvando que é proibida a
					gravação por vídeo ou de voz dos atendimentos realizados; (iii)
					copiar, armazenar, ou de qualquer forma acessar qualquer informação
					contida na Plataforma para fins que não sejam expressamente
					autorizados por estes Termos; (iv) utilizar a Plataforma para
					transmitir, distribuir, publicar ou enviar qualquer informação
					relativa a qualquer outra pessoa ou entidade, incluindo, sem
					limitação, fotografias de outras pessoas sem sua permissão,
					informações de contato pessoal ou de crédito, débito, cartão
					telefônico ou números de contas; (v) manipular os valores ou quaisquer
					outras informações contidas nos anúncios, na Plataforma; (vi) utilizar
					a Plataforma para qualquer comercial ou para outros fins que não sejam
					expressamente autorizados por estes Termos; (vii) interferir nas
					transações entre outros Usuários; (viii) usar software manual ou
					automatizado, dispositivos, scripts robôs, ou outros meios ou
					processos para acessar, “scrape”, “crawl” ou “spider” qualquer parte
					da Plataforma; (ix) modificar, copiar, distribuir, transmitir, exibir,
					realizar, reproduzir, publicar, licenciar, criar trabalhos derivados,
					exibir em outra webpage, utilizar em qualquer outro website,
					transferir ou vender qualquer informação, software, listas de
					usuários, banco de dados ou outras listas, produtos ou serviços
					prestados por ou obtidos pela Ponto do Esporte, incluindo, mas não
					limitado a, práticas envolvendo “screen scraping”, “database
					scraping”, ou qualquer outra atividade com o propósito de obter listas
					de Usuários ou outras informações; (x) usar scripts automatizados para
					coletar informações ou de outra forma interagir com a Plataforma; (xi)
					acessar, testar, adulterar, interferir ou danificar a Plataforma ou
					qualquer sistema da Ponto do Esporte, dos Usuários, ou de terceiros
					através do uso de vírus, cancelar bots, cavalos de Tróia, códigos
					nocivos, flood pings, ataques de negação de serviço, pacote ou
					falsificação de IP, roteamento forjado ou informações de endereço
					eletrônico ou métodos similares ou de tecnologia; (xii) tentar
					detectar, varrer, ou testar a vulnerabilidade de qualquer sistema ou
					rede Ponto do Esporte ou violar qualquer segurança ou medidas de
					autenticação; (xiii) evitar, ignorar, remover, desativar, prejudicar,
					decodificar, ou evitar qualquer medida tecnológica implementada pela
					Ponto do Esporte ou qualquer dos provedores Ponto do Esporte ou
					qualquer outro terceiro (incluindo outro Usuário) para proteger a
					Plataforma; (xiv) forjar qualquer pacote de cabeçalho TCP/IP ou
					qualquer parte da informação do cabeçalho em qualquer publicação,
					e-mail ou newsgroup, ou de qualquer forma utilizar a Plataforma para
					enviar fonte de identificação alterada, enganosa ou falsa informação;
					(xiv) sistematicamente recuperar dados ou outro conteúdo do nossa
					Plataforma para criar ou compilar, direta ou indiretamente, em
					downloads únicos ou múltiplos, a coleta, compilação, banco de dados,
					diretório ou similar, seja por métodos manuais, através da utilização
					de bots, crawlers, ou spiders, ou de qualquer outra forma; (xvi)
					tentar decifrar, descompilar, desmontar ou fazer engenharia reversa de
					qualquer software usado para fornecer a Plataforma; (xvii)
					personificar qualquer pessoa ou entidade, falsificar ou adulterar a si
					mesmo ou sua afiliação com qualquer pessoa ou entidade; e (xviii) a
					tentativa ou êxito de conduta configurada como fraude às
					funcionalidades, planos ou forma de pagamento utilizados pela
					Plataforma.
				</p>
			</Segment>

			<Header content="Sanções" dividing size="medium" />

			<Segment basic size="large" style={{ padding: 0 }}>
				<p>
					Caso algum Usuário não cumpra qualquer dispositivo destes Termos ou
					faça algo que prejudique a Plataforma, a Ponto do Esporte, ou outros
					Usuários, a Ponto do Esporte pode, a seu único e exclusivo critério, a
					qualquer momento e sem aviso ou consentimento prévios: (i) suspender
					ou cancelar definitivamente a conta do Usuário e todo e qualquer
					acesso de tal Usuário à Plataforma; e/ou (ii) tomar ações cabíveis
					para investigar e julgar violações de qualquer natureza em toda a
					extensão da lei, podendo envolver ou cooperar com autoridades
					judiciais e policiais.
				</p>
			</Segment>

			<Header
				content="Política de Inativação da Conta"
				dividing
				size="medium"
			/>

			<Segment basic size="large" style={{ padding: 0 }}>
				<p>
					A Ponto do Esporte pode rescindir estes Termos e proceder à inativação
					de contas de Usuários a qualquer momento, sem aviso ou consentimento
					prévios e a seu único e exclusivo critério. Nessa hipótese, a Ponto do
					Esporte se compromete a tomar as providências cabíveis de forma a não
					prejudicar os Usuários.
				</p>

				<p>
					O Usuário pode pedir a inativação de sua conta da Ponto do Esporte na
					própria Plataforma.
				</p>

				<p>
					Em caso de rescisão: (i) o Usuário permanecerá responsável por todos
					os montantes devidos à Ponto do Esporte, ou a terceiros; e (ii) A
					Ponto do Esporte verificará eventuais valores que sejam legalmente
					devidos ao Usuário e lhe pagará em tempo hábil.
				</p>
			</Segment>

			<Header
				content="Canal de Atendimento e Denúncia"
				dividing
				size="medium"
			/>

			<Segment basic size="large" style={{ padding: 0 }}>
				<p>
					A Ponto do Esporte fornece a seus Usuários um canal de atendimento
					para críticas, sugestões, denúncias e demais reclamações que o Usuário
					entenda cabível, diretamente no e-mail contato@pontodoesporte.com.br.
				</p>

				<p>
					Ao utilizar este canal, o Usuário deverá se identificar com os dados
					pertinentes (nome, endereço, número de telefone e e-mail) e descrever
					a situação detalhadamente, a fim de que a Ponto do Esporte verifique
					eventuais medidas cabíveis previstas nestes Termos.
				</p>
			</Segment>

			<Header content="Notificações" dividing size="medium" />

			<Segment basic size="large" style={{ padding: 0 }}>
				<p>
					Quaisquer notificações ou outras comunicações permitidas ou exigidas
					neste documento, incluindo as modificações em relação a estes Termos,
					serão por escrito e entregues pela Ponto do Esporte (i) por postagem
					na Plataforma ou (ii) via e-mail para o endereço informado pelo
					Usuário em suas informações de conta.
				</p>

				<p>
					Quaisquer notificações ou outras comunicações direcionadas à Ponto do
					Esporte também deverão ser por escrito e entregues via e-mail para o
					endereço contato@pontodoesporte.com.br.
				</p>

				<p>
					Para avisos feitos por e-mail, a data de recebimento será considerada
					a data em que tal notificação for transmitida.
				</p>
			</Segment>

			<Header content="Disposições Gerais" dividing size="medium" />

			<Segment basic size="large" style={{ padding: 0 }}>
				<p>
					A declaração de nulidade de qualquer disposição destes Termos, não
					invalida nenhuma das demais.
				</p>

				<p>
					A tolerância de uma parte para com a outra, relativamente ao
					descumprimento de qualquer das obrigações assumidas nestes Termos,
					constitui mera liberalidade, não sendo considerada novação ou renúncia
					a qualquer direito, não impedindo que a parte tolerante exija da outra
					o seu fiel cumprimento, a qualquer tempo.
				</p>

				<p>
					Estes Termos são regidos e interpretados de acordo com as leis
					brasileiras.
				</p>

				<p>
					A Ponto do Esporte poderá alterar as disposições dos Termos e
					respectivas políticas a eles relacionadas a qualquer tempo,
					disponibilizando-as em seu site.
				</p>

				<p>Excepcionalmente, os presentes Termos considerar-se-ão extintos:</p>

				<List bulleted>
					<List.Item>
						Por motivo de caso fortuito e/ou de força maior que impeça a
						execução de todos ou de algum serviço contratado, de forma
						definitiva;
					</List.Item>
					<List.Item>
						Por motivo de falência confessada ou decretada da Ponto do Esporte
						ou recuperação judicial impetrada pela Ponto do Esporte, nos termos
						da Lei Federal 11.101/2005; e
					</List.Item>
					<List.Item>
						A qualquer momento, mediante notificação prévia por escrito da Ponto
						do Esporte com 30 (trinta) dias de antecedência.
					</List.Item>
				</List>

				<p>
					Estes Termos representam o total entendimento entre a Ponto do Esporte
					e o Usuário em relação à matéria aqui tratada, devendo prevalecer
					sobre quaisquer outros entendimentos pretéritos sobre a mesma matéria,
					sejam estes verbais ou escritos.
				</p>

				<p>
					Caso qualquer dispositivo destes Termos sejam considerados, por um
					tribunal competente, como sendo contrários à lei, referidos
					dispositivos deverão ser aplicados na maior extensão permitida,
					permanecendo os demais dispositivos em pleno vigor e eficácia.
				</p>

				<p>
					Os presentes Termos permanecerão válidos e vigentes, sem qualquer
					alteração, interrupção ou necessidade de aviso prévio, em caso de
					fusão, incorporação, cisão, aquisição, ou qualquer outro tipo de
					operação societária que resulte na transferência do controle
					societário ou do negócio da Ponto do Esporte para entidade terceira.
				</p>
			</Segment>
		</>
	);
};

export default Terms;
