import { PayloadAction, SerializedError, createSlice } from '@reduxjs/toolkit';

import { Tournament } from '../events';

interface TournamentState {
	tournaments: Tournament[];
	tournament?: Tournament;
	loading: boolean;
	error?: SerializedError;
}

const initialState: TournamentState = {
	tournaments: [],
	loading: false,
	error: undefined,
};

export const tournSlice = createSlice({
	name: 'tournaments',
	initialState: initialState,
	reducers: {
		setTournaments: (state, { payload }: PayloadAction<Tournament[]>) => {
			state.tournaments = payload.sort((a, b) => a.name.localeCompare(b.name));
		},
		resetTournament: (state) => {
			state.tournament = undefined;
		},
		resetTournaments: (state) => {
			state.tournaments = [];
		},
	},
});

export const { setTournaments, resetTournament, resetTournaments } =
	tournSlice.actions;

export default tournSlice.reducer;
