import React from 'react';

import { Header, Segment } from 'semantic-ui-react';

const Policy: React.FC = () => {
	return (
		<>
			<Header content="Introdução" dividing size="medium" />

			<Segment basic size="large" style={{ padding: 0 }}>
				<p>
					A presente Política de Privacidade e Proteção dos Dados Pessoais foi
					elaborada em cumprimento aos dispositivos previstos na Lei Federal nº
					13.709/2018 - Lei Geral de Proteção de Dados Pessoais (“LGPD”) de modo
					a definir as regras e esclarecer os direitos relacionados à
					privacidade e proteção dos dados pessoais dos usuários da plataforma
					Ponto do Esporte Brasil, tratados durante seu acesso, experiência ou
					relacionamento com a PONTO DO ESPORTE GESTAO ESPORTIVA LTDA.,
					sociedade empresária limitada, inscrita no CNPJ sob o nº
					21.435.248/0001-55, com sede na Cidade de São Paulo, Estado de São
					Paulo, na Av. Mofarrej, 706, apt 103, Vila Leopoldina, CEP 05311-000
					(“Ponto do Esporte” ou “Nós”).
				</p>

				<p>
					De modo a reforçar o compromisso da Ponto do Esporte com a cultura da
					privacidade e segurança das informações, a presente política cumpre
					seu papel formal e legal.
				</p>
			</Segment>

			<Header content="Aplicação" dividing size="medium" />

			<Segment basic size="large" style={{ padding: 0 }}>
				<p>
					Aplica-se à Ponto do Esporte, em atendimento à LGPD, como destinatária
					dos dados pessoais eventualmente fornecidos pelos usuários da
					plataforma Ponto do Esporte, bem como de todos e quaisquer titulares
					cujos dados pessoais venham a ser eventualmente tratados pela Ponto do
					Esporte e pelo qual a Ponto do Esporte torna-se responsável - a partir
					da respectiva coleta - pela utilização, armazenamento e proteção na
					qualidade de controladora das informações fornecidas conforme a
					definição legal.
				</p>
			</Segment>

			<Header content="Tratamento de Dados Pessoais" dividing size="medium" />

			<Segment basic size="large" style={{ padding: 0 }}>
				<p>
					No contexto de seu funcionamento, a plataforma Ponto do Esporte
					realizará a coleta dos seguintes dados pessoais dos usuários: nome,
					telefone, endereço, e-mail.
				</p>

				<p>A coleta dessas informações tem por objetivos:</p>

				<p>
					Autenticação e Personalização: Identificação e autenticação do usuário
					na plataforma, permitindo uma experiência personalizada e segura.
				</p>

				<p>
					Comunicação e Notificações: Envio de comunicados, atualizações sobre o
					serviço, ofertas especiais e qualquer outra comunicação relevante.
				</p>

				<p>
					Segurança e Conformidade: Atender às exigências legais e regulatórias,
					realizar a verificação de novos cadastros, proteger a segurança dos
					usuários e responder a questionamentos judiciais, se necessário.
				</p>

				<p>
					Além das finalidades indicadas acima, a Ponto do Esporte também pode
					vir a usar os dados pessoais coletados dos Usuários para cumprimento
					de obrigações legais e regulatórias, exercício regular de direitos, e
					exercício de outras atividades essenciais para a regular operação da
					plataforma, no âmbito de seu objeto social.
				</p>
			</Segment>

			<Header
				content="Compartilhamento de Dados Pessoais"
				dividing
				size="medium"
			/>

			<Segment basic size="large" style={{ padding: 0 }}>
				<p>
					Todos os dados, as informações e comunicações coletados são por nós
					considerados confidenciais, e não serão fornecidos a terceiros,
					ressalvadas as hipóteses previstas nesta Política.
				</p>

				<p>
					Para desenvolver e melhorar os serviços prestados pela Ponto do
					Esporte, poderemos contratar provedores de serviços terceirizados para
					executar determinadas operações de negócios em nome da Ponto do
					Esporte. Ao fazer isso, podemos precisar compartilhar suas informações
					pessoais com eles. Fornecemos aos nossos provedores de serviço somente
					informações pessoais de que eles necessitam para realizar os serviços
					que solicitamos, e exigimos que eles protejam essas informações e não
					as usem para nenhum outro propósito.
				</p>

				<p>
					A Ponto do Esporte também pode divulgar seus dados pessoais a
					parceiros comerciais com os quais possuímos contrato para fins de
					promoção dos serviços da Ponto do Esporte, bem como de apresentação
					dos produtos e serviços dos nossos parceiros para os usuários. Neste
					caso, nosso parceiro irá assumir o compromisso em seguir os termos e
					condições desta Política de Privacidade no tratamento de seus dados
					pessoais.
				</p>

				<p>
					Além disso, poderemos revelar seus dados em caso de: determinação
					judicial, ordem de autoridade competente ou necessidade de defesa de
					nossos direitos em demandas judiciais ou administrativas.
				</p>

				<p>
					Adicionalmente, é possível que algumas das transferências citadas
					acima ocorram para destinatários situados fora do território nacional.
					Nesta circunstância, as partes se comprometem a fazê-lo somente para
					países que proporcionem um grau de proteção aos dados pessoais em
					consonância com às melhores práticas, sempre observado as legislações
					vigentes, proporcionando assim aos usuários garantias de salvaguardas
					seus dados, com cláusulas contratuais específicas, sem que haja
					prejuízo da prévia coleta do seu respectivo consentimento específico,
					quando aplicável.
				</p>
			</Segment>

			<Header content="Dados Pessoais Seguros" dividing size="medium" />

			<Segment basic size="large" style={{ padding: 0 }}>
				<p>
					A Ponto do Esporte adota diversas precauções, em observância às
					diretrizes sobre normas, orientações, padrões e práticas de segurança
					estabelecidas nas legislações aplicáveis, incluindo-se a LGPD. O
					acesso às informações coletadas é restrito aos colaboradores e às
					pessoas autorizadas, aqueles que se utilizarem indevidamente dessas
					informações, em violação ao disposto contido neste documento, estarão
					sujeitos às sanções disciplinares e legais cabíveis., Na hipótese de
					incidência desta natureza, a Ponto do Esporte ressalta que envidará
					todos os esforços para remediar e/ou minimizar as consequências do
					ocorrido, sempre em aderência às melhores práticas de Segurança da
					Informação, visando desta forma propiciar uma experiência segura e
					otimizada aos seus usuários.
				</p>
			</Segment>

			<Header
				content="Ponto do Esporte como Controladora de Dados Pessoais"
				dividing
				size="medium"
			/>

			<Segment basic size="large" style={{ padding: 0 }}>
				<p>
					A Ponto do Esporte é diretamente responsável pelos dados pessoais, mas
					pode haver situações em que a Ponto do Esporte não seja diretamente
					responsável, mas apenas uma entidade processadora de dados pessoais
					para terceiros, qualificada como operadora. Quando a Ponto do Esporte
					trata dados pessoais como uma operadora de dados pessoais, isso é
					ordenado por uma terceira parte e a Ponto do Esporte fica então
					vinculada às instruções dadas pela Ponto do Esporte pelo responsável.
					Nestas situações, a Ponto do Esporte não pode garantir que tal
					tratamento ocorra de acordo com esta Política de Privacidade, mas a
					Ponto do Esporte sempre garantirá que o tratamento esteja de acordo
					com a lei aplicável e, na medida do possível, de acordo com esta
					Política de Privacidade.
				</p>
			</Segment>

			<Header
				content="Retenção e Exclusão de Dados Pessoais"
				dividing
				size="medium"
			/>

			<Segment basic size="large" style={{ padding: 0 }}>
				<p>
					Caso opte em algum momento pela exclusão de seus dados pessoais do
					banco de armazenamento, a Ponto do Esporte poderá reter alguns dados
					pessoais por um período adicional para fins de cumprimento de
					obrigações legais ou regulatórias, assim como para eventual exercício
					regular de direito da Ponto do Esporte, bem como para fins de
					auditorias e cumprimento de normas de compliance. A retenção dos dados
					pessoais será feita durante o prazo necessário ao cumprimento das
					sobreditas obrigações, sempre respeitando os prazos estabelecidos na
					legislação aplicável.
				</p>
			</Segment>

			<Header
				content="Direitos como Titular de Dados Pessoais"
				dividing
				size="medium"
			/>

			<Segment basic size="large" style={{ padding: 0 }}>
				<p>
					A Ponto do Esporte reserva aos respectivos usuários a possibilidade de
					exercerem os direitos previstos no art. 18 da LGPD, podendo apresentar
					solicitações relacionadas aos seus dados pessoais, tais como:
				</p>

				<p>
					a) Confirmação da existência de tratamento;
					<br />
					b) Acesso aos dados pessoais;
					<br />
					c) Correção de dados incompletos, inexatos ou desatualizados;
					<br />
					d) Anonimização, bloqueio ou eliminação de dados desnecessários,
					excessivos ou supostamente tratados em desconformidade com a LGPD,
					ressalvadas as hipóteses previstas em Lei;
					<br />
					e) Portabilidade dos dados a outro fornecedor de serviço ou produto,
					mediante requisição expressa pelo usuário;
					<br />
					f) Eliminação dos dados tratados com consentimento do usuário,
					ressalvadas as hipóteses previstas em Lei;
					<br />
					g) Obtenção de informações sobre as entidades com as quais
					compartilhou os seus dados;
					<br />
					h) Informação sobre a possibilidade de o usuário não fornecer o
					consentimento, bem como de ser informado sobre as consequências em
					caso de negativa;
					<br />
					i) Informações relacionadas ao tratamento, armazenagem e destinação
					dos dados pessoais, no âmbito da LGPD;
					<br />
					j) Revogação do consentimento.
					<br />
				</p>

				<p>
					Para exercer os direitos indicados acima, por favor, entre em contato
					através do e-mail contato@pontodoesporte.com.br.
				</p>
			</Segment>

			<Header content="Utilização de Cookies" dividing size="medium" />

			<Segment basic size="large" style={{ padding: 0 }}>
				<p>
					A Ponto do Esporte utiliza os cookies para otimizar e acelerar suas
					atividades e experiências futuras nos serviços ofertados pela Ponto do
					Esporte através de seu website.
				</p>

				<p>
					Por meio de cookies o site armazena informações sobre as atividades do
					navegador, incluindo o endereço IP e a página acessada. Esses
					registros de atividades (logs) serão utilizados para fins estatísticos
					e de métricas qualitativas e quantitativas dos serviços
					disponibilizados, podendo também ser utilizados para investigações de
					fraudes ou de alterações indevidas em seus sistemas e cadastros, não
					tendo como finalidade o fornecimento dos dados a terceiros sem
					autorização expressa do usuário, tudo em conformidade com a legislação
					vigente.
				</p>

				<p>
					Os logs podem compreender dados como: o endereço IP do usuário, as
					ações efetuadas no site, as páginas acessadas, as datas e horários de
					cada ação e de acesso a cada página do site, as informações sobre o
					dispositivo utilizado, versão de sistema operacional, navegador,
					dentre outros aplicativos instalados.
				</p>

				<p>
					Os principais navegadores de internet possibilitam que o internauta
					gerencie a utilização dos cookies em seu dispositivo, podendo
					desabilitar o arquivamento desses pacotes de dados.
				</p>

				<p>
					No entanto, a fim de que você utilize todos os recursos de navegação
					personalizada do site da Ponto do Esporte, recomendamos que mantenha
					ligado o salvamento de cookies. Caso não haja interesse na função,
					basta desabilitá-la.
				</p>

				<p>
					Para a remoção de Cookies ou Cache, siga os procedimentos indicados
					pelos fabricantes para cada navegador:
				</p>

				<p>
					Google Chrome:
					(https://support.google.com/chrome/answer/95647?hl=pt-BR)
					<br />
					Firefox:
					(http://windows.microsoft.com/pt-br/internet-explorer/delete-manage-cookies#ie=ie-11)
					<br />
					Firefox:
					(https://support.mozilla.org/pt-BR/kb/como-limpar-cache-firefox)
					<br />
					Safari:
					(http://safari.helpmax.net/ps/privacidade-e-seguranca/como-remover-cookies/)
					<br />
					Opera:
					(https://www.tutoriaisprojetoweb.com.br/como-limpar-cache-e-cookies-do-navegador-opera)
					<br />
				</p>
			</Segment>

			<Header
				content="Alterações na Política de Privacidade"
				dividing
				size="medium"
			/>

			<Segment basic size="large" style={{ padding: 0 }}>
				<p>
					A Ponto do Esporte reforça o seu compromisso com a privacidade das
					informações fornecidas através da melhoria contínua e aprimoramento da
					experiência e segurança de seus usuários. Diante disso, as práticas de
					tratamento de dados pessoais podem vir a sofrer alterações futuras de
					modo a refletir eventuais mudanças, sempre em direção às melhores
					práticas do segmento. As atualizações realizadas serão
					disponibilizadas no endereço eletrônico da Ponto do Esporte, sendo que
					tais alterações serão válidas, eficazes e vinculantes ao website. As
					comunicações serão feitas por meio de um dos canais disponibilizados
					aos usuários durante o cadastro, quando aplicável.
				</p>
			</Segment>

			<Header content="Contato" dividing size="medium" />

			<Segment basic size="large" style={{ padding: 0 }}>
				<p>
					Em caso de dúvidas ou maiores esclarecimentos em relação à privacidade
					ou ao tratamento dos dados pessoais utilizados pela Ponto do Esporte,
					os usuários poderão entrar em contato com o Encarregado pela Proteção
					de Dados Pessoais, através do e-mail (contato@pontodoesporte.com.br).
				</p>
			</Segment>
		</>
	);
};

export default Policy;
