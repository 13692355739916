import { useEffect, useState } from 'react';

export const useLocale = () => {
	const [locale, setLocale] = useState<string>('pt_BR');

	useEffect(() => {
		const loc = navigator.languages?.[0] ?? navigator.language;

		setLocale(loc.replace('-', '_'));
	}, []);

	return locale;
};
