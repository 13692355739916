import {
	createAsyncThunk,
	createSlice,
	SerializedError,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { SemanticCOLORS } from 'semantic-ui-react';

import { api } from '../../api';
import { authRefresh } from '../auth';
import { Event, Tournament } from '../events';
import { Group } from '../groups';
import { Match } from '../matches';
import { Court, Unit } from '../organizations';
import { setSports, SportItem } from '../sports';

export type Media =
	| 'facebook'
	| 'twitter'
	| 'linkedin'
	| 'instagram'
	| 'youtube';

export interface Landing {
	land: string;
	logo?: string;
	horizontal?: string;
	site?: string;
	bg_color?: SemanticCOLORS;
	bg_image?: string;
	links?: {
		name: string;
		label: string;
		link?: string;
		mobile?: boolean;
	}[];
	medias?: {
		[media in Media]?: string;
	};
	info?: {
		title?: string;
		cover?: string;
		image?: string;
	};
	event?: {
		id: string;
		title?: string;
		image?: string;
	};
	league?: {
		id: string;
		title?: string;
		image?: string;
	};
}

interface LandingState {
	landing?: Landing;
	units?: (Unit & { courts?: Court[] })[];
	event?: Event;
	tourns?: Tournament[];
	groups?: Group[];
	matches?: Match[];
	loading: boolean;
	error?: SerializedError;
}

const initialState: LandingState = {
	loading: false,
};

export const landSlice = createSlice({
	name: 'Landings',
	initialState,
	reducers: {
		resetLanding: (state) => {
			state.landing = undefined;
			state.loading = false;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getLanding.fulfilled, (state, { payload }) => {
				state.landing = payload ?? undefined;
				state.loading = false;
				state.error = undefined;
			})
			.addCase(getLanding.pending, (state) => {
				state.loading = true;
			})
			.addCase(getLanding.rejected, (state, { payload }) => {
				state.landing = undefined;
				state.error = payload;
				state.loading = false;
			});
		builder
			.addCase(getHappening.fulfilled, (state, { payload }) => {
				state.event = payload.event;
				state.tourns = payload.tourns;
				state.units = payload.units;
				state.loading = false;
			})
			.addCase(getHappening.pending, (state) => {
				state.loading = true;
				state.error = undefined;
			})
			.addCase(getHappening.rejected, (state, { payload }) => {
				state.error = payload;
				state.loading = false;
			});
		builder
			.addCase(getContest.fulfilled, (state, { payload }) => {
				state.groups = payload.groups;
				state.matches = payload.matches;
				state.loading = false;
			})
			.addCase(getContest.pending, (state) => {
				state.loading = true;
				state.error = undefined;
			})
			.addCase(getContest.rejected, (state, { payload }) => {
				state.error = payload;
				state.loading = false;
			});
	},
});

export const getLanding = createAsyncThunk<
	Landing,
	{ id: string },
	{ rejectValue: SerializedError }
>('landings/getLanding', async ({ id }, { dispatch, rejectWithValue }) => {
	try {
		await dispatch(authRefresh());

		const { data } = await api.landings.get(id);

		return data;
	} catch (error) {
		return rejectWithValue(
			(error as AxiosError).response?.data as SerializedError
		);
	}
});

export const getHappening = createAsyncThunk<
	{
		sports: SportItem[];
		event: Event;
		tourns: Tournament[];
		units: (Unit & { courts: Court[] })[];
	},
	{ event?: string; league?: string },
	{ rejectValue: SerializedError }
>(
	'landings/getHappening',
	async ({ event, league }, { dispatch, rejectWithValue }) => {
		try {
			await dispatch(authRefresh());

			const { data } = await api.happening.get({ event, league });

			dispatch(setSports(data.sports));

			return data;
		} catch (error) {
			return rejectWithValue(
				(error as AxiosError).response?.data as SerializedError
			);
		}
	}
);

export const getContest = createAsyncThunk<
	{
		event: Event;
		tourn: Tournament;
		groups: Group[];
		matches: Match[];
	},
	string,
	{ rejectValue: SerializedError }
>('landings/getContest', async (id, { dispatch, rejectWithValue }) => {
	try {
		await dispatch(authRefresh());

		const { data } = await api.happening.contest.get(id);

		return data;
	} catch (error) {
		return rejectWithValue(
			(error as AxiosError).response?.data as SerializedError
		);
	}
});

export const { resetLanding } = landSlice.actions;

export default landSlice.reducer;
