import React from 'react';

import { Grid, Header, Image } from 'semantic-ui-react';

import { ScreenSize } from '../../hooks';

const Vantagens: React.FC<{ screenSize?: ScreenSize }> = ({ screenSize }) => {
	return (
		<Grid
			id="beneficios"
			stackable
			container
			style={{ padding: `${screenSize === 'mobile' ? '40px' : '100px'} 0px` }}
		>
			<Grid.Row centered>
				<Header
					style={{
						textTransform: 'uppercase',
						fontStyle: 'italic',
						fontWeight: 'bolder',
					}}
				>
					Vantagens do Ponto do Esporte
				</Header>
			</Grid.Row>
			<Grid.Row centered>
				<Grid.Column width={5} textAlign="center">
					<Image
						src="\img\pe\otimizacao.jpg"
						centered
						size={screenSize !== 'mobile' ? undefined : 'small'}
					/>
					<Header size="small">Otimiza</Header>
					<span>o uso das dependências de prática de esportes.</span>
				</Grid.Column>
				<Grid.Column width={5} textAlign="center">
					<Image
						src="\img\pe\lucros.jpg"
						centered
						size={screenSize !== 'mobile' ? undefined : 'small'}
					/>
					<Header size="small">Amplia os lucros</Header>
					<span>com locação de quadras.</span>
				</Grid.Column>
				<Grid.Column width={5} textAlign="center">
					<Image
						src="\img\pe\facilita.jpg"
						centered
						size={screenSize !== 'mobile' ? undefined : 'small'}
					/>
					<Header size="small">Facilita</Header>
					<span>a vida dos esportistas.</span>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row centered>
				<Grid.Column width={5} textAlign="center">
					<Image
						src="\img\pe\criacao.jpg"
						centered
						size={screenSize !== 'mobile' ? undefined : 'small'}
					/>
					<Header size="small">Criamos</Header>
					<span>
						ferramentas de interação entre donos de quadras e jogadores.
					</span>
				</Grid.Column>
				<Grid.Column width={5} textAlign="center">
					<Image
						src="\img\pe\anuncio.jpg"
						centered
						size={screenSize !== 'mobile' ? undefined : 'small'}
					/>
					<Header size="small">Anunciamos</Header>
					<span>quadras e espaços com quadro de horários disponíveis.</span>
				</Grid.Column>
				<Grid.Column width={5} textAlign="center">
					<Image
						src="\img\pe\auxilio.jpg"
						centered
						size={screenSize !== 'mobile' ? undefined : 'small'}
					/>
					<Header size="small">Auxiliamos</Header>
					<span>na captação de novos clientes.</span>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);
};

export default Vantagens;
