import React, { useState } from 'react';

import {
	Form,
	InputOnChangeData,
	Message,
	MessageProps,
	Popup,
} from 'semantic-ui-react';

import { authChangePassword, authPasswordStatus } from '../../store/auth';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import checkValidity from '../../util/valida/check';

type Password = {
	value: string;
	label: string;
	reveal: boolean;
	error: {
		value: boolean;
		message?: string;
		warning?: MessageProps;
	};
};

type Passwords = {
	[key: string]: Password;
};

const Security: React.FC<{ loading: boolean }> = ({ loading }) => {
	const dispatch = useAppDispatch();

	const { password } = useAppSelector((state) => ({
		password: state.user.password,
	}));

	const [passwords, setPasswords] = useState<Passwords>({
		previous: {
			value: '',
			label: 'Senha atual',
			reveal: false,
			error: { value: true, message: 'Insira a senha atual' },
		},
		password: {
			value: '',
			label: 'Senha nova',
			reveal: false,
			error: {
				value: true,
				message: 'Senha incompatível',
				warning: {
					header: 'Requisitos mínimos',
					list: [
						'1 letra minúscula',
						'1 letra maiúscula',
						'1 número',
						'1 caracter especial',
						'8 caracteres',
					],
				},
			},
		},
		confirm: {
			value: '',
			label: 'Confirmar senha',
			reveal: false,
			error: {
				value: true,
				message: 'Senhas diferentes',
			},
		},
	});

	const handleShowPassword = (password: keyof Passwords) => {
		setPasswords((passwords) => ({
			...passwords,
			[password]: {
				...passwords[password],
				reveal: !passwords[password].reveal,
			},
		}));
	};

	const handleErrors = (field: string, value: string) => {
		switch (field) {
			case 'previous':
				return !value;
			case 'password':
				return checkValidity(field, value);
			case 'confirm':
				return value !== passwords['password'].value;
			default:
				return true;
		}
	};

	const handleChangePassword = (
		event: React.ChangeEvent<HTMLInputElement>,
		data: InputOnChangeData
	) => {
		setPasswords((passwords) => ({
			...passwords,
			[data.field]: {
				...passwords[data.field],
				value: data.value,
				error: {
					...passwords[data.field].error,
					value: handleErrors(data.field, data.value),
				},
			},
		}));
	};

	const [send, setSend] = useState<boolean>(false);

	const setNewPassword = () => {
		setSend(true);

		if (Object.values(passwords).every((password) => !password.error.value)) {
			dispatch(
				authChangePassword({
					PreviousPassword: passwords['previous'].value,
					ProposedPassword: passwords['password'].value,
				})
			);

			setTimeout(() => {
				dispatch(authPasswordStatus(undefined));
			}, 3000);

			setSend(false);
		}
	};

	return (
		<Form>
			{Object.keys(passwords).map((password) => (
				<Form.Group key={password}>
					<Form.Input
						value={passwords[password].value}
						field={password}
						type={passwords[password].reveal ? 'text' : 'password'}
						label={passwords[password].label}
						icon={{
							name: passwords[password].reveal ? 'eye slash' : 'eye',
							link: true,
							onClick: () => handleShowPassword(password),
						}}
						error={
							send &&
							passwords[password].error.value &&
							passwords[password].error.message
								? {
										content: passwords[password].error.message,
									}
								: send && passwords[password].error.value
						}
						width={6}
						onChange={handleChangePassword}
					/>
					<Message
						{...passwords[password].error.warning}
						warning
						compact
						size="mini"
						visible={
							send &&
							passwords[password].error.value &&
							!!passwords[password].error.warning
						}
					/>
				</Form.Group>
			))}
			<Popup
				open={!!password}
				position="top right"
				content={
					password === 'changed' ? 'Senha alterada!' : 'Falha na alteração'
				}
				trigger={
					<Form.Button
						type="submit"
						basic
						positive
						floated="right"
						icon="save"
						content="Salvar"
						loading={loading}
						style={{ marginTop: '1rem' }}
						onClick={setNewPassword}
					/>
				}
			/>
		</Form>
	);
};

export default Security;
