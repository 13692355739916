import { useEffect, useState } from 'react';

export type ScreenSize = 'mobile' | 'tablet' | 'desktop' | 'widescreen';

export const useScreenSize = () => {
	const [windowSize, setWindowSize] = useState<ScreenSize>();

	useEffect(() => {
		const handleResize = () => {
			setWindowSize(
				window.innerWidth < 770
					? 'mobile'
					: window.innerWidth < 1024
						? 'tablet'
						: window.innerWidth < 1920
							? 'desktop'
							: 'widescreen'
			);
		};

		window.addEventListener('resize', handleResize);
		handleResize();

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return windowSize;
};
