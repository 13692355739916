import React, { Suspense } from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';

import Loading from './loading';
import { Direct } from './redirects';
import { AppRoutes, Item } from '../app';
import ChampIndex from '../components/champs';
import ClassIndex from '../components/classify';
import NotFound from '../components/errors/notfound';
import About from '../components/home/about';
import Home from '../components/home/home';
import Policy from '../components/home/policy';
import Terms from '../components/home/terms';
import LoginForm from '../components/login/loginpage';
import Logout from '../components/login/logoutpage';
import SignUpForm from '../components/login/signupage';
import MenuBar from '../components/menus/menubar';
import PlacesIndex from '../components/places';
import Profile from '../components/profile/user';
import { ScreenSize } from '../hooks';
import { Profile as ProfileType } from '../store/access';
import { useAppSelector } from '../store/hooks';
import { RightItem } from '../store/rights';

const Organizations = React.lazy(() => import('../components/orgs/index'));
const Events = React.lazy(() => import('../components/events/index'));
const Leagues = React.lazy(() => import('../components/leagues/index'));

const Dashboard = React.lazy(() => import('../components/dashboard/board'));
const Places = React.lazy(() => import('../components/places/board'));
const Championship = React.lazy(() => import('../components/champs/board'));
const Occasion = React.lazy(() => import('../components/occasion/board'));
const Assortment = React.lazy(() => import('../components/assortment/board'));
const Classification = React.lazy(() => import('../components/classify/board'));
const Institution = React.lazy(() => import('../components/institution/board'));

const Evento = React.lazy(() => import('../components/evento'));

interface Props {
	routes: AppRoutes;
	activeItem: Item;
	openSidebar: boolean;
	screenSize?: ScreenSize;
	institucionalRoutes?: string[];
	handleOpenSidebar: () => void;
	handleCloseSidebar: () => void;
}

const Router: React.FC<Props> = ({
	routes,
	activeItem,
	openSidebar,
	screenSize,
	handleOpenSidebar,
	handleCloseSidebar,
}) => {
	const { logged, rights } = useAppSelector((state) => ({
		logged: state.user.logged,
		rights: state.rgt.rights,
	}));

	const rightsPermissions = rights.reduce(
		(permissions, right) => ({
			...permissions,
			...right.profile?.reduce(
				(profiles, profile) => ({
					...profiles,
					[profile]: {
						...permissions?.[profile],
						[right.type]: (permissions?.[profile]?.[right.type] ?? []).concat(
							right
						),
					},
				}),
				{} as {
					[profile in ProfileType]: {
						[type in RightItem['type']]: RightItem[];
					};
				}
			),
		}),
		{} as {
			[profile in ProfileType]: { [type in RightItem['type']]: RightItem[] };
		}
	);

	return (
		<MenuBar
			open={openSidebar}
			routes={routes}
			screenSize={screenSize}
			openSidebar={handleOpenSidebar}
			closeSidebar={handleCloseSidebar}
		>
			<Switch>
				<Route path="/" render={() => <Home activeItem={activeItem} />} exact />

				{/* Rotas para documentos */}
				<Route path="/policy" component={Policy} exact />
				<Route path="/terms" component={Terms} exact />
				<Route path="/about" component={About} exact />

				{/* Rotas para gestão de usuários */}
				<Route
					path="/login"
					render={() =>
						!logged ? (
							<LoginForm screenSize={screenSize} />
						) : (
							<Redirect to="/dashboard" />
						)
					}
					exact
				/>
				<Route
					path="/signup"
					render={() =>
						!logged ? (
							<SignUpForm screenSize={screenSize} />
						) : (
							<Redirect to="/profile" />
						)
					}
					exact
				/>
				<Route
					path="/profile"
					render={() => (logged ? <Profile /> : <LoginForm />)}
					exact
				/>
				<Route path="/logout" component={Logout} exact />

				{/* Rotas para criação e configuração de Organizações, Eventos e Ligas */}
				<Route
					path="/organizations"
					render={() =>
						logged ? (
							<Suspense fallback={<Loading />}>
								<Organizations />
							</Suspense>
						) : (
							<LoginForm />
						)
					}
					exact
				/>
				<Route
					path="/events"
					render={() =>
						logged ? (
							<Suspense fallback={<Loading />}>
								<Events />
							</Suspense>
						) : (
							<LoginForm />
						)
					}
					exact
				/>
				<Route
					path="/leagues"
					render={() =>
						logged ? (
							<Suspense fallback={<Loading />}>
								<Leagues />
							</Suspense>
						) : (
							<LoginForm />
						)
					}
					exact
				/>

				{/* Rotas para administração dos Locais, Torneios e Rankings */}
				<Route
					path="/dashboard"
					render={() =>
						logged ? (
							<Suspense fallback={<Loading />}>
								<Dashboard
									screenSize={screenSize}
									rightsPermissions={rightsPermissions}
								/>
							</Suspense>
						) : (
							<LoginForm />
						)
					}
					exact
				/>
				<Route
					path="/places"
					render={() =>
						logged ? (
							<Suspense fallback={<Loading />}>
								<PlacesIndex perms={rightsPermissions?.['admin']?.['unit']} />
							</Suspense>
						) : (
							<LoginForm />
						)
					}
					exact
				/>
				<Route
					path="/places/:id"
					render={() =>
						logged ? (
							<Suspense fallback={<Loading />}>
								<Places
									screenSize={screenSize}
									views={routes['places']['views']}
								/>
							</Suspense>
						) : (
							<LoginForm />
						)
					}
					exact
				/>
				<Route
					path="/championship"
					render={() =>
						logged ? (
							<Suspense fallback={<Loading />}>
								<ChampIndex perms={rightsPermissions?.['player']?.['tourn']} />
							</Suspense>
						) : (
							<LoginForm />
						)
					}
					exact
				/>
				<Route
					path="/championship/:id"
					render={() =>
						logged ? (
							<Suspense fallback={<Loading />}>
								<Championship
									screenSize={screenSize}
									views={routes['championship']['views']}
								/>
							</Suspense>
						) : (
							<LoginForm />
						)
					}
					exact
				/>
				<Route
					path="/classification"
					render={() =>
						logged ? (
							<Suspense fallback={<Loading />}>
								<ClassIndex perms={rightsPermissions?.['player']?.['rank']} />
							</Suspense>
						) : (
							<LoginForm />
						)
					}
					exact
				/>
				<Route
					path="/classification/:id"
					render={() =>
						logged ? (
							<Suspense fallback={<Loading />}>
								<Classification
									screenSize={screenSize}
									views={routes['classification']['views']}
								/>
							</Suspense>
						) : (
							<LoginForm />
						)
					}
					exact
				/>

				<Route
					path="/assortment/:id"
					render={() =>
						logged ? (
							<Suspense fallback={<Loading />}>
								<Assortment />
							</Suspense>
						) : (
							<LoginForm />
						)
					}
					exact
				/>
				<Route
					path="/occasion/:id"
					render={() =>
						logged ? (
							<Suspense fallback={<Loading />}>
								<Occasion />
							</Suspense>
						) : (
							<LoginForm />
						)
					}
					exact
				/>

				{/* Rotas para exibição de eventos */}
				<Route
					path="/eventos"
					render={() => (
						<Suspense fallback={<Loading />}>
							<Evento />
						</Suspense>
					)}
					exact
				/>

				<Route
					path={'/:org/:redirect'}
					render={() =>
						logged ? <Direct /> : <LoginForm screenSize={screenSize} />
					}
					exact
				/>
				<Route
					path={'/:org'}
					render={() =>
						logged ? (
							<Suspense fallback={<Loading />}>
								<Institution />
							</Suspense>
						) : (
							<LoginForm screenSize={screenSize} />
						)
					}
					exact
				/>

				<Route component={NotFound} />
			</Switch>
		</MenuBar>
	);
};

export default Router;
