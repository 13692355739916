import React, { useState } from 'react';

import {
	Button,
	Form,
	Message,
	Modal,
	Segment,
	Image,
	Header,
} from 'semantic-ui-react';

import {
	authConfirmSignUp,
	authDismissError,
	authDismissValidations,
	authResendConfirmationCode,
	authVerifyUserAttribute,
} from '../../store/auth';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import ErrorBoundary from '../errors/boundary';

interface VerifyProps {
	Username?: string;
	Password?: string;
}

const VerifyCode: React.FC<VerifyProps> = ({ Username, Password }) => {
	const dispatch = useAppDispatch();

	const { logged, verify, loading, error } = useAppSelector((state) => ({
		logged: state.user.logged,
		verify: state.user.verify,
		loading: state.user.loading,
		error: state.user.error,
	}));

	const { DeliveryMedium, Destination, AttributeName } = verify?.[0] ?? {};

	const [code, setCode] = useState<string>('');

	const handleResendCode = () => {
		if (Destination) dispatch(authResendConfirmationCode(Destination));
	};

	const handleDismissValidations = () => {
		dispatch(authDismissValidations());
	};

	const handleDismissError = () => {
		dispatch(authDismissError());
	};

	const handleSubmit = () => {
		if (logged) {
			dispatch(
				authVerifyUserAttribute({
					AttributeName,
					Code: code,
				})
			);
		} else {
			dispatch(
				authConfirmSignUp({
					Username,
					Password,
					ConfirmationCode: code,
				})
			);
		}
	};

	return (
		<Modal
			open={verify && verify.length > 0}
			centered
			closeIcon
			size="tiny"
			onClose={handleDismissValidations}
		>
			<ErrorBoundary>
				<Modal.Header as={Header}>
					<Image src="\img\pe.png" size="tiny" />
					Valide sua conta
				</Modal.Header>
				<Modal.Content>
					<Form size="large" loading={loading}>
						<Segment stacked>
							<Form.Input
								name="code"
								value={code}
								fluid
								icon="key"
								iconPosition="left"
								placeholder="Código de validação"
								onChange={(event, data) => setCode(data.value)}
							/>
							<Button
								icon="sign in"
								content="Confirmar"
								positive
								fluid
								size="medium"
								onClick={handleSubmit}
							/>
						</Segment>
					</Form>
					<Message
						error={!!error}
						onDismiss={error ? handleDismissError : undefined}
					>
						{!error ? (
							<>
								{`Por favor, preencha o código recebido pelo ${DeliveryMedium} enviado para ${Destination}.`}
								<br />
								Caso não tenha recebido o e-mail,
								<Button
									as="a"
									basic
									style={{
										margin: '0px 5px',
										padding: '0px',
									}}
									onClick={handleResendCode}
								>
									clique aqui
								</Button>
								.
							</>
						) : (
							error?.message
						)}
					</Message>
				</Modal.Content>
			</ErrorBoundary>
		</Modal>
	);
};

export default VerifyCode;
