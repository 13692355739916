import React, { useEffect } from 'react';

import { Redirect } from 'react-router-dom';

import { authLogout } from '../../store/auth';
import { useAppDispatch } from '../../store/hooks';

const Logout: React.FC = () => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(authLogout());
	}, [dispatch]);

	return <Redirect to="/" />;
};

export default Logout;
