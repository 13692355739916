import {
	AttributeType,
	AuthenticationResultType,
	AuthFlowType,
	ChangePasswordCommandInput,
	CodeDeliveryDetailsType,
	CognitoIdentityProvider,
	CognitoIdentityProviderServiceException,
	ExpiredCodeException,
	GetUserCommandOutput,
	InitiateAuthCommandOutput,
	InitiateAuthResponse,
	ResendConfirmationCodeCommandOutput,
	SignUpCommandOutput,
	UpdateUserAttributesCommandOutput,
	UserNotConfirmedException,
	VerifyUserAttributeCommandInput,
} from '@aws-sdk/client-cognito-identity-provider';
import {
	createAsyncThunk,
	createSlice,
	SerializedError,
} from '@reduxjs/toolkit';

import { RootState } from '..';
import { newAPI } from '../../api';
import { ClientId, credentials, region } from '../../aws/credentials';
import normalizeField from '../../util/valida/normalize';
import { errorHandler } from '../errors/errors';
import { getRights, resetRights } from '../rights';

export interface UserState {
	auth?: AuthenticationResultType & { Issued?: number };
	username?: string;
	attributes?: AttributeType[];
	session?: string;
	logged: boolean;
	loading: boolean;
	password?: 'changed' | 'failed';
	forgot: boolean;
	verify?: CodeDeliveryDetailsType[];
	error?: SerializedError;
}

export interface LoginParams {
	Password?: string;
	Username?: string;
	GoogleToken?: string;
	ConfirmationCode?: string;
	RefreshToken?: string;
	KeepMeLogged?: boolean;
}

export interface SignUpParams extends LoginParams {
	Name: string;
	Phone?: string;
}

const initialState: UserState = {
	logged: false,
	loading: false,
	forgot: false,
};

const identityProvider = new CognitoIdentityProvider({
	region,
	credentials: credentials({}),
});

const authSlice = createSlice({
	name: 'authentication',
	initialState,
	reducers: {
		authDismissValidations: (state) => {
			state.verify = undefined;
		},
		authDismissError: (state) => {
			state.error = undefined;
		},
		authPasswordStatus: (state, action) => {
			state.password = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(authLogin.fulfilled, (state, action) => {
				const { payload } = action as { payload: InitiateAuthResponse };
				state.auth = {
					...payload.AuthenticationResult,
					Issued: new Date().getTime(),
				};
				state.session = payload.Session;
				state.logged = true;
				state.loading = false;
				state.error = undefined;
			})
			.addCase(authLogin.pending, (state) => {
				state.loading = true;
			})
			.addCase(authLogin.rejected, (state, { payload }) => {
				state.error = payload;
				state.logged = false;
				state.loading = false;
			});
		builder
			.addCase(authGetUser.fulfilled, (state, action) => {
				const { payload } = action as { payload: GetUserCommandOutput };
				state.username = payload.Username;
				state.attributes = payload.UserAttributes;
				state.loading = false;
				state.error = undefined;
			})
			.addCase(authGetUser.pending, (state) => {
				state.loading = true;
			})
			.addCase(authGetUser.rejected, (state, { error }) => {
				state.error = error;
				state.loading = false;
			});
		builder
			.addCase(authForgotPassword.fulfilled, (state) => {
				state.forgot = true;
				state.loading = false;
				state.error = undefined;
			})
			.addCase(authForgotPassword.pending, (state) => {
				state.loading = true;
			})
			.addCase(authForgotPassword.rejected, (state, { error }) => {
				state.error = error;
				state.loading = false;
			});
		builder
			.addCase(authConfirmForgotPassword.fulfilled, (state) => {
				state.forgot = false;
				state.loading = false;
				state.error = undefined;
			})
			.addCase(authConfirmForgotPassword.pending, (state) => {
				state.loading = true;
			})
			.addCase(authConfirmForgotPassword.rejected, (state, { error }) => {
				state.error = error;
				state.loading = false;
			});
		builder
			.addCase(authSignUp.fulfilled, (state, action) => {
				const { payload } = action as { payload: SignUpCommandOutput };
				state.verify = [
					...(payload.CodeDeliveryDetails ? [payload.CodeDeliveryDetails] : []),
				];
				state.loading = false;
				state.error = undefined;
			})
			.addCase(authSignUp.pending, (state) => {
				state.loading = true;
			})
			.addCase(authSignUp.rejected, (state, { error }) => {
				state.error = error;
				state.loading = false;
			});
		builder
			.addCase(authResendConfirmationCode.fulfilled, (state, action) => {
				const { payload } = action as {
					payload: ResendConfirmationCodeCommandOutput;
				};
				state.verify = [
					...(payload.CodeDeliveryDetails ? [payload.CodeDeliveryDetails] : []),
				];
				state.loading = false;
				state.error = undefined;
			})
			.addCase(authResendConfirmationCode.pending, (state) => {
				state.loading = true;
			})
			.addCase(authResendConfirmationCode.rejected, (state, { error }) => {
				state.error = error;
				state.verify = undefined;
				state.loading = false;
			});
		builder
			.addCase(authConfirmSignUp.fulfilled, (state) => {
				state.verify = undefined;
				state.loading = false;
				state.error = undefined;
			})
			.addCase(authConfirmSignUp.pending, (state) => {
				state.loading = true;
			})
			.addCase(authConfirmSignUp.rejected, (state, { error }) => {
				state.error = error;
				state.loading = false;
			});
		builder
			.addCase(authLogout.fulfilled, (state) => {
				state.auth = undefined;
				state.session = undefined;
				state.attributes = undefined;
				state.username = undefined;
				state.logged = false;
				state.loading = false;
				state.error = undefined;
			})
			.addCase(authLogout.pending, (state) => {
				state.loading = true;
			})
			.addCase(authLogout.rejected, (state, { error }) => {
				state.auth = undefined;
				state.session = undefined;
				state.attributes = undefined;
				state.username = undefined;
				state.logged = false;
				state.error = error;
				state.loading = false;
			});
		builder
			.addCase(authUpdateUserAttributes.fulfilled, (state, action) => {
				const { payload } = action as {
					payload: UpdateUserAttributesCommandOutput;
				};
				state.verify = payload.CodeDeliveryDetailsList;
				state.error = undefined;
			})
			.addCase(authUpdateUserAttributes.pending, (state) => {
				state.loading = true;
			})
			.addCase(authUpdateUserAttributes.rejected, (state, { error }) => {
				state.error = error;
				state.loading = false;
			});
		builder
			.addCase(authChangePassword.fulfilled, (state) => {
				state.password = 'changed';
				state.loading = false;
				state.error = undefined;
			})
			.addCase(authChangePassword.pending, (state) => {
				state.loading = true;
			})
			.addCase(authChangePassword.rejected, (state, { error }) => {
				state.password = 'failed';
				state.error = error;
				state.loading = false;
			});
		builder
			.addCase(authVerifyUserAttribute.fulfilled, (state) => {
				state.verify = state.verify?.filter((code, index) => index !== 0);
				state.error = undefined;
			})
			.addCase(authVerifyUserAttribute.pending, (state) => {
				state.loading = true;
			})
			.addCase(authVerifyUserAttribute.rejected, (state, { error }) => {
				state.error = error;
				state.loading = false;
			});
	},
});

export const authLogin = createAsyncThunk<
	InitiateAuthCommandOutput,
	Pick<LoginParams, 'Username' | 'Password' | 'RefreshToken' | 'KeepMeLogged'>,
	{ rejectValue: SerializedError }
>(
	'authentication/login',
	async (
		{ Username, Password, RefreshToken, KeepMeLogged },
		{ dispatch, rejectWithValue }
	) => {
		try {
			const data = await identityProvider.initiateAuth({
				AuthFlow: RefreshToken
					? AuthFlowType.REFRESH_TOKEN
					: AuthFlowType.USER_PASSWORD_AUTH,
				AuthParameters: {
					...(Username ? { USERNAME: Username, PASSWORD: Password } : {}),
					...(RefreshToken ? { REFRESH_TOKEN: RefreshToken } : {}),
				},
				ClientId,
			});

			const { AuthenticationResult } = data;

			if (AuthenticationResult?.AccessToken) {
				dispatch(authGetUser(AuthenticationResult?.AccessToken));
			}

			if (AuthenticationResult?.IdToken) {
				newAPI.defaults.headers.common.Authorization =
					AuthenticationResult.IdToken;

				dispatch(getRights());
			}

			if (AuthenticationResult?.RefreshToken) {
				sessionStorage.setItem(
					'RefreshToken',
					AuthenticationResult?.RefreshToken
				);

				if (KeepMeLogged) {
					localStorage.setItem(
						'RefreshToken',
						AuthenticationResult?.RefreshToken
					);
				}
			}

			return data;
		} catch (error) {
			if (error instanceof UserNotConfirmedException) {
				if (Username) dispatch(authResendConfirmationCode(Username));
			}
			return rejectWithValue(error as Error);
		}
	}
);

// export const authFederatedLogin = createAsyncThunk(
// 	'authentication/federatedLogin',
// 	async (
// 		{ GoogleToken }: Pick<LoginParams, 'GoogleToken'>,
// 		{ dispatch, rejectWithValue }
// 	) => {
// 		try {
// 			const googleCredentials = credentials({
// 				googleToken: GoogleToken,
// 			});

// 			console.log(googleCredentials);
// 		} catch (error) {
// 			// error handling.
// 		}
// 	}
// );

export const authGetUser = createAsyncThunk(
	'authentication/getUser',
	async (
		AccessToken: AuthenticationResultType['AccessToken'],
		{ rejectWithValue, getState }
	) => {
		try {
			const state = getState() as RootState;
			AccessToken = AccessToken ?? state.user.auth?.AccessToken;

			const data = await identityProvider.getUser({
				AccessToken,
			});

			return data;
		} catch (error) {
			return rejectWithValue(
				errorHandler(error as CognitoIdentityProviderServiceException)
			);
		}
	}
);

export const authUpdateUserAttributes = createAsyncThunk(
	'authentication/updateUserAttributes',
	async (
		UserAttributes: AttributeType[],
		{ dispatch, rejectWithValue, getState }
	) => {
		try {
			const state = getState() as RootState;
			const AccessToken = state.user.auth?.AccessToken;

			UserAttributes = UserAttributes.map((attr) => ({
				Name: attr.Name,
				Value:
					attr.Name && attr.Value ? normalizeField(attr.Name, attr.Value) : '',
			}));

			if (!AccessToken) {
				dispatch(authLogout());
			} else {
				const data = await identityProvider.updateUserAttributes({
					AccessToken,
					UserAttributes,
				});

				dispatch(authGetUser(AccessToken));
				return data;
			}
		} catch (error) {
			return rejectWithValue(
				errorHandler(error as CognitoIdentityProviderServiceException)
			);
		}
	}
);

export const authVerifyUserAttribute = createAsyncThunk(
	'authentication/verifyUserAttribute',
	async (
		{
			AttributeName,
			Code,
		}: Pick<VerifyUserAttributeCommandInput, 'AttributeName' | 'Code'>,
		{ dispatch, rejectWithValue, getState }
	) => {
		try {
			const state = getState() as RootState;
			const AccessToken = state.user.auth?.AccessToken;

			if (!AccessToken) {
				dispatch(authLogout());
			} else {
				const data = await identityProvider.verifyUserAttribute({
					AccessToken,
					AttributeName,
					Code,
				});

				dispatch(authGetUser(AccessToken));
				return data;
			}
		} catch (error) {
			return rejectWithValue(
				errorHandler(error as CognitoIdentityProviderServiceException)
			);
		}
	}
);

export const authForgotPassword = createAsyncThunk(
	'authentication/forgotPassword',
	async (Username: string, { rejectWithValue }) => {
		try {
			const data = await identityProvider.forgotPassword({
				ClientId,
				Username,
			});
			console.info(
				`Code sent by ${data.CodeDeliveryDetails?.DeliveryMedium} to ${data.CodeDeliveryDetails?.Destination}`
			);

			return data;
		} catch (error) {
			return rejectWithValue(
				errorHandler(error as CognitoIdentityProviderServiceException)
			);
		}
	}
);

export const authConfirmForgotPassword = createAsyncThunk(
	'authentication/confirmForgotPassword',
	async (
		{
			Username,
			Password,
			ConfirmationCode,
		}: Pick<LoginParams, 'Username' | 'Password' | 'ConfirmationCode'>,
		{ dispatch, rejectWithValue }
	) => {
		try {
			const data = await identityProvider.confirmForgotPassword({
				ClientId,
				ConfirmationCode,
				Username,
				Password,
			});

			console.info('Password confirmed!');

			dispatch(
				authLogin({
					Username,
					Password,
				})
			);

			return data;
		} catch (error) {
			return rejectWithValue(
				errorHandler(error as CognitoIdentityProviderServiceException)
			);
		}
	}
);

export const authSignUp = createAsyncThunk(
	'authentication/signUp',
	async (
		{
			Username,
			Password,
			Name,
			Phone,
		}: Pick<SignUpParams, 'Username' | 'Password' | 'Name' | 'Phone'>,
		{ rejectWithValue }
	) => {
		try {
			const UserAttributes: AttributeType[] = [
				{
					Name: 'name',
					Value: normalizeField(
						'name',
						Name.split(' ')
							.map((value) =>
								value.length > 2
									? value.replace(
											value.charAt(0),
											value.charAt(0).toUpperCase()
										)
									: value
							)
							.join(' ')
					),
				},
				{
					Name: 'email',
					Value: normalizeField('email', Username ?? ''),
				},
				...(Phone
					? [
							{
								Name: 'phone_number',
								Value: `${Phone?.replace(/\(|\)|_|-| /g, '')}`,
							},
						]
					: []),
			];

			const data = await identityProvider.signUp({
				ClientId,
				Username,
				Password,
				UserAttributes,
			});

			return data;
		} catch (error) {
			return rejectWithValue(
				errorHandler(error as CognitoIdentityProviderServiceException)
			);
		}
	}
);

export const authResendConfirmationCode = createAsyncThunk(
	'authentication/resendConfirmationCode',
	async (Username: string, { rejectWithValue }) => {
		try {
			const data = await identityProvider.resendConfirmationCode({
				ClientId,
				Username,
			});

			return data;
		} catch (error) {
			const { name, message, stack } =
				error as CognitoIdentityProviderServiceException;
			return rejectWithValue({ name, message, stack });
		}
	}
);

export const authConfirmSignUp = createAsyncThunk(
	'authentication/confirmSignUp',
	async (
		{
			Username,
			Password,
			ConfirmationCode,
		}: Pick<LoginParams, 'Username' | 'Password' | 'ConfirmationCode'>,
		{ dispatch, rejectWithValue }
	) => {
		try {
			const data = await identityProvider.confirmSignUp({
				ClientId,
				Username,
				ConfirmationCode,
			});

			dispatch(authLogin({ Username, Password }));

			return data;
		} catch (error) {
			if (error instanceof ExpiredCodeException) {
				if (Username) dispatch(authResendConfirmationCode(Username));
			}
			return rejectWithValue(
				errorHandler(error as CognitoIdentityProviderServiceException)
			);
		}
	}
);

export const authChangePassword = createAsyncThunk(
	'authentication/changePassword',
	async (
		{
			PreviousPassword,
			ProposedPassword,
		}: Omit<ChangePasswordCommandInput, 'AccessToken'>,
		{ getState, rejectWithValue }
	) => {
		try {
			const state = getState() as RootState;
			const AccessToken = state.user.auth?.AccessToken;

			const data = await identityProvider.changePassword({
				AccessToken,
				PreviousPassword,
				ProposedPassword,
			});

			return data;
		} catch (error) {
			return rejectWithValue(
				errorHandler(error as CognitoIdentityProviderServiceException)
			);
		}
	}
);

export const authRefresh = createAsyncThunk(
	'authentication/refresh',
	async (_, { dispatch, getState }) => {
		const state = getState() as RootState;
		const { logged } = state.user;
		const { RefreshToken, ExpiresIn, Issued } = state.user.auth ?? {};

		const expired =
			logged && (Issued ?? 0) + (ExpiresIn ?? 0) * 1000 < new Date().getTime();

		if (!expired) return true;

		console.info(
			`Token expired ${new Date(
				(Issued ?? 0) + (ExpiresIn ?? 0) * 1000
			).toLocaleString()}`
		);

		if (RefreshToken) {
			const auth = await dispatch(authLogin({ RefreshToken })).unwrap();

			if (auth.AuthenticationResult?.AccessToken) return true;
		} else {
			dispatch(authLogout());
		}
		return false;
	}
);

export const authLogout = createAsyncThunk(
	'authentication/logout',
	async (_, { dispatch, rejectWithValue, getState }) => {
		try {
			const state = getState() as RootState;
			const AccessToken = state.user.auth?.AccessToken;

			const data = await identityProvider.globalSignOut({
				AccessToken,
			});

			sessionStorage.clear();
			localStorage.clear();
			dispatch(resetRights());

			return data;
		} catch (error) {
			localStorage.clear();
			sessionStorage.clear();
			dispatch(resetRights());
			return rejectWithValue(
				errorHandler(error as CognitoIdentityProviderServiceException)
			);
		}
	}
);

export const { authDismissValidations, authDismissError, authPasswordStatus } =
	authSlice.actions;

export default authSlice.reducer;
