import { S3ServiceException, S3 } from '@aws-sdk/client-s3';
import {
	createAsyncThunk,
	createSlice,
	SerializedError,
} from '@reduxjs/toolkit';

import { RootState } from '..';
import { credentials, region } from '../../aws/credentials';
import { errorHandler } from '../errors/errors';

interface ImageState {
	imageUrl?: string;
	loading: boolean;
	error?: SerializedError;
}

interface UploadParams {
	image: Blob;
	domain: string;
	entity: string;
	type: string;
	updateFunction?: (imageUrl: string) => void;
}

const initialState: ImageState = {
	loading: false,
	error: undefined,
};

const Bucket = 'img.pontodoesporte.com.br';

const imageSlice = createSlice({
	name: 'images',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(uploadImage.fulfilled, (state) => {
				state.loading = false;
				state.error = undefined;
			})
			.addCase(uploadImage.pending, (state) => {
				state.loading = true;
			})
			.addCase(uploadImage.rejected, (state, action) => {
				const { payload } = action as {
					payload: SerializedError;
				};
				state.error = payload;
				state.loading = false;
			});
	},
});

export const uploadImage = createAsyncThunk(
	'images/upload',
	async (
		{ image, domain, entity, type, updateFunction }: UploadParams,
		{ rejectWithValue, getState }
	) => {
		const state = getState() as RootState;

		const s3Client = new S3({
			region,
			credentials: credentials({ idToken: state.user.auth?.IdToken }),
		});

		try {
			const Key = `${domain}/${entity}/${type}/${Date.now()}${Math.floor(
				Math.random() * 10000000 + 1
			)}.${image.type.split('/')[1]}`;

			const data = await s3Client.putObject({
				Bucket,
				Key,
				Body: image,
				ContentType: image.type,
				ACL: 'public-read',
			});

			console.info('Image uploaded: ' + 'https://' + Bucket + '/' + Key);

			if (updateFunction) {
				updateFunction('https://' + Bucket + '/' + Key);
			}

			return data;
		} catch (error) {
			console.error(error);

			return rejectWithValue(errorHandler(error as S3ServiceException));
		}
	}
);

// export const { imageLoad } = imageSlice.actions;

export default imageSlice.reducer;
