import React, { useEffect } from 'react';

import { Container, Header, Image } from 'semantic-ui-react';
import { useHeadSafe } from 'unhead';

import Terms from './terms';

const Index: React.FC = () => {
	useHeadSafe({
		templateParams: {
			site: {
				name: 'Ponto do Esporte',
				url: 'https://www.pontodoesporte.com.br',
			},
			separator: '|',
		},
		title: 'Termos de Uso',
		titleTemplate: '%s %separator %site.name',
		meta: [
			{
				name: 'description',
				content: '%s do %site.name.',
			},
			{
				property: 'og:site_name',
				content: '%site.name',
			},
			{
				property: 'og:url',
				content: '%site.url/terms',
			},
		],
	});

	useEffect(() => {
		const element = document.getElementById('terms');
		const y = element?.getBoundingClientRect()?.top ?? 0 + window.scrollY;
		window.scroll({
			top: y,
			behavior: 'smooth',
		});
	}, []);

	return (
		<Container
			id="terms"
			style={{
				paddingTop: '40px',
				paddingBottom: '40px',
			}}
		>
			<Header
				style={{
					color: '#aace46',
					fontSize: '28px',
				}}
			>
				<Image src="img/pe/seta.gif" size="big" />
				Termos de Uso
			</Header>
			<Terms />
		</Container>
	);
};

export default Index;
