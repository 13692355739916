import { useEffect, useState } from 'react';

export const useDomain = () => {
	const [protocol, setProtocol] = useState<string>();
	const [sub, setSub] = useState<string>();
	const [domain, setDomain] = useState<string>();
	const [port, setPort] = useState<string>();
	const [host, setHost] = useState<string>();

	useEffect(() => {
		const hostname = window.location.hostname;

		const split = hostname
			.split('.')
			.slice(0, hostname.includes('localhost') ? -1 : -3);

		if (split.length > 0) {
			setSub(split[0]);
			setDomain(split.slice(1).join('.'));
		} else {
			setSub(undefined);
			setDomain(hostname);
		}
		setProtocol(window.location.protocol);
		setPort(window.location.port);
		setHost(window.location.host);
	}, []);

	return { protocol, sub, domain, port, host };
};
