import {
	PayloadAction,
	SerializedError,
	// createAsyncThunk,
	createSlice,
} from '@reduxjs/toolkit';
// import { SemanticCOLORS } from 'semantic-ui-react';

// import { api } from '../../api';
// import checkValidity from '../../util/valida/check';
import { Allocation, Hour } from '../allocations';
// import { authRefresh } from '../auth';
import { Court } from '../organizations';
import { Sport } from '../sports';

export type Ocupation = {
	id?: string;
	/** Court Id */
	court?: Court['id'];
	/** Day of week [0 | 1 | 2 | 3 | 4 | 5 | 6] */
	day: number;
	/** Initial date */
	beginDate: number;
	/** Final date */
	endDate?: number;
	/** Initial hour[00:00] */
	startHour?: string;
	/** Final hour [00:00] */
	finishHour?: string;
	/** Sport */
	sport?: Sport;
	/** Destination */
	dest?: string;
	active?: boolean;
};

interface OcupationState {
	ocupations: Ocupation[];
	editing?: Ocupation[];
	loading: boolean;
	error?: SerializedError;
}

const initialState: OcupationState = {
	ocupations: [],
	loading: false,
};

export const ocupationSlice = createSlice({
	name: 'ocupations',
	initialState: initialState,
	reducers: {
		editOcupation: (
			state,
			{ payload }: PayloadAction<(Hour & Partial<Allocation>)[]>
		) => {
			state.editing = separateOcupations(payload);
		},
		addOcupation: (state, { payload }: PayloadAction<Ocupation>) => {
			state.editing = [...(state.editing ?? []), payload];
		},
		changeOcupation: (
			state,
			{
				payload,
			}: PayloadAction<{ field: keyof Ocupation; value: string; idx: number }>
		) => {
			const { field, value, idx } = payload;
			state.editing = state.editing?.map((ocupation, index) =>
				index === idx
					? {
							...ocupation,
							[field]: value,
						}
					: ocupation
			);
		},
		removeOcupation: (state, { payload }: PayloadAction<number>) => {
			state.editing = state.editing?.filter((ocp, index) => index !== payload);
		},
		resetOcupation: (state) => {
			state.editing = undefined;
		},
		resetOcupations: (state) => {
			state.ocupations = [];
		},
	},
	// extraReducers: (builder) => {},
});

const separateOcupations = (selected: (Hour & Partial<Allocation>)[]) => {
	// This function will eliminate the slots that doesn't have destination
	// and only select if the destination is equal to the slot that opened the context menu

	const { dest } = selected[selected.length - 1];

	const orderSelected = (selected: (Hour & Partial<Allocation>)[]) =>
		selected.sort((a, b) => (a.time > b.time ? 1 : a.time < b.time ? -1 : 0));

	const separatedByCourt = selected.reduce(
		(chunk: { [x: string]: (Hour & Partial<Allocation>)[] }, sel) => {
			return {
				...chunk,
				...(sel.court && {
					[sel.court]:
						dest && sel.dest === dest
							? orderSelected([...(chunk[sel.court] ?? []), sel])
							: (chunk[sel.court] ?? []),
				}),
			};
		},
		{}
	);

	const transformSelectedToOcupations = (
		selected: (Hour & Partial<Allocation>)[]
	) =>
		selected.reduce(
			(ocupations: Ocupation[], selected: Hour & Partial<Allocation>) => {
				const lastOcupation =
					ocupations.length > 0 ? ocupations[ocupations.length - 1] : undefined;
				const selectedSartDay = new Date(selected.time).getDay();
				const selectedStartTime = new Date(selected.time).toLocaleTimeString(
					undefined,
					{
						hour: '2-digit',
						minute: '2-digit',
					}
				);

				if (!selected.dest || !selected.sport) return ocupations;

				if (
					lastOcupation &&
					lastOcupation.court === selected.court &&
					lastOcupation.day === selectedSartDay &&
					lastOcupation.finishHour &&
					lastOcupation.finishHour >= selectedStartTime
				)
					return [
						...ocupations.slice(0, -1),
						{
							...lastOcupation,
							finishHour: new Date(selected.next).toLocaleTimeString(
								undefined,
								{
									hour: '2-digit',
									minute: '2-digit',
								}
							),
						},
					];

				return [
					...ocupations,
					{
						day: new Date(selected.time).getDay(),
						beginDate: new Date(selected.time).setHours(0, 0, 0, 0),
						startHour: new Date(selected.time).toLocaleTimeString(undefined, {
							hour: '2-digit',
							minute: '2-digit',
						}),
						finishHour: new Date(selected.next).toLocaleTimeString(undefined, {
							hour: '2-digit',
							minute: '2-digit',
						}),
						court: selected.court,
						sport: selected.sport,
						dest: selected.dest,
					},
				];
			},
			[]
		);

	const ocupations = Object.values(separatedByCourt).flatMap((selected) =>
		transformSelectedToOcupations(selected)
	);

	return ocupations;
};

export const {
	editOcupation,
	addOcupation,
	changeOcupation,
	removeOcupation,
	resetOcupation,
	resetOcupations,
} = ocupationSlice.actions;

export default ocupationSlice.reducer;
