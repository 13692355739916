import {
	createAsyncThunk,
	createSlice,
	SerializedError,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { SemanticICONS } from 'semantic-ui-react';

import { api } from '../../api';
import { Profile } from '../access';
import { authRefresh } from '../auth';

export interface RightItem {
	/**
		'dashboard' // Menu Dashboard 
 		'place' // Menu Locais
  		'event' // Menu Eventos
   		'league' // Menu Ligas
    	'user' // Menu Usuário (mobile)
		'club' // 1o nível para Locais (Clubes)
	  	'academy' // 1o nível para Locais (Academias) - a implementar
	  	'private' // 1o nível para Locais (Condomínios) - a implementar
	  	'personal' // 1o nível para Locais (Personais) - a implementar
	  	'champ' // 1o nível para Eventos (Campeonatos)
	  	'gather' // 1o nível para Eventos (Confraternizações) - a implementar
	  	'circuit' // 1o nível para Ligas (Circuitos)
	  	'unit' // 2o nível para Clubes (Departamentos)
	  	'tourn' // 2o nível para Campeonatos (Torneios)
	  	'rank'; // 2o nível para Circuitos (Rankings)
	*/
	type:
		| 'home'
		| 'dashboard'
		| 'place'
		| 'event'
		| 'league'
		| 'user'
		| 'club'
		| 'academy'
		| 'private'
		| 'personal'
		| 'champ'
		| 'gather'
		| 'circuit'
		| 'unit'
		| 'tourn'
		| 'rank';
	id: string;
	name: string;
	nick?: string;
	icon?: SemanticICONS;
	info?: string;
	img?: string;
	sports?: string[];
	link?: string;
	super: string;
	place?: string;
	profile?: Profile[];
	redirect?: boolean;
}

interface RightsState {
	rights: RightItem[];
	loading: boolean;
	error?: SerializedError;
}

const initialState: RightsState = {
	rights: [],
	loading: false,
};

const rightSlice = createSlice({
	name: 'rights',
	initialState,
	reducers: {
		resetRights: (state) => {
			state.rights = initialState.rights;
			state.loading = initialState.loading;
			state.error = undefined;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getRights.fulfilled, (state, { payload }) => {
				state.rights = payload;
				state.loading = false;
				state.error = undefined;
			})
			.addCase(getRights.pending, (state) => {
				state.loading = true;
				state.error = undefined;
			})
			.addCase(getRights.rejected, (state, { error }) => {
				state.error = error;
				state.loading = false;
			});
	},
});

export const getRights = createAsyncThunk<
	RightItem[],
	void,
	{ rejectValue: SerializedError }
>('rights/getRights', async (_, { dispatch, rejectWithValue }) => {
	try {
		await dispatch(authRefresh());

		const { data } = await api.rights.get();

		return data;
	} catch (error) {
		return rejectWithValue(
			(error as AxiosError).response?.data as SerializedError
		);
	}
});

export const { resetRights } = rightSlice.actions;

export default rightSlice.reducer;
