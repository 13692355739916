const normalize = (value: string) =>
	value
		.trim()
		.toLowerCase()
		.normalize('NFD')
		.replace(/[\u0300-\u036f]/g, '');

const normalizeField = (field: string, value: string) => {
	switch (field) {
		case 'mail':
			return normalize(value);
		case 'email':
			return normalize(value);
		default:
			return value.trim();
	}
};

export default normalizeField;
