export const validaCNPJ = (cnpj: string) => {
	// Remove todos os caracteres que não sejam números
	cnpj = cnpj.replace(/[^\d]+/g, '');

	// Se o CNPJ não tem 14 dígitos ou todos os dígitos são repetidos, o CPF é inválido
	if (cnpj.length !== 14 || !!cnpj.match(/(\d)\1{10}/)) return false;

	let dv1CNPJ =
		cnpj
			.slice(0, 12)
			.split('')
			.reduce(
				(sum, cur, ind) =>
					sum + parseInt(cur, 10) * (ind < 4 ? 5 - ind : 13 - ind),
				0
			) % 11;

	if (dv1CNPJ < 2) {
		dv1CNPJ = 0;
	} else {
		dv1CNPJ = 11 - dv1CNPJ;
	}

	let dv2CNPJ =
		cnpj
			.slice(0, 13)
			.split('')
			.reduce(
				(sum, cur, ind) =>
					sum + parseInt(cur, 10) * (ind < 5 ? 6 - ind : 14 - ind),
				0
			) % 11;

	if (dv2CNPJ < 2) {
		dv2CNPJ = 0;
	} else {
		dv2CNPJ = 11 - dv2CNPJ;
	}

	if (
		cnpj.charAt(12) !== dv1CNPJ.toString(10) ||
		cnpj.charAt(13) !== dv2CNPJ.toString(10)
	)
		return false;

	return true;
};
