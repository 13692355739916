import {
	SerializedError,
	createAsyncThunk,
	createSlice,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { api } from '../../api';
import { authRefresh } from '../auth';
import { Event, Tournament } from '../events';
import { Organization } from '../organizations';
import { setTournaments } from '../tournaments';

export type Championship = {
	event: Event;
	organization: Organization;
	tournaments: Tournament[];
};

interface ChampsState {
	championship?: Pick<Championship, 'event' | 'organization'>;
	loading: boolean;
	error?: SerializedError;
}

const initialState: ChampsState = {
	championship: undefined,
	loading: false,
	error: undefined,
};

export const champSlice = createSlice({
	name: 'championships',
	initialState,
	reducers: {
		resetChamp: (state) => {
			state.championship = undefined;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getChamp.fulfilled, (state, { payload }) => {
				state.championship = {
					event: payload.event,
					organization: payload.organization,
				};
				state.loading = false;
				state.error = undefined;
			})
			.addCase(getChamp.pending, (state) => {
				state.loading = true;
				state.error = undefined;
			})
			.addCase(getChamp.rejected, (state, { payload }) => {
				state.loading = false;
				state.error = payload;
			});
	},
});

export const getChamp = createAsyncThunk<
	Championship,
	{ tourn: string },
	{ rejectValue: SerializedError }
>(
	'championships/getChamp',
	async ({ tourn }, { rejectWithValue, dispatch }) => {
		try {
			await dispatch(authRefresh());

			const { data } = await api.championships.get(tourn);

			if (data.tournaments) dispatch(setTournaments(data.tournaments));

			return data;
		} catch (error) {
			return rejectWithValue(
				(error as AxiosError).response?.data as SerializedError
			);
		}
	}
);

export const { resetChamp } = champSlice.actions;

export default champSlice.reducer;
