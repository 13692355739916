import {
	createAsyncThunk,
	createSlice,
	SerializedError,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { api } from '../../api';
import { authRefresh } from '../auth';
import { Enrollment, setEnrollments } from '../enrollments';
import { Event, Tournament } from '../events';
import { Organization } from '../organizations';
import { Person } from '../people';
import { setTournaments } from '../tournaments';

export type Occasion = {
	event: Event;
	organization: Organization;
	tournaments?: Tournament[];
	enrollments?: Enrollment[];
	member?: Person;
};

interface OccasionState {
	event?: Event;
	organization?: Organization;
	member?: Person;
	loading: boolean;
	error?: SerializedError;
}

const initialState: OccasionState = {
	loading: false,
	error: undefined,
};

export const occasionSlice = createSlice({
	name: 'occasion',
	initialState,
	reducers: {
		resetOccasion: (state) => {
			state.event = undefined;
			state.organization = undefined;
			state.member = undefined;
			state.loading = false;
			state.error = undefined;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getOccasion.fulfilled, (state, { payload }) => {
				state.event = payload.event;
				state.organization = payload.organization;
				state.member = payload.member;
				state.loading = false;
				state.error = undefined;
			})
			.addCase(getOccasion.pending, (state) => {
				state.loading = true;
				state.error = undefined;
			})
			.addCase(getOccasion.rejected, (state, { payload }) => {
				state.loading = false;
				state.error = payload;
			});
	},
});

export const getOccasion = createAsyncThunk<
	Occasion,
	string,
	{ rejectValue: SerializedError }
>('occasion/getOccasion', async (event, { rejectWithValue, dispatch }) => {
	try {
		await dispatch(authRefresh());

		const { data } = await api.occasion.get(event);

		if (data.enrollments) dispatch(setEnrollments(data.enrollments));
		if (data.tournaments) dispatch(setTournaments(data.tournaments));

		return data;
	} catch (error) {
		return rejectWithValue(
			(error as AxiosError).response?.data as SerializedError
		);
	}
});

export const { resetOccasion } = occasionSlice.actions;

export default occasionSlice.reducer;
