import {
	PayloadAction,
	SerializedError,
	createAsyncThunk,
	createSlice,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { MessageProps, SemanticCOLORS } from 'semantic-ui-react';

import { api } from '../../api';
import { combination } from '../../util/combina';
import { authRefresh } from '../auth';
import { Enrollment } from '../enrollments';
import { Match } from '../matches';
import { Criteria, Sport } from '../sports';

export type Load = {
	loading?: boolean;
};

export type Draw = 'knockout' | 'group';

export type Stat = Partial<Record<Criteria['sign'], number>>;

export type Confront = {
	teams: [Team, Team];
	match?: Match;
	loading?: boolean;
	loosers?: boolean;
};

export type Stats = {
	stats?: Stat;
};

export type Position = {
	position?: number;
};

export type TeamType = 'seed' | 'qualy' | 'winner' | 'looser' | 'wo' | 'bye';

export type Team = {
	id?: string;
	type: TeamType;
	title?: string;
	logo?: string;
	pos?: number;
	group?: string;
	qualy?: string;
	game?: number;
};

export type Group = {
	closed: boolean;
	fase: number;
	teams?: Team[];
	loosers?: Team[];
	draw: Draw;
	id: string;
	color: SemanticCOLORS;
	tourn: string;
	title: string;
};

export type Statistics = {
	group?: Group & Load;
	teams?: (Team & Stats & Position)[];
};

interface GroupsState {
	groups: (Group & Load)[];
	stats?: Statistics;
	changed: string[];
	dragged?: Team;
	loading: boolean;
	error?: SerializedError;
	message?: MessageProps & { group?: Group['id'] };
}

const initialState: GroupsState = {
	groups: [],
	changed: [],
	loading: false,
	error: undefined,
};

export const groupSlice = createSlice({
	name: 'groups',
	initialState: initialState,
	reducers: {
		changeGroup: (
			state,
			{
				payload,
			}: PayloadAction<{
				field: keyof Group;
				value:
					| string
					| number
					| boolean
					| (string | number | boolean)[]
					| undefined;
				group: string;
			}>
		) => {
			if (!state.groups.find((group) => group.id === payload.group)?.closed) {
				state.changed = state.changed
					.filter((changed) => changed !== payload.group)
					.concat(payload.group);
				state.groups = state.groups.map((group) =>
					group.id === payload.group
						? {
								...group,
								[payload.field]: payload.value,
							}
						: group
				);
			}
		},
		updateTeams: (state, { payload }: PayloadAction<Enrollment[]>) => {
			state.groups = updateTeamsTitle(state.groups, payload);
		},
		dragTeam: (state, { payload }: PayloadAction<Team>) => {
			state.dragged = payload;
		},
		dragClear: (state) => {
			state.dragged = undefined;
		},
		calcGroupStats: (
			state,
			{
				payload,
			}: PayloadAction<{
				sport: Sport;
				group: Group;
				matches: Match[];
				criteria?: Criteria[];
				hasThird: boolean;
			}>
		) => {
			const activeMatches = payload.matches.filter(
				(match) => !('active' in match) || match.active
			);

			state.stats = {
				group: payload.group,
				...(payload.group.draw === 'group' && payload.criteria
					? {
							teams: orderByTeamStats(
								payload.group,
								activeMatches,
								payload.criteria,
								payload.sport
							),
						}
					: {}),
				...(payload.group.draw === 'knockout'
					? {
							teams: orderTeamPosition(
								payload.group,
								activeMatches,
								payload.hasThird
							),
						}
					: {}),
			};
		},
		resetMessage: (state) => {
			state.message = undefined;
		},
		resetStats: (state) => {
			state.stats = undefined;
		},
		resetGroups: (state) => {
			state.groups = [];
			state.changed = [];
			state.dragged = undefined;
			state.stats = undefined;
			state.loading = false;
			state.error = undefined;
			state.message = undefined;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getGroups.fulfilled, (state, { payload }) => {
				state.changed = [];
				state.groups = sortGroups(setTeams(payload));
				state.loading = false;
				state.error = undefined;
			})
			.addCase(getGroups.pending, (state) => {
				state.loading = true;
			})
			.addCase(getGroups.rejected, (state, { payload }) => {
				state.error = payload;
				state.loading = false;
			});
		builder
			.addCase(insertGroup.fulfilled, (state, { payload }) => {
				state.changed = state.changed.filter(
					(changed) => changed !== payload.id
				);
				state.groups = sortGroups(
					setTeams(
						state.groups.some((group) => group.id === payload.id)
							? state.groups.map((group) =>
									group.id === payload.id ? payload : group
								)
							: state.groups.concat(payload)
					)
				);
				state.error = undefined;
				state.loading = false;
			})
			.addCase(insertGroup.pending, (state, action) => {
				state.groups = state.groups.map((group) =>
					group.id === action.meta.arg.id
						? {
								...group,
								loading: true,
							}
						: group
				);
				state.loading = !action.meta.arg.id;
			})
			.addCase(insertGroup.rejected, (state, { payload, meta }) => {
				state.groups = state.groups.map((group) =>
					group.id === meta.arg.id
						? {
								...group,
								loading: false,
							}
						: group
				);
				state.error = payload;
				state.loading = false;
				state.message = {
					header: 'Falha ao criar chave',
					content: payload?.message,
					error: true,
				};
			});
		builder
			.addCase(insertTeam.fulfilled, (state, { payload }) => {
				state.changed = state.changed.filter(
					(changed) => changed !== payload.id
				);
				state.groups = setTeams(
					state.groups.map((group) =>
						group.id === payload.id ? payload : group
					)
				);
				state.error = undefined;
			})
			.addCase(insertTeam.pending, (state, action) => {
				state.groups = state.groups.map((group) =>
					group.id === action.meta.arg.group.id
						? { ...group, loading: true }
						: group
				);
			})
			.addCase(insertTeam.rejected, (state, { payload, meta }) => {
				state.groups = state.groups.map((group) =>
					group.id === meta.arg.group.id ? { ...group, loading: false } : group
				);
				state.error = payload;
			});
		builder
			.addCase(defineRounds.fulfilled, (state, { payload }) => {
				state.groups = setTeams(
					state.groups.map((group) =>
						group.id === payload.id ? payload : group
					)
				);
				state.error = undefined;
			})
			.addCase(defineRounds.pending, (state, action) => {
				state.groups = state.groups.map((group) =>
					group.id === action.meta.arg.group.id
						? {
								...group,
								loading: true,
							}
						: group
				);
			})
			.addCase(defineRounds.rejected, (state, { payload, meta }) => {
				state.groups = state.groups.map((group) =>
					group.id === meta.arg.group.id
						? {
								...group,
								loading: false,
							}
						: group
				);
				state.error = payload;
			});
		builder
			.addCase(removeTeam.fulfilled, (state, { payload }) => {
				state.changed = state.changed.filter(
					(changed) => changed !== payload.id
				);
				state.groups = setTeams(
					state.groups.map((group) =>
						group.id === payload.id ? payload : group
					)
				);
				state.error = undefined;
			})
			.addCase(removeTeam.pending, (state, action) => {
				state.groups = state.groups.map((group) =>
					group.id === action.meta.arg.group.id
						? { ...group, loading: true }
						: group
				);
			})
			.addCase(removeTeam.rejected, (state, { payload, meta }) => {
				state.groups = state.groups.map((group) =>
					group.id === meta.arg.group.id ? { ...group, loading: false } : group
				);
				state.error = payload;
			});
		builder
			.addCase(closeGroup.fulfilled, (state, { payload, meta }) => {
				state.groups = setTeams(
					state.groups.map(
						(group) => payload.find((g) => g.id === group.id) ?? group
					)
				);
				state.stats = undefined;
				state.message = {
					header: 'Fechado',
					content: 'Grupo encerrado com sucesso',
					success: true,
					group: meta.arg.group.id,
				};
			})
			.addCase(closeGroup.pending, (state) => {
				if (state.stats?.group) {
					state.stats.group = { ...state.stats?.group, loading: true };
				}
			})
			.addCase(closeGroup.rejected, (state, { payload, meta }) => {
				state.stats = undefined;
				state.error = payload;
				state.message = {
					header: 'Falha ao fechar grupo',
					content: payload?.message,
					error: true,
					group: meta.arg.group.id,
				};
			});
		builder
			.addCase(removeGroup.fulfilled, (state, { meta }) => {
				state.groups = setTeams(
					state.groups.filter((group) => group.id !== meta.arg.id)
				);
				state.error = undefined;
			})
			.addCase(removeGroup.pending, (state, { meta }) => {
				state.groups = state.groups.map((group) =>
					group.id === meta.arg.id
						? {
								...group,
								loading: true,
							}
						: group
				);
			})
			.addCase(removeGroup.rejected, (state, { payload, meta }) => {
				state.groups = state.groups.map((group) =>
					group.id === meta.arg.id
						? {
								...group,
								loading: false,
							}
						: group
				);
				state.error = payload;
				state.loading = false;
			});
	},
});

const sortGroups = (groups: Group[]): Group[] =>
	groups?.sort((a, b) => (a.title > b.title ? 1 : -1));

const setTeams = (groups: Group[]): Group[] =>
	groups.map((group) => ({
		...group,
		teams: group.teams?.map((team) => ({
			...team,
			...(team?.type === 'qualy'
				? { qualy: groups.find((group) => group.id === team.group)?.title }
				: {}),
		})),
		loosers: group.loosers?.map((looser) => ({
			...looser,
			...(looser?.type === 'qualy'
				? { qualy: groups.find((group) => group.id === looser.group)?.title }
				: {}),
		})),
	}));

export const updateTeamsTitle = (
	groups: (Group & Load)[],
	enrollments: Enrollment[]
) =>
	groups.map((group) => ({
		...group,
		teams: group.teams?.map((team) => {
			const enroll = enrollments.find(
				(enroll) => team?.id && enroll.id === team?.id
			);

			return team
				? {
						...team,
						...(enroll ? { title: enroll.title } : {}),
					}
				: team;
		}),
	}));

export const orderTeamPosition = (
	group: Group,
	matches: Match[],
	hasThird: boolean
) => {
	let teamPos: (Team & Position)[] = [];

	knockoutRounds(group, matches, hasThird)
		.reverse()
		.forEach((round, roundIndex) => {
			round.forEach((game, gameIndex) => {
				game.teams.forEach((team) => {
					const position =
						roundIndex === 0
							? Math.pow(2, roundIndex) +
								gameIndex * 2 +
								(team.id === game?.match?.winner ? 0 : 1)
							: Math.pow(2, roundIndex) + 1;

					if (
						team?.id &&
						(game.match?.winner || game.match?.walkover) &&
						(!hasThird || roundIndex !== 1)
					) {
						const isBetter =
							!teamPos.some((prev) => prev.id === team.id) ||
							position <
								(teamPos.find((prev) => prev.id === team.id)?.position ?? 0);

						if (isBetter) {
							teamPos = teamPos
								.filter((prev) => prev.id !== team.id)
								.concat({
									...team,
									position,
								});
						}
					}
				});
			});
		});

	return teamPos.sort(
		(first, second) => (first.position ?? 0) - (second.position ?? 0)
	);
};

export const orderByTeamStats = (
	group: Group,
	matches: Match[],
	criteria?: Criteria[],
	sport?: Sport
) => {
	const teamStats: (Team & Stats)[] =
		group.teams?.map((team) => ({
			...team,
			...(criteria
				? ({
						stats: calcTeamStats({
							sport,
							criteria,
							team,
							matches,
						}),
					} as Stats)
				: {}),
		})) ?? [];

	return teamStats.sort((first, second) =>
		(criteria ?? []).reduce(
			(sort, criteria) =>
				criteria.active && sort === 0
					? criteria.sign === 'HH'
						? teamHeadToHead([first, second], matches)
						: (second?.stats?.[criteria.sign] ?? 0) -
							(first?.stats?.[criteria.sign] ?? 0)
					: sort,
			0
		)
	);
};

const teamHeadToHead = ([first, second]: [Team, Team], matches: Match[]) => {
	const match = matches?.find(
		(match) =>
			match.teams.map((team) => team.id).includes(first.id) &&
			match.teams.map((team) => team.id).includes(second.id)
	);
	switch (match?.winner) {
		case first.id:
			return -1;
		case second.id:
			return 1;
		default:
			return 0;
	}
};

export const calcTeamStats = ({
	sport,
	criteria,
	team,
	matches,
}: {
	sport?: Sport;
	criteria?: Criteria[];
	team: Team;
	matches: Match[];
}) => {
	return (criteria ?? []).reduce(
		(stats, criteria, i, criterias) => ({
			...stats,
			...(sport
				? {
						[criteria.sign]: statCalcFunc[sport]?.[criteria.sign]?.(
							team,
							matches,
							sport,
							criterias
						),
					}
				: {}),
		}),
		{}
	);
};

const statCalcFunc: {
	[key in Sport]?: {
		[key in Criteria['sign']]?: (
			team: Team,
			matches: Match[],
			sport: Sport,
			criterias: Criteria[]
		) => number;
	};
} = {
	soccer: {
		P: (team, matches, sport, criterias) => {
			const criteria = criterias.find((criteria) => criteria.sign === 'P');

			const wins =
				statCalcFunc[sport]?.['W']?.(team, matches, sport, criterias) ?? 0;
			const winPoints = criteria?.win ?? 0;

			const tie =
				statCalcFunc[sport]?.['T']?.(team, matches, sport, criterias) ?? 0;
			const tiePoints = criteria?.tie ?? 0;

			const loss =
				statCalcFunc[sport]?.['L']?.(team, matches, sport, criterias) ?? 0;
			const lossPoints = criteria?.loss ?? 0;

			return wins * winPoints + tie * tiePoints + loss * lossPoints;
		},
		W: (team, matches) =>
			matches.filter((match) => match.active && match.winner === team.id)
				.length,
		T: (team, matches) =>
			matches.filter(
				(match) =>
					match.active &&
					match.teams.some((player) => player.id === team.id) &&
					match.result?.length &&
					!match.winner &&
					!match.walkover
			).length,
		L: (team, matches) =>
			matches.filter(
				(match) =>
					match.teams.map((player) => player.id).includes(team.id) &&
					(((match.result?.length ?? 0) > 0 && !!match.winner) ||
						match.walkover) &&
					match.winner !== team.id
			).length,
		RG: (team, matches) =>
			matches.filter(
				(match) =>
					match.teams.map((player) => player.id).includes(team.id) &&
					(match.result?.length ?? 0) > 0
			).length,
		GW: (team, matches, sport, criterias) =>
			matches.reduce(
				(games, match) =>
					match.teams.some((player) => player.id === team.id)
						? games +
							(match.walkover && (match.result?.length ?? 0) === 0
								? match.winner === team.id
									? +(
											criterias.find((criteria) => criteria.sign === 'GW')
												?.wo ?? 0
										)
									: 0
								: (match.result?.reduce(
										(games, set) =>
											games +
											match.teams.reduce(
												(games, player, index) =>
													player.id === team.id
														? games + set[index].games
														: games,
												0
											),
										0
									) ?? 0))
						: games,
				0
			),
		GL: (team, matches, sport, criterias) =>
			matches.reduce(
				(games, match) =>
					match.teams.some((player) => player.id === team.id)
						? games +
							(match.walkover && (match.result?.length ?? 0) === 0
								? match.winner !== team.id
									? +(
											criterias.find((criteria) => criteria.sign === 'GL')
												?.wo ?? 0
										)
									: 0
								: (match.result?.reduce(
										(games, set) =>
											games +
											match.teams.reduce(
												(games, player, index) =>
													player.id !== team.id
														? games + set[index].games
														: games,
												0
											),
										0
									) ?? 0))
						: games,
				0
			),
		SG: (team, matches, sport, criterias) =>
			(statCalcFunc[sport]?.['GW']?.(team, matches, sport, criterias) ?? 0) -
			(statCalcFunc[sport]?.['GL']?.(team, matches, sport, criterias) ?? 0),
	},
	tennis: {
		RG: (team, matches) =>
			matches.filter(
				(match) =>
					match.teams.map((player) => player.id).includes(team.id) &&
					(match.result?.length ?? 0) > 0
			).length,
		W: (team, matches) =>
			matches.filter((match) => match.active && match.winner === team.id)
				.length,
		L: (team, matches) =>
			matches.filter(
				(match) =>
					match.teams.map((player) => player.id).includes(team.id) &&
					(((match.result?.length ?? 0) > 0 && !!match.winner) ||
						match.walkover) &&
					match.winner !== team.id
			).length,
		S: (team, matches, sport, criterias) =>
			(statCalcFunc[sport]?.['W']?.(team, matches, sport, criterias) ?? 0) -
			(statCalcFunc[sport]?.['L']?.(team, matches, sport, criterias) ?? 0),
		SW: (team, matches, sport, criterias) =>
			matches.reduce(
				(total, match) =>
					match.teams.some((player) => player.id === team.id)
						? total +
							(match.walkover && (match.result?.length ?? 0) === 0
								? match.winner === team.id
									? +(
											criterias.find((criteria) => criteria.sign === 'SW')
												?.wo ?? 0
										)
									: 0
								: (match.result?.filter((set) =>
										match.teams.reduce(
											(win, player, index) =>
												win ||
												(player.id === team.id && (set[index].winner ?? false)),
											false
										)
									)?.length ?? 0))
						: total,
				0
			),
		SL: (team, matches, sport, criterias) =>
			matches.reduce(
				(total, match) =>
					match.teams.some((player) => player.id === team.id)
						? total +
							(match.walkover && (match.result?.length ?? 0) === 0
								? match.winner !== team.id
									? +(
											criterias.find((criteria) => criteria.sign === 'SL')
												?.wo ?? 0
										)
									: 0
								: (match.result?.filter((set) =>
										match.teams.reduce(
											(lost, player, index) =>
												lost ||
												(player.id === team.id &&
													(set[index === 0 ? 1 : 0].winner ?? false)),
											false
										)
									).length ?? 0))
						: total,
				0
			),
		SS: (team, matches, sport, criterias) =>
			(statCalcFunc[sport]?.['SW']?.(team, matches, sport, criterias) ?? 0) -
			(statCalcFunc[sport]?.['SL']?.(team, matches, sport, criterias) ?? 0),
		GW: (team, matches, sport, criterias) =>
			matches.reduce(
				(games, match) =>
					match.teams.some((player) => player.id === team.id)
						? games +
							(match.walkover && (match.result?.length ?? 0) === 0
								? match.winner === team.id
									? +(
											criterias.find((criteria) => criteria.sign === 'GW')
												?.wo ?? 0
										)
									: 0
								: (match.result?.reduce(
										(games, set) =>
											games +
											match.teams.reduce(
												(games, player, index) =>
													player.id === team.id
														? games + set[index].games
														: games,
												0
											),
										0
									) ?? 0))
						: games,
				0
			),
		GL: (team, matches, sport, criterias) =>
			matches.reduce(
				(games, match) =>
					match.teams.some((player) => player.id === team.id)
						? games +
							(match.walkover && (match.result?.length ?? 0) === 0
								? match.winner !== team.id
									? +(
											criterias.find((criteria) => criteria.sign === 'GL')
												?.wo ?? 0
										)
									: 0
								: (match.result?.reduce(
										(games, set) =>
											games +
											match.teams.reduce(
												(games, player, index) =>
													player.id !== team.id
														? games + set[index].games
														: games,
												0
											),
										0
									) ?? 0))
						: games,
				0
			),
		SG: (team, matches, sport, criterias) =>
			(statCalcFunc[sport]?.['GW']?.(team, matches, sport, criterias) ?? 0) -
			(statCalcFunc[sport]?.['GL']?.(team, matches, sport, criterias) ?? 0),
	},
};

export const matchGroupNumber = (firstIndex: number, secondIndex: number) => {
	if (firstIndex < secondIndex) {
		return secondIndex < 2 ? 1 : combination(secondIndex, 2) + firstIndex + 1;
	} else {
		return firstIndex < 2 ? 1 : combination(firstIndex, 2) + secondIndex + 1;
	}
};

export const matchKnockoutNumber = (
	roundIndex: number,
	confrontIndex: number,
	groupSize: number
) =>
	((Math.pow(2, roundIndex) - 1) / Math.pow(2, roundIndex)) * groupSize +
	confrontIndex +
	1;

export const findMatch = (
	teams: [Team | undefined, Team | undefined],
	matches: Match[],
	group: Group
): Match | undefined =>
	matches.find(
		(match) =>
			match.active !== false &&
			match.tourn === group.tourn &&
			match.group === group.id &&
			teams.every((gameTeam) =>
				match.teams.some((matchTeam) => {
					switch (gameTeam?.type) {
						case 'seed':
							return gameTeam.id === matchTeam.id;
						case 'qualy':
							return (
								gameTeam.group === matchTeam.group &&
								gameTeam.pos === matchTeam.pos
							);
						case 'winner':
							return (
								(matchTeam.type === 'winner' &&
									gameTeam.game &&
									matchTeam.game &&
									gameTeam.game === matchTeam.game) ||
								(matchTeam.type === 'seed' &&
									gameTeam.id &&
									matchTeam.id &&
									gameTeam.id === matchTeam.id)
							);
						case 'looser':
							return (
								matchTeam.type === 'looser' &&
								((gameTeam.game &&
									matchTeam.game &&
									gameTeam.game === matchTeam.game) ||
									(gameTeam.id && matchTeam.id && gameTeam.id === matchTeam.id))
							);
						default:
							return false;
					}
				})
			)
	);

export const knockoutRounds = (
	group: Group,
	matches: Match[],
	thirdPlace?: boolean
): Confront[][] => {
	if (group.draw !== 'knockout' || !group.teams || group.teams?.length === 0)
		return [];

	const rounds: Confront[][] = new Array(Math.log2(group.teams?.length ?? 0))
		.fill(undefined)
		.map((round, roundIndex) =>
			new Array((group.teams?.length ?? 0) / Math.pow(2, roundIndex + 1)).fill(
				undefined
			)
		);

	const findWinner = (roundIndex: number, confrontIndex: number): Team => {
		const confront = rounds[roundIndex][confrontIndex];

		if (!confront.teams) return { type: 'seed' };

		if (confront.teams.every((team) => team?.type === 'bye'))
			return { type: 'bye' };

		if (confront.teams.some((team) => team?.type === 'bye'))
			return {
				...confront.teams.find((team) => team?.type !== 'bye'),
				type: 'winner',
				game: matchKnockoutNumber(
					roundIndex,
					confrontIndex,
					group.teams?.length ?? 0
				),
			};

		if (confront.teams.some((team) => team.type === 'wo'))
			return {
				...confront.teams.find((team) => team?.type !== 'wo'),
				type: 'winner',
				game: matchKnockoutNumber(
					roundIndex,
					confrontIndex,
					group.teams?.length ?? 0
				),
			};

		if (confront.match?.walkover && !confront.match.winner)
			return {
				title: 'Duplo W.O.',
				type: 'wo',
				game: matchKnockoutNumber(
					roundIndex,
					confrontIndex,
					group.teams?.length ?? 0
				),
			};

		const winner = confront?.match?.winner
			? confront?.teams?.find((team) => team.id === confront?.match?.winner)
			: undefined;

		return {
			...(winner ?? {}),
			type: 'winner',
			game: matchKnockoutNumber(
				roundIndex,
				confrontIndex,
				group.teams?.length ?? 0
			),
		};
	};

	const findTeams = (
		roundIndex: number,
		confrontIndex: number
	): [Team & Load, Team & Load] => {
		if (!group.teams) return [{ type: 'seed' }, { type: 'seed' }];

		return roundIndex === 0
			? [group.teams[2 * confrontIndex], group.teams[1 + 2 * confrontIndex]]
			: [
					findWinner(roundIndex - 1, confrontIndex * 2),
					findWinner(roundIndex - 1, confrontIndex * 2 + 1),
				];
	};

	const findLooser = (roundIndex: number, confrontIndex: number): Team => {
		const confront = rounds[roundIndex][confrontIndex];

		if (!confront.teams) return { type: 'looser' };

		if (confront.teams.some((team) => team.type === 'wo'))
			return {
				...rounds[roundIndex][confrontIndex].teams.find(
					(team) => team?.type === 'wo'
				),
				type: 'looser',
				game: matchKnockoutNumber(
					roundIndex,
					confrontIndex,
					group.teams?.length ?? 0
				),
			};

		if (confront.match?.walkover && !confront.match.winner)
			return {
				title: 'Duplo W.O.',
				type: 'looser',
				game: matchKnockoutNumber(
					roundIndex,
					confrontIndex,
					group.teams?.length ?? 0
				),
			};

		const looser = confront?.match?.winner
			? confront?.teams?.find(
					(team) => team.id !== rounds[roundIndex][confrontIndex]?.match?.winner
				)
			: undefined;

		return {
			...(looser ?? {}),
			type: 'looser',
			game: matchKnockoutNumber(
				roundIndex,
				confrontIndex,
				group.teams?.length ?? 0
			),
		};
	};

	const findThirdTeams = (
		roundIndex: number,
		confrontIndex: number
	): [Team & Load, Team & Load] => {
		return roundIndex === 0
			? [
					{ type: 'qualy', ...group.loosers?.[0] },
					{ type: 'qualy', ...group.loosers?.[1] },
				]
			: [
					findLooser(roundIndex - 1, confrontIndex * 2),
					findLooser(roundIndex - 1, confrontIndex * 2 + 1),
				];
	};

	rounds.forEach((round, roundIndex) => {
		round.forEach((confront, confrontIndex) => {
			const teams = findTeams(roundIndex, confrontIndex);
			const match = findMatch(teams, matches, group);
			const loading = teams.some((team) => team?.loading);

			rounds[roundIndex][confrontIndex] = {
				teams,
				match,
				loading,
			};
		});

		if (thirdPlace && roundIndex === rounds.length - 1) {
			const teams = findThirdTeams(roundIndex, 0);
			const match = findMatch(teams, matches, group);
			const loading = teams.some((team) => team?.loading);

			rounds[roundIndex].push({
				teams,
				match,
				loading,
				loosers: true,
			});
		}
	});

	return rounds;
};

export const getGroups = createAsyncThunk<
	Group[],
	{ tourn: string },
	{ rejectValue: SerializedError }
>('groups/getGroups', async ({ tourn }, { rejectWithValue, dispatch }) => {
	try {
		await dispatch(authRefresh());

		const { data } = await api.groups.get(tourn);

		return data;
	} catch (error) {
		return rejectWithValue(
			(error as AxiosError).response?.data as SerializedError
		);
	}
});

export const insertGroup = createAsyncThunk<
	Group,
	Partial<Group>,
	{ rejectValue: SerializedError }
>('groups/insertGroup', async (group, { rejectWithValue, dispatch }) => {
	try {
		await dispatch(authRefresh());

		const { data } = await api.groups.post(group);

		return data;
	} catch (error) {
		return rejectWithValue(
			(error as AxiosError).response?.data as SerializedError
		);
	}
});

export const insertTeam = createAsyncThunk<
	Group,
	{
		group: Partial<Group>;
		teams: { [key: number]: Team | null };
		looser: boolean;
	},
	{ rejectValue: SerializedError }
>(
	'groups/insertTeam',
	async ({ group, teams, looser }, { rejectWithValue, dispatch }) => {
		try {
			await dispatch(authRefresh());

			const { data } = await api.groups.teams.post(group, teams, looser);

			return data;
		} catch (error) {
			return rejectWithValue(
				(error as AxiosError).response?.data as SerializedError
			);
		}
	}
);

export const defineRounds = createAsyncThunk<
	Group,
	{
		group: Partial<Group>;
		rounds: number;
	},
	{ rejectValue: SerializedError }
>(
	'groups/defineRounds',
	async ({ group, rounds }, { rejectWithValue, dispatch }) => {
		try {
			await dispatch(authRefresh());

			const { data } = await api.groups.rounds.define(group, rounds);

			return data;
		} catch (error) {
			return rejectWithValue(
				(error as AxiosError).response?.data as SerializedError
			);
		}
	}
);

export const removeTeam = createAsyncThunk<
	Group,
	{
		group: Partial<Group>;
		position: number;
		loosers: boolean;
	},
	{ rejectValue: SerializedError }
>(
	'groupps/removeTeam',
	async ({ group, position, loosers }, { rejectWithValue, dispatch }) => {
		try {
			await dispatch(authRefresh());

			const { data } = await api.groups.teams.delete(group, position, loosers);

			return data;
		} catch (error) {
			return rejectWithValue(
				(error as AxiosError).response?.data as SerializedError
			);
		}
	}
);

export const closeGroup = createAsyncThunk<
	Group[],
	{
		group: Partial<Group>;
		teams: { [key: number]: Team | null };
	},
	{ rejectValue: SerializedError }
>(
	'groups/closeGroup',
	async ({ group, teams }, { rejectWithValue, dispatch }) => {
		try {
			await dispatch(authRefresh());

			const { data } = await api.groups.close(group, teams);

			return data;
		} catch (error) {
			return rejectWithValue(
				(error as AxiosError).response?.data as SerializedError
			);
		} finally {
			setTimeout(() => {
				dispatch(resetMessage());
			}, 10000);
		}
	}
);

export const removeGroup = createAsyncThunk<
	void,
	Partial<Group>,
	{ rejectValue: SerializedError }
>('groups/removeGroup', async (group, { rejectWithValue, dispatch }) => {
	try {
		await dispatch(authRefresh());

		await api.groups.delete(group);
	} catch (error) {
		return rejectWithValue(
			(error as AxiosError).response?.data as SerializedError
		);
	}
});

export const {
	changeGroup,
	updateTeams,
	dragTeam,
	dragClear,
	calcGroupStats,
	resetMessage,
	resetStats,
	resetGroups,
} = groupSlice.actions;

export default groupSlice.reducer;
