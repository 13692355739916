import {
	createAsyncThunk,
	createSlice,
	PayloadAction,
	SerializedError,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { MessageProps } from 'semantic-ui-react';

import { api } from '../../api';
import { defaultCurrency } from '../../components/icons/currency';
import checkValidity from '../../util/valida/check';
import { Profile } from '../access';
import { authRefresh } from '../auth';
import { League } from '../leagues';
import { Entity, Plan } from '../plans';
import { Destination, Formation, Sport, Surface } from '../sports';

export type EditItem<EditType> = {
	value: EditType;
	changed: boolean;
	error?: boolean;
	required?: boolean;
};

export type Hour = {
	hour: number;
	// hour12: number;
	minute: number;
	// formatted12: string;
	formatted24: string;
	// meridiem: string;
	// formattedSimple: string;
};

export type Hours = {
	closure: (Hour | null)[];
	opening: (Hour | null)[];
};

export type Court = {
	id: string;
	unit: string;
	number: string;
	name: string;
	sports?: Sport[];
	indoor?: boolean;
	surface?: Surface['surface'];
	hours?: Hours;
	images?: string[];
	created?: string;
	active?: boolean;
};

export type EditCourt = {
	id?: string;
	unit: string;
	number: EditItem<Court['number']>;
	name: EditItem<Court['name']>;
	sports: EditItem<Court['sports']>;
	indoor: EditItem<Court['indoor']>;
	surface: EditItem<Court['surface']>;
	images: EditItem<Court['images']>;
	hours: EditItem<Hours>;
	active: boolean;
};

export type EditFormation = {
	dest: EditItem<Formation['dest']>;
	code: EditItem<Formation['code']>;
	title: EditItem<Formation['title']>;
	size: EditItem<Formation['size']>;
	spare: EditItem<Formation['spare']>;
	coach: EditItem<Formation['coach']>;
	staff: EditItem<Formation['staff']>;
	each: EditItem<Formation['each']>;
	payors: EditItem<Formation['payors']>;
	price: EditItem<string | undefined>;
	currency: EditItem<Formation['currency']>;
	active: EditItem<Formation['active']>;
	new: EditItem<boolean>;
};

export type EditDestination = {
	destination: EditItem<Destination['destination']>;
	title: EditItem<Destination['title']>;
	color: EditItem<Destination['color']>;
	allocable: EditItem<Destination['allocable']>;
	active: EditItem<Destination['active']>;
	new: EditItem<boolean>;
};

export type Unit = {
	id: string;
	org: string;
	cnpj?: string;
	name: string;
	nick?: string;
	logo?: string;
	horizontal?: string;
	slot?: number;
	sports?: Sport[];
	formations?: Formation[];
	destinations?: Destination[];
	created?: string;
	active?: boolean;
	access?: Profile[];
};

export type EditUnit = {
	id?: string;
	org: string;
	cnpj: EditItem<Unit['cnpj']>;
	name: EditItem<Unit['name']>;
	nick: EditItem<Unit['nick']>;
	logo: EditItem<Unit['logo']>;
	horizontal: EditItem<Unit['horizontal']>;
	slot: EditItem<Unit['slot']>;
	sports: EditItem<Unit['sports']>;
	formations?: EditFormation[];
	destinations?: EditDestination[];
	active: boolean;
};

export type Document = 'id' | 'cnpj' | 'document';

export type Documents = {
	[key in Document]?: string;
};

export type Responsible = 'owner' | 'manager' | 'billing';

export type RespProps = {
	name: string;
	mail: string;
	cpf: string;
	phone?: string;
};

export type Responsibles = {
	[key in Responsible]?: RespProps;
};

export type Organization = {
	id: string;
	cnpj: string;
	category?: Entity;
	logo?: string;
	horizontal?: string;
	name: string;
	nick?: string;
	link?: string;
	courts?: Court[];
	units?: Unit[];
	leagues?: League[];
	documents?: Documents;
	responsibles?: Responsibles;
	plan?: Plan;
	created?: string;
	active?: boolean;
};

export type EditOrg = {
	id?: string;
	cnpj: EditItem<Organization['cnpj']>;
	name: EditItem<Organization['name']>;
	category: EditItem<Organization['category']>;
	logo?: EditItem<Organization['logo']>;
	horizontal?: EditItem<Organization['horizontal']>;
	nick?: EditItem<Organization['nick']>;
	link?: EditItem<Organization['link']>;
	documents?: {
		[key in Document]?: EditItem<Documents[key]>;
	};
	responsibles?: {
		[key in Responsible]?: EditItem<Responsibles[key]>;
	};
	plan?: EditItem<Organization['plan']>;
	active: boolean;
};

interface OrganizationState {
	organizations: Organization[];
	organization?: EditOrg;
	units: Unit[];
	unit?: EditUnit;
	courts: Court[];
	court?: EditCourt;
	loading: boolean;
	send: boolean;
	error?: SerializedError;
	message?: MessageProps;
}

const initialState: OrganizationState = {
	organizations: [],
	units: [],
	courts: [],
	loading: false,
	send: false,
};

export const organizationSlice = createSlice({
	name: 'organizations',
	initialState,
	reducers: {
		editOrganization: (state, { payload }: PayloadAction<Organization>) => {
			if (payload) {
				state.organization = formatEditOrg(payload);
			}
		},
		newOrganization: (state) => {
			state.organization = formatEditOrg({ name: '', cnpj: '' });
		},
		changeOrganization: (
			state,
			{
				payload: { field, value },
			}: PayloadAction<{
				field: keyof EditOrg;
				value: string;
			}>
		) => {
			if (state.organization) {
				state.organization = {
					...state.organization,
					[field]: {
						...(state.organization[field] as EditItem<unknown>),
						value,
						changed:
							(state.organization[field] as EditItem<unknown>).value !== value,
						error:
							checkValidity(field, value) ||
							((state.organization[field] as EditItem<unknown>).required &&
								!value),
					},
				};
			}
		},
		changeOrgResponsible: (
			state,
			{ payload }: PayloadAction<Organization['responsibles']>
		) => {
			if (state.organization) {
				state.organization = {
					...state.organization,
					responsibles: Object.keys(payload ?? {}).reduce(
						(responsibles, res) => ({
							...responsibles,
							[res]: {
								value: payload?.[res as Responsible],
								changed: true,
								error: Object.keys(payload?.[res as Responsible] ?? {}).some(
									(key) =>
										!payload?.[res as Responsible]?.[key as keyof RespProps] ||
										checkValidity(
											key,
											payload?.[res as Responsible]?.[key as keyof RespProps]
										)
								),
							},
						}),
						{}
					),
				};
			}
		},
		changeOrgDocument: (
			state,
			{ payload }: PayloadAction<{ field: Document; value: string }>
		) => {
			if (state.organization) {
				state.organization = {
					...state.organization,
					documents: {
						...state.organization.documents,
						[payload.field]: {
							value: payload.value,
							changed: true,
							error: !payload.value,
						},
					},
				};
			}
		},
		changeOrgPlan: (state, { payload }: PayloadAction<Plan>) => {
			if (state.organization) {
				state.organization = {
					...state.organization,
					plan: {
						value: payload,
						changed:
							payload.group !== state.organization.plan?.value?.group ||
							payload.plan !== state.organization.plan?.value?.plan,
						error: !payload,
					},
				};
			}
		},
		editUnit: (state, { payload }: PayloadAction<Unit>) => {
			if (payload) {
				state.unit = formatEditUnit(payload);
			}
		},
		newUnit: (state) => {
			if (state.organization?.id) {
				state.unit = formatEditUnit({
					org: state.organization.id,
					name: '',
				});
			}
		},
		changeUnit: (
			state,
			{
				payload: { field, value },
			}: PayloadAction<{
				field: keyof EditUnit;
				value: number | string | string[];
			}>
		) => {
			if (state.unit) {
				state.unit = {
					...state.unit,
					[field]: {
						...(state.unit[field] as EditItem<unknown>),
						value,
						changed: (state.unit[field] as EditItem<unknown>).value !== value,
						error: (state.unit[field] as EditItem<unknown>).required
							? !value || checkValidity(field, value)
							: !!value && checkValidity(field, value),
					},
				};
			}
		},
		addUnitFormation: (
			state,
			{ payload }: PayloadAction<Formation['dest']>
		) => {
			const hasErrors = (state.unit?.formations ?? []).some(
				(f) => f.active.value && Object.values(f).some((v) => v.error)
			);

			if (state.unit && !hasErrors) {
				state.unit = {
					...state.unit,
					formations: (state.unit.formations ?? []).concat(
						formatEditFormation({
							dest: payload,
							size: 0,
							active: true,
							new: true,
						})
					),
				};
			}
		},
		changeUnitFormation: (
			state,
			{
				payload: { field, value, code },
			}: PayloadAction<{
				code: string;
				field: keyof EditFormation;
				value?: string | number | boolean | Profile[];
			}>
		) => {
			const existingCode = (state.unit?.formations ?? []).some(
				(formation) => field === 'code' && formation.code.value === value
			);

			if (state.unit) {
				state.unit = {
					...state.unit,
					formations: state.unit.formations?.map((formation) =>
						formation.code.value === code
							? {
									...formation,
									[field]: {
										...formation[field],
										...(existingCode
											? {
													error: true,
												}
											: {
													value: value,
													changed:
														formation[field].value !==
														(typeof formation[field].value === 'number' && value
															? +value
															: value),
													error:
														(formation[field].required &&
															(value === null || value === undefined)) ||
														checkValidity(field, value),
												}),
									},
									...(field === 'price'
										? {
												currency: {
													...formation['currency'],
													value: formation['currency'].value ?? defaultCurrency,
												},
											}
										: {}),
								}
							: formation
					),
				};
			}
		},
		removeUnitFormation: (
			state,
			{ payload }: PayloadAction<Formation['code']>
		) => {
			if (state.unit) {
				state.unit = {
					...state.unit,
					formations: state.unit.formations?.filter(
						(formation) => formation.code.value !== payload
					),
				};
			}
		},
		addUnitDestination: (
			state,
			{ payload }: PayloadAction<Destination | undefined>
		) => {
			const hasErrors = (state.unit?.destinations ?? []).some(
				(d) => d.active.value && Object.values(d).some((v) => v.error)
			);

			const existingDest = (state.unit?.destinations ?? []).some(
				(d) => d.destination.value === payload?.destination
			);

			if (state.unit && !hasErrors && !existingDest) {
				state.unit = {
					...state.unit,
					destinations: (state.unit.destinations ?? []).concat(
						formatEditDestination({
							new: !payload,
							destination: '',
							color: 'grey',
							...payload,
						})
					),
				};
			}
		},
		changeUnitDestination: (
			state,
			{
				payload: { field, value, code },
			}: PayloadAction<{
				code?: string;
				field: keyof EditDestination;
				value?: string | boolean;
			}>
		) => {
			const existingCode = (state.unit?.destinations ?? []).some(
				(destination) =>
					field === 'destination' && destination.destination.value === value
			);

			if (state.unit) {
				state.unit = {
					...state.unit,
					destinations: state.unit.destinations?.map((destination) =>
						destination.destination.value === code
							? {
									...destination,
									[field]: {
										...destination[field],
										...(existingCode
											? {
													error: true,
												}
											: {
													value: value,
													changed:
														destination[field].value !==
														(typeof destination[field].value === 'number' &&
														value
															? +value
															: value),
													error:
														(destination[field].required &&
															(value === null || value === undefined)) ||
														checkValidity(field, value),
												}),
									},
								}
							: destination
					),
				};
			}
		},
		removeUnitDestination: (
			state,
			{ payload }: PayloadAction<Destination['destination']>
		) => {
			if (state.unit) {
				state.unit = {
					...state.unit,
					destinations: state.unit.destinations?.filter(
						(destination) => destination.destination.value !== payload
					),
				};
			}
		},
		editCourt: (state, { payload }: PayloadAction<Court>) => {
			if (payload) {
				state.court = formatEditCourt(payload);
			}
		},
		newCourt: (state) => {
			if (state.organization?.id && state.unit?.id) {
				state.court = formatEditCourt({
					unit: state.unit.id,
					name: '',
					number: '',
				});
			}
		},
		changeCourt: (
			state,
			{
				payload: { field, value },
			}: PayloadAction<{
				field: keyof EditCourt;
				value: boolean | string | string[];
			}>
		) => {
			if (state.court) {
				state.court = {
					...state.court,
					[field]: {
						...(state.court[field] as EditItem<unknown>),
						value,
						changed: (state.court[field] as EditItem<unknown>).value !== value,
						error: (state.court[field] as EditItem<unknown>).required
							? value === undefined ||
								value === '' ||
								checkValidity(field, value)
							: value !== undefined &&
								value !== '' &&
								checkValidity(field, value),
					},
				};
			}
		},
		changeCourtHours: (
			state,
			{
				payload: { period, day, time },
			}: PayloadAction<{
				period: keyof Hours;
				day: number;
				time: Hour | null;
			}>
		) => {
			if (state.court) {
				state.court = {
					...state.court,
					hours: {
						value: {
							...state.court.hours.value,
							[period]: state.court.hours.value[period].map((hour, index) =>
								index === day ? time : hour
							),
						},
						changed: true,
						error:
							!!time &&
							!state.court.hours.value?.[
								period === 'opening' ? 'closure' : 'opening'
							]?.[day],
					},
				};
			}
		},
		setSend: (state) => {
			state.send = true;
		},
		resetMessage: (state) => {
			state.message = undefined;
		},
		resetOrganization: (state) => {
			state.organization = undefined;
			state.send = false;
		},
		resetOrganizations: (state) => {
			state.organizations = [];
			state.loading = false;
			state.error = undefined;
			state.message = undefined;
		},
		resetUnit: (state) => {
			state.unit = undefined;
			state.send = false;
		},
		resetUnits: (state) => {
			state.units = [];
			state.loading = false;
			state.error = undefined;
			state.message = undefined;
		},
		resetCourt: (state) => {
			state.court = undefined;
			state.send = false;
		},
		resetCourts: (state) => {
			state.courts = [];
			state.loading = false;
			state.error = undefined;
			state.message = undefined;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getOrganizations.fulfilled, (state, { payload }) => {
				state.organizations = payload ?? [];
				state.loading = false;
				state.error = undefined;
			})
			.addCase(getOrganizations.pending, (state) => {
				state.loading = true;
			})
			.addCase(getOrganizations.rejected, (state, { payload }) => {
				state.error = payload;
				state.loading = false;
				state.message = {
					header: 'Falha ao buscar organizações',
					content: payload?.message,
					error: true,
				};
			});
		builder
			.addCase(insertOrganization.fulfilled, (state, { payload }) => {
				state.organizations = state.organizations
					.filter((org) => org.id !== payload.id)
					.concat(payload);
				state.organization = formatEditOrg(payload);
				state.loading = false;
				state.error = undefined;
				state.message = {
					header: 'Salvo',
					content: 'Sua organização foi salva com sucesso.',
					success: true,
				};
			})
			.addCase(insertOrganization.pending, (state) => {
				state.loading = true;
				state.send = false;
			})
			.addCase(insertOrganization.rejected, (state, { payload }) => {
				state.error = payload;
				state.loading = false;
				state.message = {
					header: 'Falha ao salvar organização',
					content: payload?.message,
					error: true,
				};
			});
		builder
			.addCase(switchOrganization.fulfilled, (state, { payload }) => {
				state.organizations = state.organizations.map((org) =>
					org.id === payload.id ? payload : org
				);
				state.loading = false;
				state.error = undefined;
			})
			.addCase(switchOrganization.pending, (state) => {
				state.loading = true;
				state.send = false;
			})
			.addCase(switchOrganization.rejected, (state, { payload }) => {
				state.error = payload;
				state.loading = false;
				state.message = {
					header: 'Falha ao alterar organização',
					content: payload?.message,
					error: true,
				};
			});
		builder
			.addCase(getUnits.fulfilled, (state, { payload }) => {
				state.units = payload ?? [];
				state.loading = false;
				state.error = undefined;
			})
			.addCase(getUnits.pending, (state) => {
				state.loading = true;
			})
			.addCase(getUnits.rejected, (state, { payload }) => {
				state.error = payload;
				state.loading = false;
				state.message = {
					header: 'Falha ao buscar unidades',
					content: payload?.message,
					error: true,
				};
			});
		builder
			.addCase(insertUnit.fulfilled, (state, { payload }) => {
				state.units = state.units
					.filter((unit) => unit.id !== payload.id)
					.concat(payload);
				state.unit = formatEditUnit(payload);
				state.loading = false;
				state.error = undefined;
				state.message = {
					header: 'Salvo',
					content: 'Sua unidade foi salva com sucesso.',
					success: true,
				};
			})
			.addCase(insertUnit.pending, (state) => {
				state.loading = true;
				state.send = false;
			})
			.addCase(insertUnit.rejected, (state, { payload }) => {
				state.error = payload;
				state.loading = false;
				state.message = {
					header: 'Falha ao salvar unidade',
					content: payload?.message,
					error: true,
				};
			});
		builder
			.addCase(switchUnit.fulfilled, (state, { payload }) => {
				state.units = state.units.map((unit) =>
					payload && unit.id === payload.id ? payload : unit
				);
				state.loading = false;
				state.error = undefined;
			})
			.addCase(switchUnit.pending, (state) => {
				state.loading = true;
				state.send = false;
			})
			.addCase(switchUnit.rejected, (state, { payload }) => {
				state.error = payload;
				state.loading = false;
				state.message = {
					header: 'Falha ao alterar unidade',
					content: payload?.message,
					error: true,
				};
			});
		builder
			.addCase(getCourts.fulfilled, (state, { payload }) => {
				state.courts = payload ?? [];
				state.loading = false;
				state.error = undefined;
			})
			.addCase(getCourts.pending, (state) => {
				state.loading = true;
			})
			.addCase(getCourts.rejected, (state, { payload }) => {
				state.error = payload;
				state.loading = false;
				state.message = {
					header: 'Falha ao buscar espaços',
					content: payload?.message,
					error: true,
				};
			});
		builder
			.addCase(insertCourt.fulfilled, (state, { payload }) => {
				state.courts = state.courts
					.filter((court) => court.id !== payload.id)
					.concat(payload);
				state.court = formatEditCourt(payload);
				state.loading = false;
				state.error = undefined;
				state.message = {
					header: 'Salvo',
					content: 'Seu espaço foi salvo com sucesso.',
					success: true,
				};
			})
			.addCase(insertCourt.pending, (state) => {
				state.loading = true;
				state.send = false;
			})
			.addCase(insertCourt.rejected, (state, { payload }) => {
				state.error = payload;
				state.loading = false;
				state.message = {
					header: 'Falha ao salvar espaço',
					content: payload?.message,
					error: true,
				};
			});
		builder
			.addCase(switchCourt.fulfilled, (state, { payload }) => {
				state.courts = state.courts.map((unit) =>
					payload && unit.id === payload.id ? payload : unit
				);
				state.loading = false;
				state.error = undefined;
			})
			.addCase(switchCourt.pending, (state) => {
				state.loading = true;
				state.send = false;
			})
			.addCase(switchCourt.rejected, (state, { payload }) => {
				state.error = payload;
				state.loading = false;
				state.message = {
					header: 'Falha ao alterar espaço',
					content: payload?.message,
					error: true,
				};
			});
	},
});

const formatEditOrg = (
	org: Pick<EditOrg, 'id'> &
		Pick<
			Organization,
			| 'cnpj'
			| 'category'
			| 'name'
			| 'nick'
			| 'link'
			| 'logo'
			| 'horizontal'
			| 'documents'
			| 'responsibles'
			| 'plan'
			| 'active'
		>
) => {
	return {
		id: org.id,
		cnpj: {
			value: org.cnpj,
			changed: false,
			error: checkValidity('cnpj', org.cnpj),
			required: true,
		},
		category: {
			value: org.category,
			changed: false,
			error: !org.category,
			required: true,
		},
		logo: {
			value: org.logo,
			changed: false,
			error: false,
			required: false,
		},
		horizontal: {
			value: org.horizontal,
			changed: false,
			error: false,
			required: false,
		},
		name: {
			value: org.name ?? '',
			changed: false,
			error: checkValidity('name', org.name),
			required: true,
		},
		nick: {
			value: org.nick ?? '',
			changed: false,
			error: false,
			required: false,
		},
		link: {
			value: org.link ?? '',
			changed: false,
			error: false,
			required: false,
		},
		documents: Object.keys(org.documents ?? {}).reduce(
			(docs, doc) => ({
				...docs,
				[doc]: {
					value: org.documents?.[doc as Document],
					changed: false,
					error: false,
				},
			}),
			{}
		),
		responsibles: Object.keys(org.responsibles ?? {}).reduce(
			(resps, resp) => ({
				...resps,
				[resp]: {
					value: org.responsibles?.[resp as Responsible],
					changed: false,
					error: false,
				},
			}),
			{}
		),
		...(org.plan
			? {
					plan: {
						value: org.plan,
						changed: false,
						error: false,
					},
				}
			: {}),
		active: org.active ?? true,
	} as EditOrg;
};

const formatEditUnit = (
	unit: Pick<EditUnit, 'id'> &
		Pick<
			Unit,
			| 'org'
			| 'cnpj'
			| 'name'
			| 'nick'
			| 'logo'
			| 'horizontal'
			| 'slot'
			| 'sports'
			| 'formations'
			| 'destinations'
			| 'active'
		>
) => {
	return {
		id: unit.id,
		org: unit.org,
		cnpj: {
			value: unit.cnpj,
			changed: false,
			error: false,
			required: false,
		},
		name: {
			value: unit.name ?? '',
			changed: false,
			error: checkValidity('name', unit.name),
			required: true,
		},
		nick: {
			value: unit.nick ?? '',
			changed: false,
			error: false,
			required: false,
		},
		logo: {
			value: unit.logo,
			changed: false,
			error: false,
			required: false,
		},
		horizontal: {
			value: unit.horizontal,
			changed: false,
			error: false,
			required: false,
		},
		slot: {
			value: unit.slot ?? 60,
			changed: !unit.slot,
			error: false,
			required: true,
		},
		sports: {
			value: unit.sports ?? [],
			changed: false,
			error: (unit.sports ?? [])?.length === 0,
			required: true,
		},
		formations: unit.formations?.map((formation) =>
			formatEditFormation(formation)
		),
		destinations: unit.destinations?.map((destination) =>
			formatEditDestination(destination)
		),
		active: unit.active ?? true,
	} as EditUnit;
};

const formatEditFormation = (
	formation: Pick<
		Formation,
		| 'code'
		| 'dest'
		| 'title'
		| 'size'
		| 'spare'
		| 'coach'
		| 'staff'
		| 'each'
		| 'payors'
		| 'price'
		| 'currency'
		| 'active'
	> & { new?: boolean }
) => {
	return {
		code: {
			value: formation.code ?? '',
			changed: !formation.code,
			error: !formation.code,
			required: true,
		},
		dest: {
			value: formation.dest,
			changed: false,
			error: !formation.dest,
			required: true,
		},
		title: {
			value: formation.title ?? '',
			changed: false,
			error: !formation.title,
			required: true,
		},
		size: {
			value: formation.size ?? 0,
			changed: !formation.size,
			error: !formation.size,
			required: true,
		},
		spare: {
			value: formation.spare ?? 0,
			changed: false,
			error: false,
			required: false,
		},
		coach: {
			value: formation.coach ?? 0,
			changed: false,
			error: false,
			required: false,
		},
		staff: {
			value: formation.staff ?? 0,
			changed: false,
			error: false,
			required: false,
		},
		each: {
			value: formation.each,
			changed: false,
			error: false,
			required: false,
		},
		payors: {
			value: formation.payors,
			changed: false,
			error: false,
			required: false,
		},
		price: {
			value: formation.price,
			changed: false,
			error: false,
			required: false,
		},
		currency: {
			value: formation.currency,
			changed: false,
			error: false,
			required: false,
		},
		active: {
			value: formation.active === undefined ? true : formation.active,
			changed: false,
			error: false,
			required: true,
		},
		new: {
			value: !!formation.new,
			changed: false,
			error: false,
			required: false,
		},
	} as EditFormation;
};

const formatEditDestination = (
	destination: Pick<
		Destination,
		| 'destination'
		| 'color'
		| 'title'
		| 'pt_BR'
		| 'en_US'
		| 'allocable'
		| 'active'
	> & { new?: boolean }
) => {
	return {
		destination: {
			value: destination.destination,
			changed: !destination.destination,
			error: !destination.destination,
			required: true,
		},
		color: {
			value: destination.color,
			changed: false,
			error: !destination.color,
			required: true,
		},
		title: {
			value: destination.title ?? destination.pt_BR ?? destination.en_US ?? '',
			changed: !destination.title,
			error: !destination.title && !destination.pt_BR && !destination.en_US,
			required: true,
		},
		allocable: {
			value: destination.allocable ?? true,
			changed: destination.allocable === undefined,
			error: false,
			required: true,
		},
		active: {
			value: destination.active === undefined ? true : destination.active,
			changed: destination.active === undefined,
			error: false,
			required: true,
		},
		new: {
			value: !!destination.new,
			changed: !!destination.new,
			error: false,
			required: false,
		},
	} as EditDestination;
};

const formatEditCourt = (
	court: Pick<EditCourt, 'id'> &
		Pick<
			Court,
			| 'unit'
			| 'number'
			| 'name'
			| 'sports'
			| 'hours'
			| 'surface'
			| 'indoor'
			| 'images'
			| 'active'
		>
) => {
	return {
		id: court.id,
		unit: court.unit,
		number: {
			value: court.number ?? '',
			changed: false,
			error: !court.number,
			required: true,
		},
		name: {
			value: court.name ?? '',
			changed: false,
			error: checkValidity('name', court.name),
			required: true,
		},
		sports: {
			value: court.sports ?? [],
			changed: false,
			error: (court.sports ?? [])?.length === 0,
			required: true,
		},
		surface: {
			value: court.surface,
			changed: false,
			error: false,
			required: false,
		},
		indoor: {
			value: court.indoor,
			changed: false,
			error: false,
			required: false,
		},
		images: {
			value: court.images,
			changed: false,
			error: false,
			required: false,
		},
		hours: {
			value: court.hours,
			changed: false,
			error: false,
			required: false,
		},
		active: court.active ?? true,
	} as EditCourt;
};

export const getOrganizations = createAsyncThunk<
	Organization[],
	void,
	{ rejectValue: SerializedError }
>(
	'organizations/getOrganizations',
	async (_, { dispatch, rejectWithValue }) => {
		try {
			await dispatch(authRefresh());

			const { data } = await api.organizations.get();

			return data;
		} catch (error) {
			return rejectWithValue(
				(error as AxiosError).response?.data as SerializedError
			);
		}
	}
);

export const insertOrganization = createAsyncThunk<
	Organization,
	Partial<Organization>,
	{ rejectValue: SerializedError }
>(
	'organizations/insertOrganization',
	async (organization, { dispatch, rejectWithValue }) => {
		try {
			await dispatch(authRefresh());

			const { data } = await api.organizations.post(organization);

			try {
				const documents = await Promise.all(
					Object.keys(organization.documents ?? {}).map(async (doc) => {
						const document = organization.documents?.[doc as Document];
						const url = data.documents?.[doc as Document];

						if (url && document?.startsWith('blob')) {
							const file = await fetch(document).then((r) => r.blob());

							const savedFile = await api.documents.put(url, file);

							return {
								[doc]:
									savedFile.status === 200
										? `https://docs.pontodoesporte.com.br/organizations/${organization.id}/${doc}.pdf`
										: undefined,
							};
						}

						return {
							[doc]: document,
						};
					})
				);

				return {
					...data,
					documents: {
						...data.documents,
						...documents.reduce(
							(docs, doc) => ({
								...docs,
								...doc,
							}),
							{}
						),
					},
				};
			} catch (error) {
				console.info('Erro ao salvar arquivos.');
				console.error(error);

				return data;
			}
		} catch (error) {
			return rejectWithValue(
				(error as AxiosError).response?.data as SerializedError
			);
		} finally {
			setTimeout(() => {
				dispatch(resetMessage());
			}, 10000);
		}
	}
);

export const switchOrganization = createAsyncThunk<
	Organization,
	{ org: string; activate: boolean },
	{ rejectValue: SerializedError }
>(
	'organizations/switchOrganization',
	async ({ org, activate }, { dispatch, rejectWithValue }) => {
		try {
			await dispatch(authRefresh());

			const { data } = await api.organizations.switch(org, activate);

			return data;
		} catch (error) {
			return rejectWithValue(
				(error as AxiosError).response?.data as SerializedError
			);
		} finally {
			setTimeout(() => {
				dispatch(resetMessage());
			}, 10000);
		}
	}
);

export const getUnits = createAsyncThunk<
	Unit[],
	string | undefined,
	{ rejectValue: SerializedError }
>('organizations/getUnits', async (org, { dispatch, rejectWithValue }) => {
	try {
		await dispatch(authRefresh());

		const { data } = await api.units.get(org);

		return data;
	} catch (error) {
		return rejectWithValue(
			(error as AxiosError).response?.data as SerializedError
		);
	}
});

export const insertUnit = createAsyncThunk<
	Unit,
	Partial<Unit>,
	{ rejectValue: SerializedError }
>('organizations/insertUnit', async (unit, { dispatch, rejectWithValue }) => {
	try {
		await dispatch(authRefresh());

		const { data } = await api.units.post(unit);

		return data;
	} catch (error) {
		return rejectWithValue(
			(error as AxiosError).response?.data as SerializedError
		);
	} finally {
		setTimeout(() => {
			dispatch(resetMessage());
		}, 10000);
	}
});

export const switchUnit = createAsyncThunk<
	Unit,
	{ unit: string; activate: boolean },
	{ rejectValue: SerializedError }
>(
	'organizations/switchUnit',
	async ({ unit, activate }, { dispatch, rejectWithValue }) => {
		try {
			await dispatch(authRefresh());

			const { data } = await api.units.switch(unit, activate);

			return data;
		} catch (error) {
			return rejectWithValue(
				(error as AxiosError).response?.data as SerializedError
			);
		} finally {
			setTimeout(() => {
				dispatch(resetMessage());
			}, 10000);
		}
	}
);

export const getCourts = createAsyncThunk<
	Court[],
	string,
	{ rejectValue: SerializedError }
>('organizations/getCourts', async (unit, { dispatch, rejectWithValue }) => {
	try {
		await dispatch(authRefresh());

		const { data } = await api.courts.get(unit);

		return data;
	} catch (error) {
		return rejectWithValue(
			(error as AxiosError).response?.data as SerializedError
		);
	}
});

export const insertCourt = createAsyncThunk<
	Court,
	Partial<Court>,
	{ rejectValue: SerializedError }
>('organizations/insertCourt', async (court, { dispatch, rejectWithValue }) => {
	try {
		await dispatch(authRefresh());

		const { data } = await api.courts.post(court);

		return data;
	} catch (error) {
		return rejectWithValue(
			(error as AxiosError).response?.data as SerializedError
		);
	} finally {
		setTimeout(() => {
			dispatch(resetMessage());
		}, 10000);
	}
});

export const switchCourt = createAsyncThunk<
	Court,
	{ court: string; activate: boolean },
	{ rejectValue: SerializedError }
>(
	'organizations/switchCourt',
	async ({ court, activate }, { dispatch, rejectWithValue }) => {
		try {
			await dispatch(authRefresh());

			const { data } = await api.courts.switch(court, activate);

			return data;
		} catch (error) {
			return rejectWithValue(
				(error as AxiosError).response?.data as SerializedError
			);
		} finally {
			setTimeout(() => {
				dispatch(resetMessage());
			}, 10000);
		}
	}
);

export const {
	newOrganization,
	editOrganization,
	changeOrganization,
	changeOrgResponsible,
	changeOrgDocument,
	changeOrgPlan,
	newUnit,
	editUnit,
	changeUnit,
	addUnitFormation,
	changeUnitFormation,
	removeUnitFormation,
	addUnitDestination,
	changeUnitDestination,
	removeUnitDestination,
	newCourt,
	editCourt,
	changeCourt,
	changeCourtHours,
	setSend,
	resetMessage,
	resetOrganizations,
	resetOrganization,
	resetUnits,
	resetUnit,
	resetCourts,
	resetCourt,
} = organizationSlice.actions;

export default organizationSlice.reducer;
