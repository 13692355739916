import React, { useEffect } from 'react';

import { Link } from 'react-router-dom';
import { Card, Header, Icon, Image, Statistic } from 'semantic-ui-react';

import Counter from './counter';
import { ScreenSize } from '../../hooks';
import { useAppDispatch } from '../../store/hooks';
import { RightItem } from '../../store/rights';
import { getStat, StatisticItem } from '../../store/statistics';

interface iRank {
	rank: RightItem;
	stat?: StatisticItem;
	screenSize?: ScreenSize;
}

const Rank: React.FC<iRank> = ({ rank, stat, screenSize }) => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(getStat({ id: rank.id, type: 'rank' }));
	}, [dispatch, rank.id]);

	return (
		<Card
			as={Link}
			to={`/classification/${rank.id}`}
			centered={screenSize === 'mobile'}
			fluid={screenSize === 'mobile'}
		>
			<Card.Content>
				<Header
					textAlign={screenSize !== 'mobile' ? 'left' : 'center'}
					size={screenSize === 'mobile' ? 'small' : 'medium'}
				>
					<Header.Content>{rank?.name}</Header.Content>
					<Image src={rank.img} size="mini" floated="right" />
				</Header>
			</Card.Content>
			{stat?.loading ? (
				<Card.Content textAlign="center">
					<Icon name="spinner" loading size="big" color="black" />
				</Card.Content>
			) : (
				<Card.Content textAlign="center">
					{stat?.stats?.map((stat) => {
						switch (stat.type) {
							case 'count':
								return screenSize === 'mobile' ? (
									<Header
										key={stat.id}
										content={
											<Counter
												initial={0}
												target={+(stat.value ?? 0)}
												easing="quadratic"
											/>
										}
										subheader={stat.name}
										size="tiny"
									/>
								) : (
									<Statistic key={stat.id} size="mini">
										<Statistic.Label>{stat.name}</Statistic.Label>
										<Statistic.Value>
											<Counter
												initial={0}
												target={+(stat.value ?? 0)}
												easing="quadratic"
											/>
										</Statistic.Value>
									</Statistic>
								);
							case 'date':
								return screenSize === 'mobile' ? (
									<Header
										key={stat.id}
										content={new Date(stat.value).toLocaleDateString()}
										subheader={stat.name}
										size="tiny"
									/>
								) : (
									<Statistic
										key={stat.id}
										label={stat.name}
										value={new Date(stat.value).toLocaleDateString()}
										size="mini"
									/>
								);
							default:
								return null;
						}
					})}
				</Card.Content>
			)}
		</Card>
	);
};

export default Rank;
