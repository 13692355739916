import { FlagNameValues } from 'semantic-ui-react';

export const countryOptions: {
	code: string;
	value: FlagNameValues;
	en_US: string;
	pt_BR: string;
	continent: string;
}[] = [
	{
		code: '47',
		value: 'bv',
		en_US: 'Bouvet Island',
		pt_BR: 'Ilha Bouvet',
		continent: '',
	},
	{
		code: '358',
		value: 'ax',
		en_US: 'Aland Islands',
		pt_BR: 'Ilhas de Aland',
		continent: '',
	},
	{
		code: '93',
		value: 'af',
		en_US: 'Afghanistan',
		pt_BR: 'Afeganistão',
		continent: 'Ásia',
	},
	{
		code: '27',
		value: 'za',
		en_US: 'South Africa',
		pt_BR: 'África do Sul',
		continent: 'África',
	},
	{
		code: '355',
		value: 'al',
		en_US: 'Albania',
		pt_BR: 'Albânia',
		continent: 'Europa',
	},
	{
		code: '49',
		value: 'de',
		en_US: 'Germany',
		pt_BR: 'Alemanha',
		continent: 'Europa',
	},
	{
		code: '376',
		value: 'ad',
		en_US: 'Andorra',
		pt_BR: 'Andorra',
		continent: 'Europa',
	},
	{
		code: '244',
		value: 'ao',
		en_US: 'Angola',
		pt_BR: 'Angola',
		continent: 'África',
	},
	{
		code: '1',
		value: 'ai',
		en_US: 'Anguilla',
		pt_BR: 'Anguilla',
		continent: 'América Central',
	},
	{
		code: '1',
		value: 'ag',
		en_US: 'Antigua',
		pt_BR: 'Antígua e Barbuda',
		continent: 'América Central',
	},
	{
		code: '599',
		value: 'an',
		en_US: 'Netherlandsantilles',
		pt_BR: 'Antilhas Holandesas',
		continent: 'América Central',
	},
	{
		code: '966',
		value: 'sa',
		en_US: 'Saudi Arabia',
		pt_BR: 'Arábia Saudita',
		continent: 'Ásia',
	},
	{
		code: '213',
		value: 'dz',
		en_US: 'Algeria',
		pt_BR: 'Argélia',
		continent: 'África',
	},
	{
		code: '54',
		value: 'ar',
		en_US: 'Argentina',
		pt_BR: 'Argentina',
		continent: 'América do Sul',
	},
	{
		code: '374',
		value: 'am',
		en_US: 'Armenia',
		pt_BR: 'Armênia',
		continent: 'Ásia',
	},
	{
		code: '297',
		value: 'aw',
		en_US: 'Aruba',
		pt_BR: 'Aruba',
		continent: 'América Central',
	},
	{
		code: '61',
		value: 'au',
		en_US: 'Australia',
		pt_BR: 'Austrália',
		continent: 'Oceania',
	},
	{
		code: '43',
		value: 'at',
		en_US: 'Austria',
		pt_BR: 'Áustria',
		continent: 'Europa',
	},
	{
		code: '994',
		value: 'az',
		en_US: 'Azerbaijan',
		pt_BR: 'Azerbaijão',
		continent: 'Ásia',
	},
	{
		code: '1',
		value: 'bs',
		en_US: 'Bahamas',
		pt_BR: 'Bahamas',
		continent: 'América Central',
	},
	{
		code: '880',
		value: 'bd',
		en_US: 'Bangladesh',
		pt_BR: 'Bangladesh',
		continent: 'Ásia',
	},
	{
		code: '1',
		value: 'bb',
		en_US: 'Barbados',
		pt_BR: 'Barbados',
		continent: 'América Central',
	},
	{
		code: '973',
		value: 'bh',
		en_US: 'Bahrain',
		pt_BR: 'Bahrein',
		continent: 'Ásia',
	},
	{
		code: '32',
		value: 'be',
		en_US: 'Belgium',
		pt_BR: 'Bélgica',
		continent: 'Europa',
	},
	{
		code: '501',
		value: 'bz',
		en_US: 'Belize',
		pt_BR: 'Belize',
		continent: 'América Central',
	},
	{
		code: '229',
		value: 'bj',
		en_US: 'Benin',
		pt_BR: 'Benim',
		continent: 'África',
	},
	{
		code: '1',
		value: 'bm',
		en_US: 'Bermuda',
		pt_BR: 'Bermudas',
		continent: 'América Central',
	},
	{
		code: '375',
		value: 'by',
		en_US: 'Belarus',
		pt_BR: 'Bielorrússia',
		continent: 'Europa',
	},
	{
		code: '591',
		value: 'bo',
		en_US: 'Bolivia',
		pt_BR: 'Bolívia',
		continent: 'América do Sul',
	},
	{
		code: '387',
		value: 'ba',
		en_US: 'Bosnia',
		pt_BR: 'Bósnia e Herzegovina',
		continent: 'Europa',
	},
	{
		code: '267',
		value: 'bw',
		en_US: 'Botswana',
		pt_BR: 'Botswana',
		continent: 'África',
	},
	{
		code: '55',
		value: 'br',
		en_US: 'Brazil',
		pt_BR: 'Brasil',
		continent: 'América do Sul',
	},
	{
		code: '673',
		value: 'bn',
		en_US: 'Brunei',
		pt_BR: 'Brunei',
		continent: 'Ásia',
	},
	{
		code: '359',
		value: 'bg',
		en_US: 'Bulgaria',
		pt_BR: 'Bulgária',
		continent: 'Europa',
	},
	{
		code: '226',
		value: 'bf',
		en_US: 'Burkina Faso',
		pt_BR: 'Burkina Faso',
		continent: 'África',
	},
	{
		code: '257',
		value: 'bi',
		en_US: 'Burundi',
		pt_BR: 'Burundi',
		continent: 'África',
	},
	{
		code: '975',
		value: 'bt',
		en_US: 'Bhutan',
		pt_BR: 'Butão',
		continent: 'Ásia',
	},
	{
		code: '238',
		value: 'cv',
		en_US: 'Cape Verde',
		pt_BR: 'Cabo Verde',
		continent: 'África',
	},
	{
		code: '237',
		value: 'cm',
		en_US: 'Cameroon',
		pt_BR: 'Camarões',
		continent: 'África',
	},
	{
		code: '855',
		value: 'kh',
		en_US: 'Cambodia',
		pt_BR: 'Camboja',
		continent: 'Ásia',
	},
	{
		code: '1',
		value: 'ca',
		en_US: 'Canada',
		pt_BR: 'Canadá',
		continent: 'América do Norte',
	},
	{
		code: '7',
		value: 'kz',
		en_US: 'Kazakhstan',
		pt_BR: 'Cazaquistão',
		continent: 'Ásia',
	},
	{
		code: '235',
		value: 'td',
		en_US: 'Chad',
		pt_BR: 'Chade',
		continent: 'África',
	},
	{
		code: '56',
		value: 'cl',
		en_US: 'Chile',
		pt_BR: 'Chile',
		continent: 'América do Sul',
	},
	{
		code: '86',
		value: 'cn',
		en_US: 'China',
		pt_BR: 'República Popular da China',
		continent: 'Ásia',
	},
	{
		code: '357',
		value: 'cy',
		en_US: 'Cyprus',
		pt_BR: 'Chipre',
		continent: 'Europa',
	},
	{
		code: '57',
		value: 'co',
		en_US: 'Colombia',
		pt_BR: 'Colômbia',
		continent: 'América do Sul',
	},
	{
		code: '269',
		value: 'km',
		en_US: 'Comoros',
		pt_BR: 'Comores',
		continent: 'África',
	},
	{
		code: '242',
		value: 'cg',
		en_US: 'Congo Brazzaville',
		pt_BR: 'Congo-Brazzaville',
		continent: 'África',
	},
	{
		code: '243',
		value: 'cd',
		en_US: 'Congo',
		pt_BR: 'Congo-Kinshasa',
		continent: 'África',
	},
	{
		code: '850',
		value: 'kp',
		en_US: 'North Korea',
		pt_BR: 'Coreia do Norte',
		continent: 'Ásia',
	},
	{
		code: '82',
		value: 'kr',
		en_US: 'South Korea',
		pt_BR: 'Coreia do Sul',
		continent: 'Ásia',
	},
	{
		code: '225',
		value: 'ci',
		en_US: 'Cote Divoire',
		pt_BR: 'Costa do Marfim',
		continent: 'África',
	},
	{
		code: '506',
		value: 'cr',
		en_US: 'Costa Rica',
		pt_BR: 'Costa Rica',
		continent: 'América Central',
	},
	{
		code: '385',
		value: 'hr',
		en_US: 'Croatia',
		pt_BR: 'Croácia',
		continent: 'Europa',
	},
	{
		code: '53',
		value: 'cu',
		en_US: 'Cuba',
		pt_BR: 'Cuba',
		continent: 'América Central',
	},
	{
		code: '45',
		value: 'dk',
		en_US: 'Denmark',
		pt_BR: 'Dinamarca',
		continent: 'Europa',
	},
	{
		code: '253',
		value: 'dj',
		en_US: 'Djibouti',
		pt_BR: 'Djibuti',
		continent: 'África',
	},
	{
		code: '1',
		value: 'dm',
		en_US: 'Dominica',
		pt_BR: 'Dominica',
		continent: 'América Central',
	},
	{
		code: '20',
		value: 'eg',
		en_US: 'Egypt',
		pt_BR: 'Egipto',
		continent: 'África/Ásia',
	},
	{
		code: '503',
		value: 'sv',
		en_US: 'El Salvador',
		pt_BR: 'El Salvador',
		continent: 'América Central',
	},
	{
		code: '971',
		value: 'ae',
		en_US: 'United Arab Emirates',
		pt_BR: 'Emirados Árabes Unidos',
		continent: 'Ásia',
	},
	{
		code: '593',
		value: 'ec',
		en_US: 'Ecuador',
		pt_BR: 'Equador',
		continent: 'América do Sul',
	},
	{
		code: '291',
		value: 'er',
		en_US: 'Eritrea',
		pt_BR: 'Eritreia',
		continent: 'África',
	},
	{
		code: '421',
		value: 'sk',
		en_US: 'Slovakia',
		pt_BR: 'Eslováquia',
		continent: 'Europa',
	},
	{
		code: '386',
		value: 'si',
		en_US: 'Slovenia',
		pt_BR: 'Eslovénia',
		continent: 'Europa',
	},
	{
		code: '34',
		value: 'es',
		en_US: 'Spain',
		pt_BR: 'Espanha',
		continent: 'Europa',
	},
	{
		code: '1',
		value: 'us',
		en_US: 'United States',
		pt_BR: 'Estados Unidos',
		continent: 'América do Norte',
	},
	{
		code: '372',
		value: 'ee',
		en_US: 'Estonia',
		pt_BR: 'Estónia',
		continent: 'Europa',
	},
	{
		code: '251',
		value: 'et',
		en_US: 'Ethiopia',
		pt_BR: 'Etiópia',
		continent: 'África',
	},
	{
		code: '679',
		value: 'fj',
		en_US: 'Fiji',
		pt_BR: 'Fiji',
		continent: 'Oceania',
	},
	{
		code: '63',
		value: 'ph',
		en_US: 'Philippines',
		pt_BR: 'Filipinas',
		continent: 'Ásia',
	},
	{
		code: '358',
		value: 'fi',
		en_US: 'Finland',
		pt_BR: 'Finlândia',
		continent: 'Europa',
	},
	{
		code: '33',
		value: 'fr',
		en_US: 'France',
		pt_BR: 'França',
		continent: 'Europa',
	},
	{
		code: '241',
		value: 'ga',
		en_US: 'Gabon',
		pt_BR: 'Gabão',
		continent: 'África',
	},
	{
		code: '220',
		value: 'gm',
		en_US: 'Gambia',
		pt_BR: 'Gâmbia',
		continent: 'África',
	},
	{
		code: '233',
		value: 'gh',
		en_US: 'Ghana',
		pt_BR: 'Gana',
		continent: 'África',
	},
	{
		code: '995',
		value: 'ge',
		en_US: 'Georgia',
		pt_BR: 'Geórgia',
		continent: 'Ásia',
	},
	{
		code: '350',
		value: 'gi',
		en_US: 'Gibraltar',
		pt_BR: 'Gibraltar',
		continent: 'Europa',
	},
	{
		code: '1',
		value: 'gd',
		en_US: 'Grenada',
		pt_BR: 'Granada',
		continent: 'América Central',
	},
	{
		code: '30',
		value: 'gr',
		en_US: 'Greece',
		pt_BR: 'Grécia',
		continent: 'Europa',
	},
	{
		code: '299',
		value: 'gl',
		en_US: 'Greenland',
		pt_BR: 'Groenlândia',
		continent: 'América do Norte',
	},
	{
		code: '590',
		value: 'gp',
		en_US: 'Guadeloupe',
		pt_BR: 'Guadalupe',
		continent: 'América Central',
	},
	{
		code: '671',
		value: 'gu',
		en_US: 'Guam',
		pt_BR: 'Guam',
		continent: 'Oceania',
	},
	{
		code: '502',
		value: 'gt',
		en_US: 'Guatemala',
		pt_BR: 'Guatemala',
		continent: 'América Central',
	},
	{
		code: '592',
		value: 'gy',
		en_US: 'Guyana',
		pt_BR: 'Guiana',
		continent: 'América do Sul',
	},
	{
		code: '594',
		value: 'gf',
		en_US: 'French Guiana',
		pt_BR: 'Guiana Francesa',
		continent: 'América do Sul',
	},
	{
		code: '224',
		value: 'gn',
		en_US: 'Guinea',
		pt_BR: 'Guiné',
		continent: 'África',
	},
	{
		code: '245',
		value: 'gw',
		en_US: 'Guinea-Bissau',
		pt_BR: 'Guiné-Bissau',
		continent: 'África',
	},
	{
		code: '240',
		value: 'gq',
		en_US: 'Equatorial Guinea',
		pt_BR: 'Guiné Equatorial',
		continent: 'África',
	},
	{
		code: '509',
		value: 'ht',
		en_US: 'Haiti',
		pt_BR: 'Haiti',
		continent: 'América Central',
	},
	{
		code: '504',
		value: 'hn',
		en_US: 'Honduras',
		pt_BR: 'Honduras',
		continent: 'América Central',
	},
	{
		code: '852',
		value: 'hk',
		en_US: 'Hong Kong',
		pt_BR: 'Hong Kong',
		continent: 'Ásia',
	},
	{
		code: '36',
		value: 'hu',
		en_US: 'Hungary',
		pt_BR: 'Hungria',
		continent: 'Europa',
	},
	{
		code: '967',
		value: 'ye',
		en_US: 'Yemen',
		pt_BR: 'Iêmen',
		continent: 'Ásia',
	},
	{
		code: '1',
		value: 'ky',
		en_US: 'Cayman Islands',
		pt_BR: 'Ilhas Cayman',
		continent: 'América Central',
	},
	{
		code: '672',
		value: 'cx',
		en_US: 'Christmas Island',
		pt_BR: 'Ilha Christmas',
		continent: 'Oceania',
	},
	{
		code: '672',
		value: 'cc',
		en_US: 'Cocos Islands',
		pt_BR: 'Ilhas Cocos',
		continent: 'Oceania',
	},
	{
		code: '682',
		value: 'ck',
		en_US: 'Cook Islands',
		pt_BR: 'Ilhas Cook',
		continent: 'Oceania',
	},
	{
		code: '298',
		value: 'fo',
		en_US: 'Faroe Islands',
		pt_BR: 'Ilhas Féroe',
		continent: 'Europa',
	},
	{
		code: '672',
		value: 'hm',
		en_US: 'Heard Island',
		pt_BR: 'Ilha Heard e Ilhas McDonald',
		continent: 'Oceania',
	},
	{
		code: '960',
		value: 'mv',
		en_US: 'Maldives',
		pt_BR: 'Maldivas',
		continent: 'Ásia',
	},
	{
		code: '500',
		value: 'fk',
		en_US: 'Falkland Islands',
		pt_BR: 'Ilhas Malvinas',
		continent: 'América do Sul',
	},
	{
		code: '1',
		value: 'mp',
		en_US: 'Northern Mariana Islands',
		pt_BR: 'Ilhas Marianas do Norte',
		continent: 'Oceania',
	},
	{
		code: '692',
		value: 'mh',
		en_US: 'Marshall Islands',
		pt_BR: 'Ilhas Marshall',
		continent: 'Oceania',
	},
	{
		code: '672',
		value: 'nf',
		en_US: 'Norfolk Island',
		pt_BR: 'Ilha Norfolk',
		continent: 'Oceania',
	},
	{
		code: '677',
		value: 'sb',
		en_US: 'Solomon Islands',
		pt_BR: 'Ilhas Salomão',
		continent: 'Oceania',
	},
	{
		code: '1',
		value: 'vi',
		en_US: 'US Virgin Islands',
		pt_BR: 'Ilhas Virgens Americanas',
		continent: 'América Central',
	},
	{
		code: '1',
		value: 'vg',
		en_US: 'British Virgin Islands',
		pt_BR: 'Ilhas Virgens Britânicas',
		continent: 'América Central',
	},
	{
		code: '91',
		value: 'in',
		en_US: 'India',
		pt_BR: 'Índia',
		continent: 'Ásia',
	},
	{
		code: '62',
		value: 'id',
		en_US: 'Indonesia',
		pt_BR: 'Indonésia',
		continent: 'Ásia/Oceania',
	},
	{ code: '98', value: 'ir', en_US: 'Iran', pt_BR: 'Irã', continent: 'Ásia' },
	{
		code: '964',
		value: 'iq',
		en_US: 'Iraq',
		pt_BR: 'Iraque',
		continent: 'Ásia',
	},
	{
		code: '353',
		value: 'ie',
		en_US: 'Ireland',
		pt_BR: 'Irlanda',
		continent: 'Europa',
	},
	{
		code: '354',
		value: 'is',
		en_US: 'Iceland',
		pt_BR: 'Islândia',
		continent: 'Europa',
	},
	{
		code: '972',
		value: 'il',
		en_US: 'Israel',
		pt_BR: 'Israel',
		continent: 'Ásia',
	},
	{
		code: '39',
		value: 'it',
		en_US: 'Italy',
		pt_BR: 'Itália',
		continent: 'Europa',
	},
	{
		code: '1',
		value: 'jm',
		en_US: 'Jamaica',
		pt_BR: 'Jamaica',
		continent: 'América Central',
	},
	{
		code: '81',
		value: 'jp',
		en_US: 'Japan',
		pt_BR: 'Japão',
		continent: 'Ásia',
	},
	{
		code: '962',
		value: 'jo',
		en_US: 'Jordan',
		pt_BR: 'Jordânia',
		continent: 'Ásia',
	},
	{
		code: '686',
		value: 'ki',
		en_US: 'Kiribati',
		pt_BR: 'Kiribati',
		continent: 'Oceania',
	},
	{
		code: '965',
		value: 'kw',
		en_US: 'Kuwait',
		pt_BR: 'Kuwait',
		continent: 'Ásia',
	},
	{
		code: '856',
		value: 'la',
		en_US: 'Laos',
		pt_BR: 'Laos',
		continent: 'Ásia',
	},
	{
		code: '266',
		value: 'ls',
		en_US: 'Lesotho',
		pt_BR: 'Lesoto',
		continent: 'África',
	},
	{
		code: '371',
		value: 'lv',
		en_US: 'Latvia',
		pt_BR: 'Letônia',
		continent: 'Europa',
	},
	{
		code: '961',
		value: 'lb',
		en_US: 'Lebanon',
		pt_BR: 'Líbano',
		continent: 'Ásia',
	},
	{
		code: '231',
		value: 'lr',
		en_US: 'Liberia',
		pt_BR: 'Libéria',
		continent: 'África',
	},
	{
		code: '218',
		value: 'ly',
		en_US: 'Libya',
		pt_BR: 'Líbia',
		continent: 'África',
	},
	{
		code: '423',
		value: 'li',
		en_US: 'Liechtenstein',
		pt_BR: 'Liechtenstein',
		continent: 'Europa',
	},
	{
		code: '370',
		value: 'lt',
		en_US: 'Lithuania',
		pt_BR: 'Lituânia',
		continent: 'Europa',
	},
	{
		code: '352',
		value: 'lu',
		en_US: 'Luxembourg',
		pt_BR: 'Luxemburgo',
		continent: 'Europa',
	},
	{
		code: '853',
		value: 'mo',
		en_US: 'Macau',
		pt_BR: 'Macau',
		continent: 'Ásia',
	},
	{
		code: '389',
		value: 'mk',
		en_US: 'Macedonia',
		pt_BR: 'República da Macedônia',
		continent: 'Europa',
	},
	{
		code: '261',
		value: 'mg',
		en_US: 'Madagascar',
		pt_BR: 'Madagascar',
		continent: 'África',
	},
	{
		code: '60',
		value: 'my',
		en_US: 'Malaysia',
		pt_BR: 'Malásia',
		continent: 'Ásia',
	},
	{
		code: '265',
		value: 'mw',
		en_US: 'Malawi',
		pt_BR: 'Malawi',
		continent: 'África',
	},
	{
		code: '223',
		value: 'ml',
		en_US: 'Mali',
		pt_BR: 'Mali',
		continent: 'África',
	},
	{
		code: '356',
		value: 'mt',
		en_US: 'Malta',
		pt_BR: 'Malta',
		continent: 'Europa',
	},
	{
		code: '212',
		value: 'ma',
		en_US: 'Morocco',
		pt_BR: 'Marrocos',
		continent: 'África',
	},
	{
		code: '596',
		value: 'mq',
		en_US: 'Martinique',
		pt_BR: 'Martinica',
		continent: 'América Central',
	},
	{
		code: '230',
		value: 'mu',
		en_US: 'Mauritius',
		pt_BR: 'Maurícia',
		continent: 'África',
	},
	{
		code: '222',
		value: 'mr',
		en_US: 'Mauritania',
		pt_BR: 'Mauritânia',
		continent: 'África',
	},
	{
		code: '269',
		value: 'yt',
		en_US: 'Mayotte',
		pt_BR: 'Mayotte',
		continent: 'África',
	},
	{
		code: '52',
		value: 'mx',
		en_US: 'Mexico',
		pt_BR: 'México',
		continent: 'América do Norte',
	},
	{
		code: '691',
		value: 'fm',
		en_US: 'Micronesia',
		pt_BR: 'Estados Federados da Micronésia',
		continent: 'Oceania',
	},
	{
		code: '258',
		value: 'mz',
		en_US: 'Mozambique',
		pt_BR: 'Moçambique',
		continent: 'África',
	},
	{
		code: '373',
		value: 'md',
		en_US: 'Moldova',
		pt_BR: 'Moldávia',
		continent: 'Europa',
	},
	{
		code: '377',
		value: 'mc',
		en_US: 'Monaco',
		pt_BR: 'Mônaco',
		continent: 'Europa',
	},
	{
		code: '976',
		value: 'mn',
		en_US: 'Mongolia',
		pt_BR: 'Mongólia',
		continent: 'Ásia',
	},
	{
		code: '382',
		value: 'me',
		en_US: 'Montenegro',
		pt_BR: 'Montenegro',
		continent: 'Europa',
	},
	{
		code: '1',
		value: 'ms',
		en_US: 'Montserrat',
		pt_BR: 'Montserrat',
		continent: 'América Central',
	},
	{
		code: '95',
		value: 'mm',
		en_US: 'Burma',
		pt_BR: 'Myanmar',
		continent: 'Ásia',
	},
	{
		code: '264',
		value: 'na',
		en_US: 'Namibia',
		pt_BR: 'Namíbia',
		continent: 'África',
	},
	{
		code: '674',
		value: 'nr',
		en_US: 'Nauru',
		pt_BR: 'Nauru',
		continent: 'Oceania',
	},
	{
		code: '977',
		value: 'np',
		en_US: 'Nepal',
		pt_BR: 'Nepal',
		continent: 'Ásia',
	},
	{
		code: '505',
		value: 'ni',
		en_US: 'Nicaragua',
		pt_BR: 'Nicarágua',
		continent: 'América Central',
	},
	{
		code: '227',
		value: 'ne',
		en_US: 'Niger',
		pt_BR: 'Níger',
		continent: 'África',
	},
	{
		code: '234',
		value: 'ng',
		en_US: 'Nigeria',
		pt_BR: 'Nigéria',
		continent: 'África',
	},
	{
		code: '683',
		value: 'nu',
		en_US: 'Niue',
		pt_BR: 'Niue',
		continent: 'Oceania',
	},
	{
		code: '47',
		value: 'no',
		en_US: 'Norway',
		pt_BR: 'Noruega',
		continent: 'Europa',
	},
	{
		code: '687',
		value: 'nc',
		en_US: 'New Caledonia',
		pt_BR: 'Nova Caledônia',
		continent: 'Oceania',
	},
	{
		code: '64',
		value: 'nz',
		en_US: 'New Zealand',
		pt_BR: 'Nova Zelândia',
		continent: 'Oceania',
	},
	{
		code: '968',
		value: 'om',
		en_US: 'Oman',
		pt_BR: 'Omã',
		continent: 'Ásia',
	},
	{
		code: '31',
		value: 'nl',
		en_US: 'Netherlands',
		pt_BR: 'Países Baixos',
		continent: 'Europa',
	},
	{
		code: '680',
		value: 'pw',
		en_US: 'Palau',
		pt_BR: 'Palau',
		continent: 'Oceania',
	},
	{
		code: '970',
		value: 'ps',
		en_US: 'Palestine',
		pt_BR: 'Palestina',
		continent: 'Ásia',
	},
	{
		code: '507',
		value: 'pa',
		en_US: 'Panama',
		pt_BR: 'Panamá',
		continent: 'América Central',
	},
	{
		code: '675',
		value: 'pg',
		en_US: 'New Guinea',
		pt_BR: 'Papua-Nova Guiné',
		continent: 'Oceania',
	},
	{
		code: '92',
		value: 'pk',
		en_US: 'Pakistan',
		pt_BR: 'Paquistão',
		continent: 'Ásia',
	},
	{
		code: '595',
		value: 'py',
		en_US: 'Paraguay',
		pt_BR: 'Paraguai',
		continent: 'América do Sul',
	},
	{
		code: '51',
		value: 'pe',
		en_US: 'Peru',
		pt_BR: 'Peru',
		continent: 'América do Sul',
	},
	{
		code: '689',
		value: 'pf',
		en_US: 'French Polynesia',
		pt_BR: 'Polinésia Francesa',
		continent: 'Oceania',
	},
	{
		code: '48',
		value: 'pl',
		en_US: 'Poland',
		pt_BR: 'Polônia',
		continent: 'Europa',
	},
	{
		code: '1',
		value: 'pr',
		en_US: 'Puerto Rico',
		pt_BR: 'Porto Rico',
		continent: 'América Central',
	},
	{
		code: '351',
		value: 'pt',
		en_US: 'Portugal',
		pt_BR: 'Portugal',
		continent: 'Europa',
	},
	{
		code: '974',
		value: 'qa',
		en_US: 'Qatar',
		pt_BR: 'Qatar',
		continent: 'Ásia',
	},
	{
		code: '254',
		value: 'ke',
		en_US: 'Kenya',
		pt_BR: 'Quênia',
		continent: 'África',
	},
	{
		code: '996',
		value: 'kg',
		en_US: 'Kyrgyzstan',
		pt_BR: 'Quirguistão',
		continent: 'Ásia',
	},
	{
		code: '44',
		value: 'gb',
		en_US: 'United Kingdom',
		pt_BR: 'Reino Unido',
		continent: 'Europa',
	},
	{
		code: '44',
		value: 'gb sct',
		en_US: 'Scotland',
		pt_BR: 'Escócia',
		continent: 'Europa',
	},
	{
		code: '44',
		value: 'gb wls',
		en_US: 'Wales',
		pt_BR: 'País de Gales',
		continent: 'Europa',
	},
	{
		code: '236',
		value: 'cf',
		en_US: 'Central African Republic',
		pt_BR: 'República Centro-Africana',
		continent: 'África',
	},
	{
		code: '1',
		value: 'do',
		en_US: 'Dominican Republic',
		pt_BR: 'República Dominicana',
		continent: 'América Central',
	},
	{
		code: '420',
		value: 'cz',
		en_US: 'Czech Republic',
		pt_BR: 'República Tcheca',
		continent: 'Europa',
	},
	{
		code: '262',
		value: 're',
		en_US: 'Reunion',
		pt_BR: 'Reunião',
		continent: 'África',
	},
	{
		code: '40',
		value: 'ro',
		en_US: 'Romania',
		pt_BR: 'Romênia',
		continent: 'Europa',
	},
	{
		code: '250',
		value: 'rw',
		en_US: 'Rwanda',
		pt_BR: 'Ruanda',
		continent: 'África',
	},
	{
		code: '7',
		value: 'ru',
		en_US: 'Russia',
		pt_BR: 'Rússia',
		continent: 'Europa/Ásia',
	},
	{
		code: '212',
		value: 'eh',
		en_US: 'Western Sahara',
		pt_BR: 'Saara Ocidental',
		continent: 'África',
	},
	{
		code: '685',
		value: 'ws',
		en_US: 'Samoa',
		pt_BR: 'Samoa',
		continent: 'Oceania',
	},
	{
		code: '1',
		value: 'as',
		en_US: 'American Samoa',
		pt_BR: 'Samoa Americana',
		continent: 'Oceania',
	},
	{
		code: '290',
		value: 'sh',
		en_US: 'Saint Helena',
		pt_BR: 'Santa Helena',
		continent: 'África',
	},
	{
		code: '1',
		value: 'lc',
		en_US: 'Saint Lucia',
		pt_BR: 'Santa Lúcia',
		continent: 'América Central',
	},
	{
		code: '1',
		value: 'kn',
		en_US: 'Saint Kitts and Nevis',
		pt_BR: 'São Cristóvão e Nevis',
		continent: 'América Central',
	},
	{
		code: '378',
		value: 'sm',
		en_US: 'San Marino',
		pt_BR: 'São Marinho',
		continent: 'Europa',
	},
	{
		code: '508',
		value: 'pm',
		en_US: 'Saint Pierre',
		pt_BR: 'Saint-Pierre e Miquelon',
		continent: 'América do Norte',
	},
	{
		code: '239',
		value: 'st',
		en_US: 'Sao Tome',
		pt_BR: 'São Tomé e Príncipe',
		continent: 'África',
	},
	{
		code: '1',
		value: 'vc',
		en_US: 'Saint Vincent',
		pt_BR: 'São Vicente e Granadinas',
		continent: 'América Central',
	},
	{
		code: '248',
		value: 'sc',
		en_US: 'Seychelles',
		pt_BR: 'Seicheles',
		continent: 'África',
	},
	{
		code: '221',
		value: 'sn',
		en_US: 'Senegal',
		pt_BR: 'Senegal',
		continent: 'África',
	},
	{
		code: '232',
		value: 'sl',
		en_US: 'Sierra Leone',
		pt_BR: 'Serra Leoa',
		continent: 'África',
	},
	{
		code: '381',
		value: 'rs',
		en_US: 'Serbia',
		pt_BR: 'Sérvia',
		continent: 'Europa',
	},
	{
		code: '65',
		value: 'sg',
		en_US: 'Singapore',
		pt_BR: 'Singapura',
		continent: 'Ásia',
	},
	{
		code: '963',
		value: 'sy',
		en_US: 'Syria',
		pt_BR: 'Síria',
		continent: 'Ásia',
	},
	{
		code: '252',
		value: 'so',
		en_US: 'Somalia',
		pt_BR: 'Somália',
		continent: 'África',
	},
	{
		code: '94',
		value: 'lk',
		en_US: 'Sri Lanka',
		pt_BR: 'Sri Lanka',
		continent: 'Ásia',
	},
	{
		code: '268',
		value: 'sz',
		en_US: 'Swaziland',
		pt_BR: 'Suazilândia',
		continent: 'África',
	},
	{
		code: '249',
		value: 'sd',
		en_US: 'Sudan',
		pt_BR: 'Sudão',
		continent: 'África',
	},
	{
		code: '46',
		value: 'se',
		en_US: 'Sweden',
		pt_BR: 'Suécia',
		continent: 'Europa',
	},
	{
		code: '41',
		value: 'ch',
		en_US: 'Switzerland',
		pt_BR: 'Suíça',
		continent: 'Europa',
	},
	{
		code: '597',
		value: 'sr',
		en_US: 'Suriname',
		pt_BR: 'Suriname',
		continent: 'América do Sul',
	},
	{
		code: '992',
		value: 'tj',
		en_US: 'Tajikistan',
		pt_BR: 'Tadjiquistão',
		continent: 'Ásia',
	},
	{
		code: '66',
		value: 'th',
		en_US: 'Thailand',
		pt_BR: 'Tailândia',
		continent: 'Ásia',
	},
	{
		code: '886',
		value: 'tw',
		en_US: 'Taiwan',
		pt_BR: 'Taiwan',
		continent: 'Ásia',
	},
	{
		code: '255',
		value: 'tz',
		en_US: 'Tanzania',
		pt_BR: 'Tanzânia',
		continent: 'África',
	},
	{
		code: '246',
		value: 'io',
		en_US: 'Indian Ocean Territory',
		pt_BR: 'Território Britânico do Oceano Índico',
		continent: 'África',
	},
	{
		code: '670',
		value: 'tl',
		en_US: 'Timorleste',
		pt_BR: 'Timor-Leste',
		continent: 'Ásia',
	},
	{
		code: '228',
		value: 'tg',
		en_US: 'Togo',
		pt_BR: 'Togo',
		continent: 'África',
	},
	{
		code: '690',
		value: 'tk',
		en_US: 'Tokelau',
		pt_BR: 'Tokelau',
		continent: 'Oceania',
	},
	{
		code: '676',
		value: 'to',
		en_US: 'Tonga',
		pt_BR: 'Tonga',
		continent: 'Oceania',
	},
	{
		code: '1',
		value: 'tt',
		en_US: 'Trinidad',
		pt_BR: 'Trinidad e Tobago',
		continent: 'América Central',
	},
	{
		code: '216',
		value: 'tn',
		en_US: 'Tunisia',
		pt_BR: 'Tunísia',
		continent: 'África',
	},
	{
		code: '1',
		value: 'tc',
		en_US: 'Caicos Islands',
		pt_BR: 'Turcas e Caicos',
		continent: 'América Central',
	},
	{
		code: '993',
		value: 'tm',
		en_US: 'Turkmenistan',
		pt_BR: 'Turquemenistão',
		continent: 'Ásia',
	},
	{
		code: '90',
		value: 'tr',
		en_US: 'Turkey',
		pt_BR: 'Turquia',
		continent: 'Ásia//Europa',
	},
	{
		code: '688',
		value: 'tv',
		en_US: 'Tuvalu',
		pt_BR: 'Tuvalu',
		continent: 'Oceania',
	},
	{
		code: '380',
		value: 'ua',
		en_US: 'Ukraine',
		pt_BR: 'Ucrânia',
		continent: 'Europa',
	},
	{
		code: '256',
		value: 'ug',
		en_US: 'Uganda',
		pt_BR: 'Uganda',
		continent: 'África',
	},
	{
		code: '598',
		value: 'uy',
		en_US: 'Uruguay',
		pt_BR: 'Uruguai',
		continent: 'América do Sul',
	},
	{
		code: '998',
		value: 'uz',
		en_US: 'Uzbekistan',
		pt_BR: 'Uzbequistão',
		continent: 'Ásia',
	},
	{
		code: '678',
		value: 'vu',
		en_US: 'Vanuatu',
		pt_BR: 'Vanuatu',
		continent: 'Oceania',
	},
	{
		code: '379',
		value: 'va',
		en_US: 'Vatican City',
		pt_BR: 'Vaticano',
		continent: 'Europa',
	},
	{
		code: '58',
		value: 've',
		en_US: 'Venezuela',
		pt_BR: 'Venezuela',
		continent: 'América do Sul',
	},
	{
		code: '84',
		value: 'vn',
		en_US: 'Vietnam',
		pt_BR: 'Vietnã',
		continent: 'Ásia',
	},
	{
		code: '681',
		value: 'wf',
		en_US: 'Wallis and Futuna',
		pt_BR: 'Wallis e Futuna',
		continent: 'Oceania',
	},
	{
		code: '260',
		value: 'zm',
		en_US: 'Zambia',
		pt_BR: 'Zâmbia',
		continent: 'África',
	},
	{
		code: '263',
		value: 'zw',
		en_US: 'Zimbabwe',
		pt_BR: 'Zimbábue',
		continent: 'África',
	},
	{
		code: '246',
		value: 'um',
		en_US: 'US Minor Islands',
		pt_BR: 'Ilhas Menores dos Estados Unidos',
		continent: '',
	},
	{
		code: '64',
		value: 'pn',
		en_US: 'Pitcairn Islands',
		pt_BR: 'Ilhas Pitcairn',
		continent: '',
	},
	{
		code: '500',
		value: 'gs',
		en_US: 'Sandwich Islands',
		pt_BR: 'Ilhas Sandwich',
		continent: '',
	},
];
