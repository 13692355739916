import React from 'react';

import { Segment, Image } from 'semantic-ui-react';
import { useHeadSafe } from 'unhead';

const paralax = {
	backgroundImage: 'url("/img/pe/goalkeeper.jpg")',
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover',
	height: '88vh',
	padding: '150px',
};

const NotFound = () => {
	useHeadSafe({
		title: 'Página não encontrada',
	});

	return (
		<Segment basic inverted style={paralax}>
			<p style={{ fontSize: '10rem' }}>
				4
				<Image
					src="\img\symbols\soccer.svg"
					spaced
					style={{ height: '10rem' }}
				/>
				4
			</p>
			<p style={{ fontSize: '3rem' }}>
				Desculpe, esta página não foi encontrada.
			</p>
		</Segment>
	);
};

export default NotFound;
