import {
	PayloadAction,
	SerializedError,
	createAsyncThunk,
	createSlice,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { MessageProps } from 'semantic-ui-react';

import { api } from '../../api';
import checkValidity from '../../util/valida/check';
import { Permission, Profile } from '../access';
import { authRefresh } from '../auth';
import { Gender } from '../people';
import { Sport } from '../sports';

export type EnrollType =
	| 'challenged'
	| 'challenger'
	| 'registration'
	| 'self_registration';

export type Availablility = 'dawn' | 'morn' | 'noon' | 'night';

export type ScheduleType = {
	[day: number]: Availablility[];
};

export type EditItem<EditType> = {
	value: EditType;
	changed: boolean;
	error?: boolean;
};

export type EnrollChangeItem<ChangeType> = {
	key: 'title' | 'descr' | 'logo';
	value: ChangeType;
};

export type EnrollEdit = {
	id?: string;
	enroll: Permission; // If the enrollment is linked to a 'tourn' | 'rank' | 'aloc'
	item: string; // The id of the 'tourn' | 'rank' | 'aloc' that is this enrollment is linked to
	sport: Sport;
	title?: EditItem<string | undefined>;
	descr?: EditItem<string | undefined>;
	logo?: EditItem<string | undefined>;
	available?: EditItem<ScheduleType | undefined>;
	type: EnrollType;
	active?: boolean;
};

export type Points = {
	points?: number;
};

export type Status = {
	new?: boolean;
	status?:
		| 'send'
		| 'loading'
		| 'changed'
		| 'error'
		| 'pending_confirmation'
		| 'pending_payment'
		| 'confirmed';
};

export type Balance = {
	mail?: string;
	balance?: number;
};

export type Loading = {
	loading?: boolean;
};

export type Sort = 'title' | 'points';
export type Order = 'ascending' | 'descending';
export type SortBy = {
	by: Sort;
	order: Order;
};

export type Participant = {
	enroll?: string;
	mail: string;
	name: string;
	photo?: string;
	profile: Profile;
	gender?: Gender;
	birth?: string | number;
	phone?: string;
	active?: boolean;
};

export type Enrollment = {
	id: string;
	item: string;
	enroll: Permission;
	title?: string;
	descr?: string;
	logo?: string;
	available?: ScheduleType;
	type: EnrollType;
	sport: Sport;
	team?: Participants;
	active?: boolean;
};

export type Enrollments = (Enrollment & Points & Loading)[];

export type Participants = (Participant & Status)[];

interface EnrollmentsState {
	enrollments: Enrollments;
	participants: Participants;
	sorted: Enrollments;
	sort: SortBy;
	editing?: EnrollEdit;
	send: boolean;
	status?: 'success' | 'fail';
	loading: boolean;
	error?: SerializedError;
	message?: MessageProps;
}

const initialSort: SortBy = {
	by: 'title',
	order: 'ascending',
};

const initialState: EnrollmentsState = {
	enrollments: [],
	participants: [],
	sorted: [],
	sort: initialSort,
	send: false,
	loading: false,
};

export const enrollmentSlice = createSlice({
	name: 'enrollments',
	initialState: initialState,
	reducers: {
		editEnrollment: (
			state,
			{ payload }: PayloadAction<{ id?: string } & Omit<Enrollment, 'id'>>
		) => {
			state.editing = {
				id: payload.id,
				enroll: payload.enroll,
				item: payload.item,
				logo: {
					changed: false,
					value: payload.logo,
				},
				title: {
					changed: false,
					value: payload.title ?? '',
				},
				descr: {
					changed: false,
					value: payload.descr ?? '',
				},
				available: {
					changed: false,
					value: payload.available ?? {},
				},
				type: payload.type,
				sport: payload.sport,
				active: payload.active ?? true,
			};
			state.participants = (payload.team ?? []).map((participant) => ({
				...participant,
				new: false,
			}));
		},
		changeEnrollment: (
			state,
			{ payload }: PayloadAction<EnrollChangeItem<string>>
		) => {
			state.editing = {
				...(state.editing as EnrollEdit),
				[payload.key]: {
					value: payload.value,
					changed: payload.value !== state.editing?.[payload.key]?.value,
				},
			};
		},
		changeAvailability: (state, { payload }: PayloadAction<ScheduleType>) => {
			if (state.editing) {
				state.editing = {
					...state.editing,
					available: {
						value: Object.entries({
							...state.editing?.available?.value,
							...payload,
						}).reduce(
							(obj, v) => ({
								...obj,
								...(v[1].length > 0 ? { [v[0]]: v[1] } : {}),
							}),
							{}
						),
						changed: true,
					},
				};
			}
		},
		addParticipant: (
			state,
			{ payload }: PayloadAction<Partial<Participant>>
		) => {
			if (state.participants.every((part) => !!part.mail)) {
				const errors = participantError(payload);

				state.participants = state.participants.concat({
					enroll: payload.enroll,
					mail: payload.mail ?? '',
					name: payload.name ?? '',
					phone: payload.phone,
					gender: payload.gender,
					birth: payload.birth,
					photo: payload.photo,
					profile: payload.profile ?? 'player',
					active: payload.active ?? true,
					status: errors ? 'error' : 'changed',
					new: true,
				});
			}
		},
		changeParticipant: (
			state,
			{
				payload,
			}: PayloadAction<{
				mail: string;
				field: keyof Participant;
				value: string;
			}>
		) => {
			const cantHaveSameMail =
				payload.field === 'mail' &&
				(state.participants.some((part) => part.mail === payload.value) ||
					state.enrollments.some((enroll) =>
						enroll.team?.some((part) => part.mail === payload.value)
					));

			if (cantHaveSameMail) {
				state.message = {
					header: 'Não permitido',
					content:
						'Nao é possível inscrever o mesmo participante mais de uma vez.',
					error: true,
				};
			} else {
				state.participants = [
					...state.participants.map((participant) => {
						if (participant.mail === payload.mail) {
							const newPart = {
								...participant,
								[payload.field]:
									payload.field === 'mail'
										? payload.value
												.trim()
												.toLowerCase()
												.normalize('NFD')
												.replace(/[\u0300-\u036f]/g, '')
										: payload.value,
							};

							const errors = participantError(newPart);

							return {
								...newPart,
								status: errors ? 'error' : 'changed',
							} as Participant & Status;
						} else return participant;
					}),
				];
			}
		},
		setParticipantStatus: (
			state,
			{ payload }: PayloadAction<{ mail: string; status: Status['status'] }>
		) => {
			state.participants = state.participants.map((participant) =>
				participant.mail === payload.mail
					? { ...participant, status: payload.status }
					: participant
			);
		},
		setParticipations: (state, { payload }: PayloadAction<Participant[]>) => {
			state.participants = payload ?? [];
		},
		removeParticipant: (state, { payload }: PayloadAction<string>) => {
			state.participants = state.participants.filter(
				(participant) => participant.mail !== payload
			);
		},
		filterEnrollments: (
			state,
			{ payload }: PayloadAction<string | undefined>
		) => {
			state.sorted = state.enrollments.filter((enroll) =>
				enroll.title?.toLowerCase().includes((payload ?? '').toLowerCase())
			);
		},
		setSortOrder: (state, { payload }: PayloadAction<Sort>) => {
			state.sort = {
				by: payload,
				order:
					state.sort.by === payload
						? state.sort.order === 'ascending'
							? 'descending'
							: 'ascending'
						: payload === 'points'
							? 'descending'
							: 'ascending',
			};
		},
		sortEnrollments: (state, { payload }: PayloadAction<SortBy>) => {
			state.sorted = orderEnrollments(state.enrollments, payload);
		},
		setEnrollBalance: (state, { payload }: PayloadAction<Balance[]>) => {
			const updatedEnrollments = state.enrollments.map((enroll) => ({
				...enroll,
				points: calcBalance(enroll, payload),
			}));

			state.enrollments = updatedEnrollments;
			state.sorted = orderEnrollments(updatedEnrollments, state.sort);
		},
		setEnrollments: (state, { payload }: PayloadAction<Enrollment[]>) => {
			state.enrollments = payload ?? [];
		},
		setSend: (state) => {
			state.send = true;
		},
		setEnrollStatus: (
			state,
			{ payload }: PayloadAction<EnrollmentsState['status']>
		) => {
			state.status = payload;
		},
		setMessage: (state, { payload }: PayloadAction<MessageProps>) => {
			state.message = payload;
		},
		resetSort: (state) => {
			state.sorted = state.enrollments;
			state.sort = initialSort;
		},
		resetMessage: (state) => {
			state.message = undefined;
		},
		resetParticipants: (state) => {
			state.participants = [];
		},
		resetEnroll: (state) => {
			state.participants = [];
			state.editing = undefined;
			state.status = undefined;
			state.error = undefined;
		},
		resetEnrollments: (state) => {
			state.enrollments = [];
			state.sorted = [];
			state.message = undefined;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getEnrollments.fulfilled, (state, { payload }) => {
				state.enrollments = payload ?? [];
				state.sorted = orderEnrollments(payload ?? [], state.sort);
				state.loading = false;
				state.error = undefined;
			})
			.addCase(getEnrollments.pending, (state) => {
				state.editing = undefined;
				state.loading = true;
				state.error = undefined;
			})
			.addCase(getEnrollments.rejected, (state, { payload }) => {
				state.error = payload;
				state.loading = false;
				state.message = {
					header: 'Falha ao buscar inscrições',
					content: payload?.message,
					error: true,
				};
			});
		builder
			.addCase(insertEnrollment.fulfilled, (state, { payload }) => {
				const updatedEnrollments = state.enrollments.some(
					(enroll) => enroll.id === payload.id
				)
					? state.enrollments.map((enroll) =>
							enroll.id === payload.id ? payload : enroll
						)
					: state.enrollments.concat(payload);

				state.enrollments = updatedEnrollments;
				state.sorted = orderEnrollments(updatedEnrollments, state.sort);
				state.send = false;
				state.status = 'success';
				state.loading = false;
				state.error = undefined;
				state.message = {
					header: 'Salvo',
					content: 'A inscrição foi salva com sucesso.',
					success: true,
				};
			})
			.addCase(insertEnrollment.pending, (state) => {
				state.status = undefined;
				state.loading = true;
				state.error = undefined;
			})
			.addCase(insertEnrollment.rejected, (state, { payload }) => {
				state.error = payload;
				state.send = false;
				state.status = 'fail';
				state.loading = false;
				state.message = {
					header: 'Falha ao salvar inscrição',
					content: payload?.message,
					error: true,
				};
			});
		builder
			.addCase(getEnrollment.fulfilled, (state, { payload }) => {
				const updatedEnrollments = state.enrollments.some(
					(enroll) => enroll.id === payload.id
				)
					? state.enrollments.map((enroll) =>
							enroll.id === payload.id
								? {
										...payload,
										team: state.participants ?? [],
									}
								: enroll
						)
					: state.enrollments.concat({
							...payload,
							team: [],
						});

				state.enrollments = updatedEnrollments;
				state.sorted = orderEnrollments(updatedEnrollments, state.sort);
				state.loading = false;
			})
			.addCase(getEnrollment.pending, (state) => {
				state.loading = true;
				state.error = undefined;
			})
			.addCase(getEnrollment.rejected, (state, action) => {
				state.error = action.error;
				state.loading = false;
			});
		builder
			.addCase(switchEnrollment.fulfilled, (state, { payload }) => {
				const updatedEnrollments = state.enrollments.map((enroll) =>
					enroll.id === payload?.id
						? {
								...payload,
								team: state.participants ?? [],
							}
						: enroll
				);

				state.enrollments = updatedEnrollments;
				state.sorted = orderEnrollments(updatedEnrollments, state.sort);
				state.loading = false;
				state.error = undefined;
			})
			.addCase(switchEnrollment.pending, (state, action) => {
				state.sorted = state.sorted.map((enroll) =>
					enroll.id === action.meta.arg.id
						? {
								...enroll,
								loading: true,
							}
						: enroll
				);
				state.error = undefined;
			})
			.addCase(switchEnrollment.rejected, (state, { payload, meta }) => {
				state.sorted = state.sorted.map((enroll) =>
					enroll.id === meta.arg.id
						? {
								...enroll,
								loading: false,
							}
						: enroll
				);
				state.error = payload;
			});
		builder
			.addCase(getParticipants.fulfilled, (state, { payload }) => {
				state.participants = payload ?? [];
				state.loading = false;
				state.error = undefined;
			})
			.addCase(getParticipants.pending, (state) => {
				state.loading = true;
				state.error = undefined;
			})
			.addCase(getParticipants.rejected, (state, { payload }) => {
				state.loading = false;
				state.error = payload;
			});
		builder
			.addCase(insertParticipant.fulfilled, (state, { payload }) => {
				const updatedEnrollments = updateEnrollmentTeam(
					state.enrollments,
					payload
				);

				state.enrollments = updatedEnrollments;
				state.sorted = orderEnrollments(updatedEnrollments, state.sort);
				state.participants = updateParticipant(state.participants, payload);
			})
			.addCase(insertParticipant.pending, (state, action) => {
				state.participants = state.participants.map((participant) =>
					participant.enroll === action.meta.arg.participant.enroll &&
					participant.mail === action.meta.arg.participant.mail
						? { ...participant, status: 'loading' }
						: participant
				);
			})
			.addCase(insertParticipant.rejected, (state, { payload, meta }) => {
				state.participants = state.participants.map((participant) =>
					participant.enroll === meta.arg.participant.enroll &&
					participant.mail === meta.arg.participant.mail
						? { ...participant, status: 'error' }
						: participant
				);
				state.error = payload;
			});
		builder
			.addCase(switchParticipant.fulfilled, (state, { payload }) => {
				const updatedEnrollments = updateEnrollmentTeam(
					state.enrollments,
					payload
				);

				state.enrollments = updatedEnrollments;
				state.sorted = orderEnrollments(updatedEnrollments, state.sort);
				state.participants = updateParticipant(state.participants, payload);
			})
			.addCase(switchParticipant.pending, (state, action) => {
				state.participants = state.participants.map((participant) =>
					participant.enroll === action.meta.arg.enroll &&
					participant.mail === action.meta.arg.mail
						? { ...participant, status: 'loading' }
						: participant
				);
			})
			.addCase(switchParticipant.rejected, (state, { payload, meta }) => {
				state.participants = state.participants.map((participant) =>
					participant.enroll === meta.arg.enroll &&
					participant.mail === meta.arg.mail
						? { ...participant, status: 'error' }
						: participant
				);
				state.error = payload;
			});
	},
});

const participantError = (participant: Partial<Participant>) => {
	return (
		!participant.name ||
		!participant.mail ||
		!participant.gender ||
		!participant.birth ||
		Object.keys(participant).reduce(
			(errors, field) =>
				errors || checkValidity(field, participant[field as keyof Participant]),
			false
		)
	);
};

const calcBalance = (enroll: Enrollment, balances: Balance[]) =>
	enroll?.team?.reduce(
		(total: number, player) =>
			total +
			(balances.find((balance) => balance.mail === player.mail)?.balance ?? 0),
		0
	);

const orderEnrollments = (enrollments: Enrollments, sort: SortBy) =>
	enrollments.sort((a, b) => {
		if (!a[sort.by] && !b[sort.by]) return 0;
		if (!a[sort.by]) return 1;
		if (!b[sort.by]) return -1;

		return (a[sort.by] ?? (sort.by === 'title' ? '' : 0)) >
			(b[sort.by] ?? (sort.by === 'title' ? '' : 0))
			? sort.order === 'ascending'
				? 1
				: -1
			: sort.order === 'descending'
				? 1
				: -1;
	});

const updateEnrollmentTeam = (
	enrollments: (Enrollment & Points & Loading)[],
	payload: Participant
) =>
	enrollments.map((enrollment) =>
		enrollment.id === payload.enroll
			? {
					...enrollment,
					team: [
						...(enrollment.team?.filter(
							(player) => player.mail !== payload.mail
						) ?? []),
						payload,
					],
				}
			: enrollment
	);

const updateParticipant = (
	participants: (Participant & Status)[],
	payload: Participant
) =>
	participants.map((participant) =>
		participant.enroll === payload.enroll && participant.mail === payload.mail
			? {
					...payload,
					status: undefined,
					new: false,
				}
			: participant
	);

export const getEnrollments = createAsyncThunk<
	Enrollment[],
	{
		enroll: Permission;
		item: string;
	},
	{ rejectValue: SerializedError }
>(
	'enrollments/getEnrollments',
	async ({ enroll, item }, { rejectWithValue, dispatch }) => {
		try {
			await dispatch(authRefresh());

			const { data } = await api.enrollments.get(enroll, item);

			return data;
		} catch (error) {
			return rejectWithValue(
				(error as AxiosError).response?.data as SerializedError
			);
		}
	}
);

export const insertEnrollment = createAsyncThunk<
	Enrollment,
	Partial<Enrollment>,
	{ rejectValue: SerializedError }
>(
	'enrollments/insertEnrollment',
	async (enrollment, { rejectWithValue, dispatch }) => {
		try {
			await dispatch(authRefresh());

			const { data } = await api.enrollments.post(enrollment);

			dispatch(editEnrollment(data));

			return data;
		} catch (error) {
			return rejectWithValue(
				(error as AxiosError).response?.data as SerializedError
			);
		} finally {
			setTimeout(() => {
				dispatch(resetMessage());
			}, 10000);
		}
	}
);

export const getEnrollment = createAsyncThunk<
	Enrollment,
	{
		enroll: Permission;
		item: string;
		id: string;
	},
	{ rejectValue: SerializedError }
>(
	'enrollments/getEnrollment',
	async ({ enroll, item, id }, { rejectWithValue, dispatch }) => {
		try {
			await dispatch(authRefresh());

			const { data } = await api.enrollments.id(enroll, item, id);
			dispatch(editEnrollment(data));

			return data;
		} catch (error) {
			return rejectWithValue(
				(error as AxiosError).response?.data as SerializedError
			);
		}
	}
);

export const switchEnrollment = createAsyncThunk<
	Enrollment,
	Pick<Enrollment, 'id'> & Partial<Enrollment>,
	{ rejectValue: SerializedError }
>(
	'enrollments/switchEnrollment',
	async (enrollment, { rejectWithValue, dispatch }) => {
		try {
			await dispatch(authRefresh());

			const { data } = await api.enrollments.switch(enrollment);

			return data;
		} catch (error) {
			return rejectWithValue(
				(error as AxiosError).response?.data as SerializedError
			);
		}
	}
);

export const getParticipants = createAsyncThunk<
	Participant[],
	{ item: string; enroll: Permission },
	{ rejectValue: SerializedError }
>(
	'enrollments/getParticipants',
	async ({ item, enroll }, { rejectWithValue, dispatch }) => {
		try {
			await dispatch(authRefresh());

			const { data } = await api.participations.get(item, enroll);

			return data;
		} catch (error) {
			return rejectWithValue(
				(error as AxiosError).response?.data as SerializedError
			);
		}
	}
);

export const insertParticipant = createAsyncThunk<
	Participant,
	{ participant: Partial<Participant>; item: string; enroll: Permission },
	{ rejectValue: SerializedError }
>(
	'enrollments/insertParticipant',
	async ({ participant, item, enroll }, { rejectWithValue, dispatch }) => {
		try {
			await dispatch(authRefresh());

			const { data } = await api.participations.post(item, enroll, participant);

			return data;
		} catch (error) {
			return rejectWithValue(
				(error as AxiosError).response?.data as SerializedError
			);
		}
	}
);

export const switchParticipant = createAsyncThunk<
	Participant,
	{
		enroll: string;
		mail: Participant['mail'];
		item: string;
		permission: Permission;
		confirm?: Extract<
			Status['status'],
			'confirmed' | 'pending_confirmation' | 'pending_payment'
		>;
		active?: Participant['active'];
	},
	{ rejectValue: SerializedError }
>(
	'enrollments/switchParticipant',
	async (
		{ item, enroll, permission, mail, confirm, active },
		{ rejectWithValue, dispatch }
	) => {
		try {
			await dispatch(authRefresh());

			const { data } = await api.participations.switch(
				item,
				permission,
				enroll,
				mail,
				confirm,
				!active
			);

			return data;
		} catch (error) {
			return rejectWithValue(
				(error as AxiosError).response?.data as SerializedError
			);
		}
	}
);

export const {
	editEnrollment,
	changeEnrollment,
	changeAvailability,
	addParticipant,
	changeParticipant,
	setParticipantStatus,
	setParticipations,
	removeParticipant,
	filterEnrollments,
	setSortOrder,
	sortEnrollments,
	setEnrollBalance,
	setEnrollments,
	setSend,
	setEnrollStatus,
	setMessage,
	resetSort,
	resetMessage,
	resetParticipants,
	resetEnroll,
	resetEnrollments,
} = enrollmentSlice.actions;

export default enrollmentSlice.reducer;
