import React from 'react';

import {
	faBrazilianRealSign,
	faDollarSign,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const currencies = {
	brl: faBrazilianRealSign,
	usd: faDollarSign,
};

export type Currency = keyof typeof currencies;

export const defaultCurrency: Currency = 'brl';

interface ICurrency {
	currency?: Currency;
}

const CurrencyIcon: React.FC<ICurrency> = ({ currency }) => {
	return <FontAwesomeIcon icon={currencies[currency ?? defaultCurrency]} />;
};

export default CurrencyIcon;
