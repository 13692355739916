import {
	PayloadAction,
	SerializedError,
	createAsyncThunk,
	createSlice,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import cep, { CEP } from 'cep-promise';
import { FlagNameValues, MessageProps } from 'semantic-ui-react';

import { api } from '../../api';
import checkValidity from '../../util/valida/check';
import { authRefresh } from '../auth';
import { setEnrollments, setParticipations } from '../enrollments';
import { Category } from '../leagues';
import { setMatches } from '../matches';
import { setRankings } from '../rankings';
import { setTournaments } from '../tournaments';

export type Gender = 'male' | 'female' | 'all';

export type Sort = 'name' | 'birth';
export type Order = 'ascending' | 'descending';
export type SortBy = {
	by: Sort;
	order: Order;
};

type Filters = {
	search: string;
	gender: Gender[];
	ages: [number, number];
	categories: Category[];
};

export type Address = {
	type: 'res' | 'pro';
	street: string;
	number: string;
	compl?: string;
	neighbour?: string;
	city: string;
	state: string;
	country: FlagNameValues;
	code: string;
};

export type EditAddress = {
	type: EditItem<Address['type']>;
	street: EditItem<Address['street']>;
	number: EditItem<Address['number']>;
	compl: EditItem<Address['compl']>;
	neighbour: EditItem<Address['neighbour']>;
	city: EditItem<Address['city']>;
	state: EditItem<Address['state']>;
	country: EditItem<Address['country']>;
	code: EditItem<Address['code']>;
	loading?: boolean;
	error?: SerializedError;
};

export type Phone = {
	type: 'cel' | 'res' | 'pro';
	country: FlagNameValues;
	phone: string;
};

export type EditPhone = {
	type: EditItem<Phone['type']>;
	country: EditItem<Phone['country']>;
	phone: EditItem<Phone['phone']>;
};

export type Person = {
	org: string;
	mail: string;
	name?: string;
	nick?: string;
	birth?: string;
	gender?: Gender;
	photo?: string;
	associate?: string;
	adresses?: Address[];
	phones?: Phone[];
	categories?: Category[];
	active?: boolean;
};

export type EditItem<EditType> = {
	value: EditType;
	changed: boolean;
	error?: boolean;
};

export type EditPerson = {
	org: string;
	mail: EditItem<Person['mail']>;
	name: EditItem<Person['name']>;
	nick: EditItem<Person['nick']>;
	birth: EditItem<Person['birth']>;
	gender: EditItem<Person['gender']>;
	photo: EditItem<Person['photo']>;
	associate: EditItem<Person['associate']>;
	adresses: EditAddress[];
	phones: EditPhone[];
	categories?: EditItem<Person['categories']>;
	active?: boolean;
};

export type People = Person[];

interface PeopleState {
	people: People;
	filtered: People;
	filters: Filters;
	sort: SortBy;
	person?: EditPerson;
	loading: boolean;
	send?: boolean;
	error?: SerializedError;
	message?: MessageProps;
}

const initialSort: SortBy = {
	by: 'name',
	order: 'ascending',
};

const initialFilters: Filters = {
	search: '',
	ages: [0, 100],
	gender: ['male', 'female'],
	categories: [],
};

const initialState: PeopleState = {
	people: [],
	filtered: [],
	filters: initialFilters,
	sort: initialSort,
	loading: false,
	send: false,
	error: undefined,
};

export const peopleSlice = createSlice({
	name: 'people',
	initialState: initialState,
	reducers: {
		editPerson: (state, { payload }: PayloadAction<Person>) => {
			state.person = formatEditPerson(payload);
		},
		changePerson: (
			state,
			{
				payload: { field, value },
			}: PayloadAction<{
				field: keyof Person;
				value: string;
			}>
		) => {
			const newVal =
				field === 'mail'
					? value
							.trim()
							.toLowerCase()
							.normalize('NFD')
							.replace(/[\u0300-\u036f]/g, '')
					: value;

			if (state.person) {
				state.person = {
					...state.person,
					[field]: {
						value: newVal,
						changed:
							(state.person[field] as EditItem<unknown>).value !== newVal,
						error: checkValidity(field, value),
					},
				};
			}
		},
		addPersonAddress: (state) => {
			if (state.person) {
				state.person = {
					...state.person,
					adresses: [
						{
							type: {
								value: 'res',
								changed: true,
								error: false,
							},
							code: {
								value: '',
								changed: true,
								error: true,
							},
							street: {
								value: '',
								changed: true,
								error: true,
							},
							number: {
								value: '',
								changed: true,
								error: true,
							},
							compl: {
								value: '',
								changed: true,
								error: false,
							},
							neighbour: {
								value: '',
								changed: true,
								error: true,
							},
							city: {
								value: '',
								changed: true,
								error: true,
							},
							state: {
								value: '',
								changed: true,
								error: true,
							},
							country: {
								value: 'br',
								changed: true,
								error: false,
							},
						},
						...state.person.adresses,
					],
				};
			}
		},
		changePersonAddress: (
			state,
			{
				payload: { index, field, value },
			}: PayloadAction<{
				index: number;
				field: keyof Address;
				value: string;
			}>
		) => {
			if (state.person) {
				state.person = {
					...state.person,
					adresses: state.person.adresses.map((address, i) =>
						i === index
							? {
									...address,
									[field]: {
										value: value,
										changed:
											(address[field] as EditItem<unknown>).value !== value,
										error: checkValidity(field, value),
									},
								}
							: address
					),
				};
			}
		},
		removePersonAddress: (state, { payload }: PayloadAction<number>) => {
			if (state.person) {
				state.person = {
					...state.person,
					adresses: state.person.adresses.filter((a, i) => i !== payload),
				};
			}
		},
		addPersonPhone: (state) => {
			if (state.person) {
				state.person = {
					...state.person,
					phones: [
						{
							type: {
								value: 'cel',
								changed: true,
								error: false,
							},
							phone: {
								value: '',
								changed: true,
								error: true,
							},
							country: {
								value: 'br',
								changed: true,
								error: false,
							},
						},
						...state.person.phones,
					],
				};
			}
		},
		changePersonPhone: (
			state,
			{
				payload: { index, field, value },
			}: PayloadAction<{
				index: number;
				field: keyof Phone;
				value: string;
			}>
		) => {
			if (state.person) {
				state.person = {
					...state.person,
					phones: state.person.phones.map((phone, i) =>
						i === index
							? {
									...phone,
									[field]: {
										value: value,
										changed:
											(phone[field] as EditItem<unknown>).value !== value,
										error: checkValidity(field, value),
									},
								}
							: phone
					),
				};
			}
		},
		removePersonPhone: (state, { payload }: PayloadAction<number>) => {
			if (state.person) {
				state.person = {
					...state.person,
					phones: state.person.phones.filter((p, i) => i !== payload),
				};
			}
		},
		addPersonCategory: (state, { payload }: PayloadAction<Category>) => {
			if (state.person) {
				state.person = {
					...state.person,
					categories: {
						value: (state.person.categories?.value ?? []).concat(payload),
						changed: true,
						error: false,
					},
				};
			}
		},
		removePersonCategory: (state, { payload }: PayloadAction<Category>) => {
			if (state.person) {
				state.person = {
					...state.person,
					categories: {
						value: (state.person.categories?.value ?? []).filter(
							(category) =>
								category.league !== payload.league ||
								category.name !== payload.name ||
								category.gender !== payload.gender ||
								category.sport !== payload.sport
						),
						changed: true,
						error: false,
					},
				};
			}
		},
		setPeopleOrder: (state, { payload }: PayloadAction<Sort>) => {
			state.sort = {
				by: payload,
				order:
					state.sort.by === payload && state.sort.order === 'ascending'
						? 'descending'
						: 'ascending',
			};
		},
		setPeopleFilters: (state, { payload }: PayloadAction<Partial<Filters>>) => {
			state.filters = {
				...state.filters,
				...payload,
			};
		},
		filterPeople: (
			state,
			{ payload }: PayloadAction<{ filters: Filters; sort: SortBy }>
		) => {
			state.filtered = applyOrder(
				applyFilters(state.people, payload.filters),
				payload.sort
			);
		},
		setSend: (state, { payload }: PayloadAction<boolean>) => {
			state.send = payload;
		},
		setMessage: (state, { payload }: PayloadAction<MessageProps>) => {
			state.message = payload;
		},
		resetMessage: (state) => {
			state.message = undefined;
		},
		resetOrder: (state) => {
			state.sort = initialSort;
			state.filtered = applyOrder(state.people, initialSort);
		},
		resetFilters: (state) => {
			state.filters = initialFilters;
			state.filtered = applyFilters(state.people, initialFilters);
		},
		resetPerson: (state) => {
			state.person = undefined;
			state.send = false;
		},
		resetPeople: (state) => {
			state.people = [];
			state.filtered = [];
			state.message = undefined;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(
				getPeople.fulfilled,
				(state, { payload }: PayloadAction<People>) => {
					const updatedPeople = [
						...state.people.filter(
							(person) => !payload.some((p) => p.mail === person.mail)
						),
						...payload,
					];

					state.people = updatedPeople;
					state.filtered = applyOrder(
						applyFilters(updatedPeople, state.filters),
						state.sort
					);
					state.loading = false;
					state.error = undefined;
				}
			)
			.addCase(getPeople.pending, (state) => {
				state.loading = true;
				state.error = undefined;
			})
			.addCase(getPeople.rejected, (state, { payload }) => {
				state.error = payload;
				state.loading = false;
				state.message = {
					header: 'Falha ao buscar pessoas',
					content: payload?.message,
					error: true,
				};
			});
		builder
			.addCase(getPerson.fulfilled, (state, { payload }) => {
				state.person = payload ? formatEditPerson(payload) : undefined;
				state.loading = false;
				state.error = undefined;
			})
			.addCase(getPerson.pending, (state) => {
				state.loading = true;
				state.error = undefined;
			})
			.addCase(getPerson.rejected, (state, { payload }) => {
				state.error = payload;
				state.loading = false;
			});
		builder
			.addCase(getAddressByCep.fulfilled, (state, { meta }) => {
				if (state.person) {
					state.person = {
						...state.person,
						adresses: state.person.adresses.map((add, i) =>
							i === meta.arg.index
								? {
										...add,
										loading: false,
									}
								: add
						),
					};
				}
			})
			.addCase(getAddressByCep.pending, (state, { meta }) => {
				if (state.person) {
					state.person = {
						...state.person,
						adresses: state.person?.adresses.map((add, i) =>
							i === meta.arg.index
								? {
										...add,
										loading: true,
									}
								: add
						),
					};
				}
			})
			.addCase(getAddressByCep.rejected, (state, { meta, payload }) => {
				if (state.person) {
					state.person = {
						...state.person,
						adresses: state.person.adresses.map((add, i) =>
							i === meta.arg.index
								? {
										...add,
										loading: false,
										payload,
									}
								: add
						),
					};
					state.message = {
						header: 'Falha ao buscar CEP',
						content: payload?.message,
						error: true,
					};
				}
			});
		builder
			.addCase(insertPerson.fulfilled, (state, { payload }) => {
				const updatedPeople = state.people
					.filter((person) => person.mail !== payload.mail)
					.concat(payload);

				state.person = formatEditPerson(payload);
				state.people = updatedPeople;
				state.filtered = applyOrder(
					applyFilters(updatedPeople, state.filters),
					state.sort
				);
				state.loading = false;
				state.send = false;
				state.error = undefined;
				state.message = {
					header: 'Salvo',
					content: 'O cadastro foi salvo com sucesso.',
					success: true,
				};
			})
			.addCase(insertPerson.pending, (state) => {
				state.loading = true;
				state.error = undefined;
			})
			.addCase(insertPerson.rejected, (state, { payload }) => {
				state.error = payload;
				state.loading = false;
				state.message = {
					header: 'Falha ao salvar inscrição',
					content: payload?.message,
					error: true,
				};
			});
	},
});

const formatEditPerson = (person: Person) =>
	({
		org: person.org,
		mail: {
			value: person.mail ?? '',
			changed: false,
			error: !person.mail,
		},
		name: {
			value: person.name ?? '',
			changed: false,
			error: !person.name,
		},
		nick: {
			value: person.nick ?? '',
			changed: false,
			error: false,
		},
		gender: {
			value: person.gender ?? undefined,
			changed: false,
			error: !person.gender,
		},
		birth: {
			value: person.birth ?? undefined,
			changed: false,
			error: !person.birth,
		},
		associate: {
			value: person.associate ?? '',
			changed: false,
			error: false,
		},
		photo: {
			value: person.photo ?? '',
			changed: false,
			error: false,
		},
		phones: (person.phones ?? []).map((phone) => ({
			country: {
				value: phone.country ?? 'br',
				changed: false,
				error: false,
			},
			phone: {
				value: phone.phone ?? '',
				changed: false,
				error: !phone.phone,
			},
			type: {
				value: phone.type ?? 'res',
				changed: false,
				error: false,
			},
		})),
		adresses: (person.adresses ?? [])?.map((add) => ({
			street: {
				value: add.street ?? '',
				changed: false,
				error: !add.street,
			},
			number: {
				value: add.number ?? '',
				changed: false,
				error: !add.number,
			},
			compl: {
				value: add.compl ?? '',
				changed: false,
				error: false,
			},
			code: {
				value: add.code ?? '',
				changed: false,
				error: !add.code,
			},
			neighbour: {
				value: add.neighbour ?? '',
				changed: false,
				error: !add.neighbour,
			},
			city: {
				value: add.city ?? '',
				changed: false,
				error: !add.city,
			},
			state: {
				value: add.state ?? '',
				changed: false,
				error: !add.state,
			},
			country: {
				value: add.country ?? 'br',
				changed: false,
				error: false,
			},
			type: {
				value: add.type ?? 'res',
				changed: false,
				error: false,
			},
		})),
		categories: {
			value: person.categories,
			changed: false,
			error: false,
		},
	}) as EditPerson;

const applyOrder = (people: People, sort: SortBy) =>
	people.sort((a, b) => {
		if (!a[sort.by] && !b[sort.by]) return 0;
		if (!a[sort.by]) return sort.order === 'descending' ? 1 : -1;
		if (!b[sort.by]) return sort.order === 'ascending' ? 1 : -1;

		const value = (person: Person) => {
			if (sort.by === 'birth') return new Date(person['birth'] ?? 0).getTime();
			return person[sort.by] ?? '';
		};

		return value(a) > value(b)
			? sort.order === 'ascending'
				? 1
				: -1
			: sort.order === 'descending'
				? 1
				: -1;
	});

const applyFilters = (people: People, filters: Partial<Filters>) =>
	people.filter((person) => {
		const searchFilter = (search?: Filters['search']) => {
			if (!search) return true;
			if (person.name?.toLowerCase()?.includes(search.toLowerCase()))
				return true;
			if (person.mail.toLowerCase().includes(search.toLowerCase())) return true;
			if (person.nick?.toLowerCase()?.includes(search.toLowerCase()))
				return true;
			if (person.associate?.includes(search)) return true;

			return false;
		};

		const genderFilter = (gender?: Filters['gender']) => {
			if (gender?.includes(person.gender ?? 'all')) return true;

			return false;
		};

		const ageFilter = (ages?: Filters['ages']) => {
			const personAge = Math.abs(
				new Date(
					person.birth
						? new Date().getTime() -
							new Date(
								new Date(person.birth).getTime() +
									new Date().getTimezoneOffset() * 60 * 1000
							).getTime()
						: new Date(0)
				).getUTCFullYear() - 1970
			);

			if ((ages?.[0] ?? 0) <= personAge && (ages?.[1] ?? 100) >= personAge)
				return true;

			return false;
		};

		const catFilter = (categories?: Filters['categories']) => {
			if (!categories?.length) return true;

			if (!person.categories?.length) return false;

			if (
				person.categories.some((categ) =>
					categories.some(
						(cat) =>
							(cat.id && cat.id === categ.id) ||
							(cat.name === categ.name &&
								cat.gender === categ.gender &&
								cat.sport === categ.sport &&
								cat.league === categ.league)
					)
				)
			)
				return true;

			return false;
		};

		return (
			searchFilter(filters.search) &&
			genderFilter(filters.gender) &&
			ageFilter(filters.ages) &&
			catFilter(filters.categories)
		);

		// return (
		// 	!(
		// 	person.categories?.some(
		// 		(category) =>
		// 			!filters.categories ||
		// 			!(filters.categories.length > 0) ||
		// 			filters.categories.some(
		// 				(cat) =>
		// 					(!!cat.id && cat.id === category.id) ||
		// 					(category.name === cat.name &&
		// 						category.gender === cat.gender &&
		// 						category.sport === cat.sport &&
		// 						category.league === cat.league)
		// 			)
		// 	))
		// );
	});

export const getPeople = createAsyncThunk<
	Person[],
	{ unit: string } & { name?: string },
	{ rejectValue: SerializedError }
>('people/getPeople', async ({ unit, name }, { rejectWithValue, dispatch }) => {
	try {
		await dispatch(authRefresh());

		const { data } = await api.people.get(unit, name);

		return data;
	} catch (error) {
		return rejectWithValue(
			(error as AxiosError).response?.data as SerializedError
		);
	}
});

export const getPerson = createAsyncThunk<
	Person | undefined,
	{ org: string; mail: string; unit: string },
	{ rejectValue: SerializedError }
>(
	'people/getPerson',
	async ({ org, mail, unit }, { rejectWithValue, dispatch }) => {
		try {
			await dispatch(authRefresh());

			const { data } = await api.person.get(org, mail, unit);
			const {
				participations,
				enrollments,
				tournaments,
				rankings,
				matches,
				...person
			} = data;

			if (!data) return undefined;

			if (participations) dispatch(setParticipations(participations));
			if (enrollments) dispatch(setEnrollments(enrollments));
			if (tournaments) dispatch(setTournaments(tournaments));
			if (rankings) dispatch(setRankings(rankings));
			if (matches) dispatch(setMatches(matches));

			return person;
		} catch (error) {
			return rejectWithValue(
				(error as AxiosError).response?.data as SerializedError
			);
		}
	}
);

export const getAddressByCep = createAsyncThunk<
	CEP,
	{ index: number; value: string },
	{ rejectValue: SerializedError }
>(
	'people/getAddress',
	async ({ index, value }, { dispatch, rejectWithValue }) => {
		try {
			const address = await cep(value);

			dispatch(
				changePersonAddress({ index, field: 'street', value: address.street })
			);
			dispatch(
				changePersonAddress({
					index,
					field: 'neighbour',
					value: address.neighborhood,
				})
			);
			dispatch(
				changePersonAddress({ index, field: 'city', value: address.city })
			);
			dispatch(
				changePersonAddress({ index, field: 'state', value: address.state })
			);

			return address;
		} catch (error) {
			return rejectWithValue(
				(error as AxiosError).response?.data as SerializedError
			);
		} finally {
			setTimeout(() => {
				dispatch(resetMessage());
			}, 10000);
		}
	}
);

export const insertPerson = createAsyncThunk<
	Person,
	Person,
	{ rejectValue: SerializedError }
>('people/insertPerson', async (person, { rejectWithValue, dispatch }) => {
	try {
		await dispatch(authRefresh());

		const { data } = await api.person.post(person);

		return data;
	} catch (error) {
		return rejectWithValue(
			(error as AxiosError).response?.data as SerializedError
		);
	} finally {
		setTimeout(() => {
			dispatch(resetMessage());
		}, 10000);
	}
});

export const {
	editPerson,
	changePerson,
	addPersonAddress,
	changePersonAddress,
	removePersonAddress,
	addPersonPhone,
	changePersonPhone,
	removePersonPhone,
	addPersonCategory,
	removePersonCategory,
	setPeopleFilters,
	setPeopleOrder,
	filterPeople,
	setSend,
	setMessage,
	resetMessage,
	resetOrder,
	resetFilters,
	resetPerson,
	resetPeople,
} = peopleSlice.actions;

export default peopleSlice.reducer;
