import React from 'react';

const Dots: React.FC<{ title?: string }> = ({ title }) => {
	return (
		<div
			style={{
				alignItems: 'center',
				display: 'flex',
				flexFlow: 'column nowrap',
				justifyContent: 'center',
				width: '100%',
				fontSize: '1.6rem',
				fontWeight: 'bolder',
				gap: '16px',
			}}
		>
			{title}
			<div className="dots-loader" />
		</div>
	);
};

export default Dots;
