import React from 'react';

import { Image, Modal } from 'semantic-ui-react';

interface iLoader {
	open: boolean;
	images: string[];
}

const Loader: React.FC<iLoader> = ({ open, images }) => (
	<Modal open={open} basic centered mountNode={document.body}>
		<Modal.Content image>
			{images.map((image) => (
				<Image key={image} src={image} centered size="medium" />
			))}
		</Modal.Content>
	</Modal>
);

export default Loader;
