import {
	createAsyncThunk,
	createSlice,
	// PayloadAction,
	SerializedError,
} from '@reduxjs/toolkit';
import { SemanticCOLORS } from 'semantic-ui-react';

import { api } from '../../api';
import { authRefresh } from '../auth';

export type Entity = 'club' | 'acad' | 'cond' | 'prof';

export type Item = {
	limit: number;
	value: number;
	billing: 'monthly' | 'yearly' | 'unlimited';
};

export type Plan = {
	group: string;
	plan: Entity;
	expiration: number;
	name: string;
	description: string;
	color: SemanticCOLORS;
	places?: Item;
	people?: Item;
	events?: Item;
	leagues?: Item;
	price: Pick<Item, 'value' | 'billing'>;
	active: boolean;
};

interface PlanState {
	plans: Plan[];
	loading: boolean;
	error?: SerializedError;
}

const initialState: PlanState = {
	plans: [],
	loading: false,
};

export const planSlice = createSlice({
	name: 'plans',
	initialState: initialState,
	reducers: {
		resetPlans: (state) => {
			state.plans = [];
			state.loading = false;
			state.error = undefined;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getPlans.fulfilled, (state, { payload }) => {
				state.plans = payload.sort((a, b) =>
					a.price.value > b.price.value ? 1 : -1
				);
				state.loading = false;
				state.error = undefined;
			})
			.addCase(getPlans.pending, (state) => {
				state.loading = true;
			})
			.addCase(getPlans.rejected, (state, { error }) => {
				state.error = error;
				state.loading = false;
			});
	},
});

export const getPlans = createAsyncThunk(
	'plans/getPlans',
	async (_, { dispatch, rejectWithValue }) => {
		try {
			await dispatch(authRefresh());

			const { data } = await api.plans.get();

			return data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const { resetPlans } = planSlice.actions;

export default planSlice.reducer;
