import React, { useState, useEffect } from 'react';

type Easing = 'constant' | 'linear' | 'quadratic';

type CounterProps = {
	initial: number;
	target: number;
	easing: Easing;
};

const duration = 1000;

const calcEasing = {
	constant: (duration: number, range: number) => duration / range, // No easing
	linear: (duration: number, range: number, current: number) =>
		((duration * 2) / Math.pow(range, 2)) * current, // Linear easing
	quadratic: (duration: number, range: number, current: number) =>
		((duration * 3) / Math.pow(range, 3)) * Math.pow(current, 2), // Quadratic easing
};

const Counter: React.FC<CounterProps> = ({ initial, target, easing }) => {
	const [count, setCount] = useState(initial);

	useEffect(() => {
		if (count < target) {
			const counter = setTimeout(
				() => setCount(count + (target > initial ? 1 : -1)),
				calcEasing[easing](duration, target - initial, count)
			);
			return () => clearInterval(counter);
		}
	}, [count, easing, initial, target]);

	return <>{Math.round(count)}</>;
};

export default Counter;
