import {
	SerializedError,
	createAsyncThunk,
	createSlice,
	PayloadAction,
} from '@reduxjs/toolkit';
import { SemanticCOLORS } from 'semantic-ui-react';

import { api } from '../../api';
import { authRefresh } from '../auth';
import { Court, Unit } from '../organizations';
import { Sport } from '../sports';

export type Hour = {
	court: string;
	/** Slot initial time */
	time: number;
	/** Slot size in time */
	slot: number;
	/** Next slot time */
	next: number;
	/** Closed slot */
	closed: boolean;
	/** True if it is the slot time now */
	actual: boolean;
} & Partial<Allocation>;

export type Day = Hour[];

export type Schedule = Day[];

type Loading = {
	loading?: boolean;
};

type Error = {
	error?: SerializedError;
};

export type Allocation = {
	/** Id = [day]#[start] */
	id?: string;
	/** Court Id */
	court: Court['id'];
	/** Day of week [0 | 1 | 2 | 3 | 4 | 5 | 6] */
	day: number;
	/** Start time [00:00] */
	start: string;
	/** Destination of the slot */
	dest: string;
	/** Color to be displayed */
	color: SemanticCOLORS;
	/** Sport beeing used */
	sport: Sport;
	active?: boolean;
};

interface AllocationState {
	allocations: (Allocation & Loading & Error)[];
	schedule?: Schedule;
}

const initialState: AllocationState & Loading & Error = {
	allocations: [],
	loading: false,
	error: undefined,
};

export const allocationSlice = createSlice({
	name: 'allocations',
	initialState: initialState,
	reducers: {
		createSchedule: (
			state,
			{
				payload: { week, court, unit },
			}: PayloadAction<{
				week: string[];
				court: Court;
				unit: Unit;
			}>
		) => {
			state.schedule = formatSchedule(
				week.map((day) => new Date(day)),
				court,
				unit
			);
		},
		resetAllocations: (state) => {
			state.allocations = [];
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(insertAllocations.fulfilled, (state, { payload }) => {
				state.allocations = [
					...state.allocations.filter(
						(a) =>
							!payload.some(
								(p) =>
									p.court === a.court && p.day === a.day && p.start === a.start
							)
					),
					...payload,
				];
			})
			.addCase(insertAllocations.pending, (state, { meta }) => {
				state.allocations = [
					...state.allocations.filter(
						(allocation) =>
							!meta.arg.some(
								(arg) =>
									arg.court === allocation.court &&
									arg.day === allocation.day &&
									arg.start === allocation.start
							)
					),
					...meta.arg.map((arg) => ({
						...arg,
						loading: true,
					})),
				];
			})
			.addCase(insertAllocations.rejected, (state, { error }) => {
				state.allocations = state.allocations.map((allocation) => ({
					...allocation,
					loading: false,
					error,
				}));
			});
		builder
			.addCase(getAllocations.fulfilled, (state, { payload }) => {
				state.allocations = payload;
				state.loading = false;
				state.error = undefined;
			})
			.addCase(getAllocations.pending, (state) => {
				state.loading = true;
				state.error = undefined;
			})
			.addCase(getAllocations.rejected, (state, { error }) => {
				state.loading = false;
				state.error = error;
			});
		builder
			.addCase(removeAllocation.fulfilled, (state, { payload }) => {
				state.allocations = state.allocations.map((allocation) =>
					allocation.court === payload.court &&
					allocation.day === payload.day &&
					allocation.start === payload.start
						? payload
						: allocation
				);
			})
			.addCase(removeAllocation.pending, (state, { meta }) => {
				state.allocations = state.allocations.map((allocation) =>
					meta.arg.court === allocation.court &&
					meta.arg.day === allocation.day &&
					meta.arg.start === allocation.start
						? {
								...allocation,
								loading: true,
							}
						: allocation
				);
			})
			.addCase(removeAllocation.rejected, (state, { error }) => {
				state.allocations = state.allocations.map((allocation) => ({
					...allocation,
					loading: false,
				}));
				state.error = error;
				state.loading = false;
			});
	},
});

const formatSchedule = (
	week: Date[],
	// allocations: Allocation[],
	court: Court,
	unit: Unit
): Schedule => {
	const setHour = (day: Date, hour: number, minutes?: number) =>
		new Date(day).setHours(hour, minutes ?? 0, 0, 0);

	const minHour = Math.min(
		...(court?.hours?.opening?.some((time) => !!time?.hour)
			? (court?.hours?.opening?.reduce(
					(hours: number[], time) => [
						...hours,
						...(time?.hour ? [time.hour] : []),
					],
					[]
				) ?? [0])
			: [0])
	);
	const maxHour = Math.max(
		...(court?.hours?.closure?.some((time) => !!time?.hour)
			? (court?.hours?.closure?.reduce(
					(hours: number[], time) => [
						...hours,
						...(time?.hour ? [time.hour] : []),
					],
					[]
				) ?? [0])
			: [0])
	);

	return week?.map((day, index) => {
		let grid: Day = [];

		if (!court || !unit) return grid;

		const hasOpening = !!court.hours?.opening?.[index];
		const opening = setHour(
			day,
			court.hours?.opening?.[index]?.hour ?? 0,
			court.hours?.opening?.[index]?.minute
		);

		const hasClosure = !!court.hours?.closure?.[index];
		const closure = setHour(
			day,
			court.hours?.closure?.[index]?.hour ?? 24,
			court.hours?.closure?.[index]?.minute
		);

		for (
			let time = setHour(day, minHour);
			time < setHour(day, maxHour);
			time = time + new Date(0).setMinutes(unit.slot ?? 60)
		) {
			// const allocation = allocations.find(
			// 	(alloc) =>
			// 		alloc.court === court.id &&
			// 		alloc.day === new Date(time).getDay() &&
			// 		alloc.start ===
			// 			new Date(time).toLocaleTimeString(undefined, {
			// 				hour: '2-digit',
			// 				minute: '2-digit',
			// 			}) &&
			// 		alloc.active !== false
			// );

			grid = [
				...grid,
				{
					court: court.id,
					time: time,
					slot: unit.slot ?? 60,
					next: time + new Date(0).setMinutes(unit.slot ?? 60),
					closed:
						!hasOpening || !hasClosure || time < opening || time >= closure,
					actual:
						new Date() >= new Date(time) &&
						new Date() <
							new Date(time + new Date(0).setMinutes(unit.slot ?? 60)),
					// sport: allocation?.sport,
					// color: allocation?.color,
					// dest: allocation?.dest,
				},
			];
		}

		return grid;
	});
};

export const insertAllocations = createAsyncThunk(
	'allocations/insertAllocations',
	async (allocations: Allocation[], { dispatch, rejectWithValue }) => {
		try {
			await dispatch(authRefresh());

			const { data } = await api.allocations.post(allocations);

			return data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getAllocations = createAsyncThunk(
	'allocations/getAllocations',
	async ({ unit }: { unit: string }, { dispatch, rejectWithValue }) => {
		try {
			await dispatch(authRefresh());

			const { data } = await api.allocations.get(unit);

			return data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const removeAllocation = createAsyncThunk(
	'allocations/removeAllocation',
	async (
		{ court, day, start }: { court: string; day: number; start: string },
		{ dispatch, rejectWithValue }
	) => {
		try {
			await dispatch(authRefresh());

			const { data } = await api.allocations.delete(court, day, start);

			return data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const { createSchedule, resetAllocations } = allocationSlice.actions;

export default allocationSlice.reducer;
