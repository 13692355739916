import React from 'react';

import { useHistory } from 'react-router-dom';
import { Grid, List, Image, Header } from 'semantic-ui-react';

const Institucional = () => {
	const history = useHistory();

	return (
		<Grid
			stackable
			divided
			centered
			doubling
			columns="equal"
			style={{
				backgroundImage: 'url("/img/pe/fake_brick.png")',
				paddingTop: '10px',
				paddingBottom: '15px',
			}}
		>
			<Grid.Row>
				<Grid.Column
					mobile={16}
					computer={5}
					largeScreen={5}
					textAlign="center"
				>
					<Image src="img/logo.png" centered />
					<p
						style={{
							color: 'white',
						}}
					>
						Queremos te ver jogando!
					</p>
				</Grid.Column>
				<Grid.Column textAlign="center">
					<Header
						as="h4"
						inverted
						style={{
							textTransform: 'uppercase',
							fontStyle: 'italic',
							fontWeight: 'bolder',
							paddingTop: '10px',
						}}
						content="Institucional"
					/>
					<List link inverted>
						<List.Item as="a" onClick={() => history.push('/about')}>
							Sobre nós
						</List.Item>
						<List.Item as="a" onClick={() => history.push('/terms')}>
							Termos de uso
						</List.Item>
						<List.Item as="a" onClick={() => history.push('/policy')}>
							Política de privacidade
						</List.Item>
					</List>
				</Grid.Column>
				<Grid.Column textAlign="center">
					<Header
						as="h4"
						inverted
						style={{
							textTransform: 'uppercase',
							fontStyle: 'italic',
							fontWeight: 'bolder',
							paddingTop: '10px',
						}}
						content="Plataforma"
					/>
					<List link inverted>
						<List.Item as="a" onClick={() => history.push('/signup')}>
							Cadastre-se
						</List.Item>
						{/* <List.Item as="a">Onde jogar</List.Item> */}
						<List.Item as="a">Como funciona</List.Item>
						<List.Item as="a" onClick={() => history.push('/organizations')}>
							Cadastre sua organização
						</List.Item>
						{/* <List.Item as="a">Termos de anúncio</List.Item> */}
					</List>
				</Grid.Column>
				<Grid.Column textAlign="center">
					<Header
						as="h4"
						inverted
						style={{
							textTransform: 'uppercase',
							fontStyle: 'italic',
							fontWeight: 'bolder',
							paddingTop: '10px',
						}}
						content="Contato"
					/>
					<List link inverted>
						<List.Item as="a" data-openwidget-action="maximize">
							Fale conosco
						</List.Item>
					</List>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);
};

export default Institucional;
