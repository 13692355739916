import { validaCNPJ } from './cnpj';
import { validaCPF } from './cpf';

const checkCategories = (value: string | number | true | string[]) =>
	typeof value === 'string' &&
	!['club', 'acad', 'cond', 'pers'].includes(value);

const checkDates = (value: string | number | true | string[]) =>
	(typeof value === 'string' || typeof value === 'number') &&
	!(new Date(value) instanceof Date);

const checkGenders = (value: string | number | true | string[]) =>
	typeof value === 'string' && !['male', 'female', 'all'].includes(value);

const checkPhones = (value: string | number | true | string[]) =>
	typeof value === 'string' &&
	(value.length === 0 || value.replace(/\+|\(|\)|_|-| /g, '').length <= 10);

const checkMails = (value: string | number | true | string[]) =>
	typeof value === 'string' &&
	!/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
		value
	);

const checkNames = (value: string | number | true | string[]) =>
	typeof value === 'string' &&
	!/^\s*([A-ZÀ-Úa-zà-ú0-9"]{1,}([.,] |[-']| ))+[A-ZÀ-Úa-zà-ú0-9"]+\.?\s*$/gim.test(
		value
	);

const checkNumbers = (value: string | number | true | string[]) =>
	typeof value === 'string' && !/^[0-9]*$/gim.test(value);

const checkLogos = (value: string | number | true | string[]) =>
	typeof value === 'string' &&
	value.length !== 0 &&
	!/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/.test(
		value
	);

const checkPasswords = (value: string | number | true | string[]) =>
	typeof value === 'string' &&
	!/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(value);

const checkValidity = (
	field: string,
	value?: string | string[] | number | boolean
) => {
	switch (field) {
		case 'name':
			return !value || checkNames(value);
		case 'title':
			return !value;
		case 'number':
			return !value || checkNumbers(value);
		case 'points':
			return !value || checkNumbers(value);
		case 'mail':
			return !value || checkMails(value);
		case 'email':
			return !value || checkMails(value);
		case 'phone':
			return !value || checkPhones(value);
		case 'phone_number':
			return !value || checkPhones(value);
		case 'password':
			return !value || checkPasswords(value);
		case 'birth':
			return !value || checkDates(value);
		case 'birthdate':
			return !value || checkDates(value);
		case 'entry':
			return !value || checkDates(value);
		case 'begin':
			return !value || checkDates(value);
		case 'gender':
			return !value || checkGenders(value);
		case 'cnpj':
			return !(typeof value === 'string' && validaCNPJ(value));
		case 'cpf':
			return !(typeof value === 'string' && validaCPF(value));
		case 'policy':
			return !value;
		case 'category':
			return !value || checkCategories(value);
		case 'logo':
			return !value || checkLogos(value);
		case 'sports':
			return !value || (Array.isArray(value) && value.length === 0);
		case 'sign':
			return !value || (typeof value === 'string' && value.length === 0);
		case 'edition':
			return !value || (typeof value === 'string' && value.length === 0);
		case 'verification':
			return !value || (typeof value === 'string' && value.length === 0);
		case 'schedule':
			return !Array.isArray(value);
		default:
			return false;
	}
};

export default checkValidity;
