import React from 'react';

import { Route, Switch } from 'react-router-dom';

import Footer from './footer';
import Institucional from './institucional';

interface Props {
	institucionalRoutes?: string[];
}

const Index: React.FC<Props> = ({ institucionalRoutes }) => {
	return (
		<Switch>
			{institucionalRoutes?.map((path) => (
				<Route key={path} path={path} component={Institucional} exact />
			))}
			<Route component={Footer} />
		</Switch>
	);
};

export default Index;
