import React from 'react';

import { useHistory } from 'react-router-dom';
import { Icon, Menu, SemanticWIDTHS } from 'semantic-ui-react';

import { AppRoute } from '../../app';

interface MobileMenuProps {
	bottomMenuItems: AppRoute[];
}

const BottomMenu: React.FC<MobileMenuProps> = ({ bottomMenuItems }) => {
	const history = useHistory();

	if (!bottomMenuItems.length) return null;

	return (
		<Menu
			icon="labeled"
			widths={bottomMenuItems.length as SemanticWIDTHS}
			size="tiny"
			style={{
				position: 'fixed' /* Set the navbar to fixed position */,
				bottom: 0 /* Position the navbar at the top of the page */,
				zIndex: 1000,
			}}
		>
			{bottomMenuItems.map((item) => (
				<Menu.Item
					key={item.name}
					onClick={() => {
						if (item.link) history.push(item.link);
					}}
				>
					<Icon name={item.icon} size="tiny" />
					{item.name}
				</Menu.Item>
			))}
		</Menu>
	);
};

export default BottomMenu;
