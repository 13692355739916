import React from 'react';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { InferSeoMetaPlugin } from '@unhead/addons';
import dayjs from 'dayjs';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import 'semantic-ui-css/semantic.min.css';
import './index.css';
import 'dayjs/locale/pt-br';
import { createHead } from 'unhead';

import App from './app';
import registerServiceWorker from './registerServiceWorker';
import store from './store';

console.info(
	`Ponto do Esporte is running${!process.env.REACT_APP_ENV && process.env.NODE_ENV === 'production' ? '...' : ` in ${process.env.REACT_APP_ENV ?? process.env.NODE_ENV}`}.`
);

const root = createRoot(document.getElementById('root') as HTMLElement);
dayjs.locale('pt-br');

// Create a global head instance
createHead({
	plugins: [InferSeoMetaPlugin()],
});

root.render(
	// stabilityCheck para remover aviso
	<Provider store={store} stabilityCheck="never">
		<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
			<App />
		</LocalizationProvider>
	</Provider>
);

registerServiceWorker();
