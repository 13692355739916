import React, { useEffect } from 'react';

import { Header, Image, Card, Loader, Grid } from 'semantic-ui-react';

import { useScreenSize } from '../../hooks';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { RightItem } from '../../store/rights';
import { resetStats } from '../../store/statistics';
import Unit from '../dashboard/unit';
import ErrorBoundary from '../errors/boundary';

interface iPlace {
	perms?: RightItem[];
}

const PlacesIndex: React.FC<iPlace> = ({ perms }) => {
	const screenSize = useScreenSize();
	const dispatch = useAppDispatch();

	const { rights, stats, loading } = useAppSelector((state) => ({
		rights: state.rgt.rights,
		stats: state.stt.stats,
		loading: state.rgt.loading,
	}));

	const places = (perms ?? []).reduce((places, unit) => {
		const place = rights.find((r) => unit.super === r.id);

		return [
			...places,
			...(place && !places.some((p) => p.id === place.id) ? [place] : []),
		];
	}, [] as RightItem[]);

	useEffect(
		() => () => {
			dispatch(resetStats());
		},
		[dispatch]
	);

	return (
		<ErrorBoundary>
			<Loader active={loading} />
			{places.map((place) => (
				<Grid key={place?.id} padded>
					<Grid.Row>
						<Grid.Column>
							<Header dividing>
								{place.img && <Image src={place.img} size="mini" />}
								<Header.Content>{place?.name}</Header.Content>
							</Header>
						</Grid.Column>
					</Grid.Row>

					<Grid.Row>
						<Grid.Column>
							<Card.Group itemsPerRow={screenSize === 'mobile' ? 3 : undefined}>
								{perms?.map((unit) => (
									<Unit
										key={unit.id}
										unit={unit}
										stat={stats?.find((s) => s.id === unit.id)}
										screenSize={screenSize}
									/>
								))}
							</Card.Group>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			))}
		</ErrorBoundary>
	);
};

export default PlacesIndex;
