import {
	PayloadAction,
	SerializedError,
	createAsyncThunk,
	createSlice,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { MessageProps } from 'semantic-ui-react';

import { api } from '../../api';
import checkValidity from '../../util/valida/check';
import { Allowed, Profile } from '../access';
import { authRefresh } from '../auth';
import { EnrollType } from '../enrollments';
import { Gender } from '../people';
import { Sport } from '../sports';

export type EditItem<EditType> = {
	value: EditType;
	changed: boolean;
	error: boolean;
	required?: boolean;
};

export type Period = 'week' | 'month' | 'year';

export type SetFormat = 'percent' | 'points';
export type SetOf = 'self' | 'opponent';
export type SetValue = number;

export type Setup = {
	format: SetFormat;
	value: SetValue;
	of: SetOf;
};

export type ResultSetup = {
	loser: Setup;
	winner: Setup;
	wo: Setup;
};

export type EditResultSetup = {
	loser: EditItem<Setup>;
	winner: EditItem<Setup>;
	wo: EditItem<Setup>;
};

export type Chall = {
	[key in Extract<EnrollType, 'challenged' | 'challenger'>]: ResultSetup;
};

export type EditChall = {
	[key in Extract<EnrollType, 'challenged' | 'challenger'>]: EditResultSetup;
};

export type Rules = {
	[rule in 'win' | 'lost']: { [key in number | 'wo']: number };
};

export type Age = {
	id?: number;
	name: string;
	en_US?: string;
	pt_BR?: string;
	age: number;
	rule: 'up' | 'down';
	sport: Sport;
	active: boolean;
};

export type EditAge = {
	id: Age['id'];
	name: EditItem<Age['name']>;
	en_US: EditItem<Age['en_US']>;
	pt_BR: EditItem<Age['pt_BR']>;
	age: EditItem<Age['age']>;
	rule: EditItem<Age['rule']>;
	sport: EditItem<Age['sport']>;
	active: EditItem<Age['active']>;
};

export type Category = {
	id?: number;
	name: string;
	en_US?: string;
	pt_BR?: string;
	descr?: string;
	gender: Gender;
	sport: Sport;
	league?: string;
	size?: number;
	spare?: number;
	active: boolean;
};

export type EditCategory = {
	id: Category['id'];
	league: Category['league'];
	name: EditItem<Category['name']>;
	en_US: EditItem<Category['en_US']>;
	pt_BR: EditItem<Category['pt_BR']>;
	descr: EditItem<Category['descr']>;
	gender: EditItem<Category['gender']>;
	sport: EditItem<Category['sport']>;
	active: EditItem<Category['active']>;
};

export type Document = 'rules';

export type Documents = {
	[key in Document]?: string;
};

export type League = {
	id: string;
	org: string;
	name?: string;
	descr?: string;
	link?: string;
	poster?: string;
	ages?: Age[];
	cats?: Category[];
	rules?: Rules;
	documents?: Documents;
	visibility?: Extract<Allowed, 'everyone' | 'members'>;
	alteration?: Extract<Allowed, 'players' | 'admins'>;
	created?: string;
	active?: boolean;
};

export type EditLeague = {
	id?: string;
	org: EditItem<League['org']>;
	name: EditItem<League['name']>;
	descr: EditItem<League['descr']>;
	link: EditItem<League['link']>;
	poster: EditItem<League['poster']>;
	cats: EditCategory[];
	ages: EditAge[];
	rules: EditItem<League['rules']>;
	documents?: {
		[key in Document]?: EditItem<Documents[key]>;
	};
	visibility: EditItem<League['visibility']>;
	alteration: EditItem<League['alteration']>;
	active: boolean;
};

export type Ranking = {
	id: string;
	league: string;
	sport?: Sport;
	name?: string;
	descr?: string;
	logo?: string;
	ages?: Age[];
	cats?: Category[];
	chall?: Chall;
	team?: number;
	period?: Period;
	valid?: number;
	unit?: string;
	type?: 'points' | 'position';
	created?: string;
	public?: boolean;
	active?: boolean;
	access?: Profile[];
};

export type EditRank = {
	id?: Ranking['id'];
	league: Ranking['league'];
	name: EditItem<Ranking['name']>;
	descr: EditItem<Ranking['descr']>;
	logo: EditItem<Ranking['logo']>;
	ages: EditItem<Ranking['ages']>;
	cats: EditItem<Ranking['cats']>;
	chall?: EditChall;
	period: EditItem<Ranking['period']>;
	sport: EditItem<Ranking['sport']>;
	team: EditItem<Ranking['team']>;
	valid: EditItem<Ranking['valid']>;
	unit: EditItem<Ranking['unit']>;
	type: EditItem<Ranking['type']>;
	active: boolean;
};

interface LeagueState {
	leagues: League[];
	league?: EditLeague;
	rankings: Ranking[];
	ranking?: EditRank;
	loading: boolean;
	send: boolean;
	error?: SerializedError;
	message?: MessageProps;
}

const initialState: LeagueState = {
	leagues: [],
	rankings: [],
	loading: false,
	send: false,
};

export const leagueSlice = createSlice({
	name: 'leagues',
	initialState: initialState,
	reducers: {
		newLeague: (state) => {
			state.league = formatEditLeague({ org: '', name: '' });
		},
		editLeague: (state, { payload }: PayloadAction<League>) => {
			if (payload) {
				state.league = formatEditLeague(payload);
			}
		},
		changeLeague: (
			state,
			{
				payload: { field, value },
			}: PayloadAction<{
				field: keyof EditLeague;
				value: string;
			}>
		) => {
			if (state.league) {
				state.league = {
					...state.league,
					[field]: {
						...(state.league[field] as EditItem<unknown>),
						value,
						changed: (state.league[field] as EditItem<unknown>).value !== value,
						error:
							checkValidity(field, value) ||
							((state.league[field] as EditItem<unknown>).required && !value),
					},
				};
			}
		},
		changeLeagueDocument: (
			state,
			{ payload }: PayloadAction<{ field: Document; value: string }>
		) => {
			if (state.league) {
				state.league = {
					...state.league,
					documents: {
						...state.league.documents,
						[payload.field]: {
							value: payload.value,
							changed: true,
							error: !payload.value,
						},
					},
				};
			}
		},
		newLeagueCategory: (state) => {
			if (state.league?.id) {
				state.league.cats = state.league.cats.concat(formatEditCategory({}));
			}
		},
		changeLeagueCategory: (
			state,
			{
				payload,
			}: PayloadAction<{
				id: number;
				field: keyof EditCategory;
				value: string | boolean;
			}>
		) => {
			if (state.league) {
				state.league.cats = state.league.cats.map((cat, index) =>
					index === payload.id
						? {
								...cat,
								[payload.field]: {
									...(cat[payload.field] as EditItem<unknown>),
									value: payload.value,
									changed:
										(cat[payload.field] as EditItem<unknown>).value !==
										payload.value,
									error:
										checkValidity(payload.field, payload.value) ||
										((cat[payload.field] as EditItem<unknown>).required &&
											!payload.value),
								},
							}
						: cat
				);
			}
		},
		newLeagueAge: (state) => {
			if (state.league?.id) {
				state.league.ages = state.league.ages.concat(formatEditAge({}));
			}
		},
		changeLeagueAge: (
			state,
			{
				payload,
			}: PayloadAction<{
				id: number;
				field: keyof EditAge;
				value: string | number | boolean;
			}>
		) => {
			if (state.league) {
				state.league.ages = state.league.ages.map((age, index) =>
					index === payload.id
						? {
								...age,
								[payload.field]: {
									...(age[payload.field] as EditItem<unknown>),
									value: payload.value,
									changed:
										(age[payload.field] as EditItem<unknown>).value !==
										payload.value,
									error:
										checkValidity(payload.field, payload.value) ||
										((age[payload.field] as EditItem<unknown>).required &&
											!payload.value),
								},
							}
						: age
				);
			}
		},
		newLeagueRule: (
			state,
			{ payload }: PayloadAction<{ rule: keyof Rules; pos: 'wo' | number }>
		) => {
			if (state.league?.rules.value) {
				state.league.rules.value = {
					...state.league.rules.value,
					[payload.rule]: {
						...state.league.rules.value?.[payload.rule],
						[payload.pos]: 0,
					},
				};
				state.league.rules.changed = true;
			}
		},
		changeLeagueRule: (
			state,
			{
				payload,
			}: PayloadAction<{ rule: keyof Rules; pos: 'wo' | number; value: number }>
		) => {
			if (state.league?.rules.value?.[payload.rule]) {
				state.league.rules.value[payload.rule][payload.pos] = payload.value;
				state.league.rules.changed = true;
			}
		},
		removeLeagueRule: (
			state,
			{ payload }: PayloadAction<{ rule: keyof Rules; pos: 'wo' | number }>
		) => {
			if (state.league?.rules.value) {
				delete state.league.rules.value[payload.rule][payload.pos];
				state.league.rules.changed = true;
			}
		},
		newRanking: (state) => {
			if (state.league?.id)
				state.ranking = formatEditRanking({
					league: state.league.id,
				});
		},
		editRanking: (state, { payload }: PayloadAction<Ranking>) => {
			if (payload) {
				state.ranking = formatEditRanking(payload);
			}
		},
		changeRanking: (
			state,
			{
				payload: { field, value },
			}: PayloadAction<{
				field: keyof EditRank;
				value?: string | number;
			}>
		) => {
			if (state.ranking) {
				state.ranking = {
					...state.ranking,
					[field]: {
						...(state.ranking[field] as EditItem<unknown>),
						value,
						changed:
							(state.ranking[field] as EditItem<unknown>).value !== value,
						error:
							checkValidity(field, value) ||
							((state.ranking[field] as EditItem<unknown>).required && !value),
					},
				};
			}
		},
		changeRankingCats: (state, { payload }: PayloadAction<Category[]>) => {
			if (state.ranking) {
				state.ranking.cats.value = payload.sort(
					(a, b) =>
						a.sport.localeCompare(b.sport) ||
						a.gender.localeCompare(b.gender) ||
						a.name.localeCompare(b.name)
				);
				state.ranking.cats.changed = true;
			}
		},
		changeRankingAges: (state, { payload }: PayloadAction<Age[]>) => {
			if (state.ranking) {
				state.ranking.ages.value = payload.sort(
					(a, b) =>
						a.sport.localeCompare(b.sport) ||
						a.age - b.age ||
						a.rule.localeCompare(b.rule) ||
						a.name.localeCompare(b.name)
				);
			}
		},
		defineRankingChallenge: (state) => {
			if (state.ranking) {
				state.ranking.chall = {
					challenged: {
						loser: {
							value: {
								format: 'points',
								value: 0,
								of: 'self',
							},
							changed: true,
							error: false,
						},
						winner: {
							value: {
								format: 'points',
								value: 0,
								of: 'self',
							},
							changed: true,
							error: false,
						},
						wo: {
							value: {
								format: 'points',
								value: 0,
								of: 'self',
							},
							changed: true,
							error: false,
						},
					},
					challenger: {
						loser: {
							value: {
								format: 'points',
								value: 0,
								of: 'self',
							},
							changed: true,
							error: false,
						},
						winner: {
							value: {
								format: 'points',
								value: 0,
								of: 'self',
							},
							changed: true,
							error: false,
						},
						wo: {
							value: {
								format: 'points',
								value: 0,
								of: 'self',
							},
							changed: true,
							error: false,
						},
					},
				};
			}
		},
		changeRankingChallenge: (
			state,
			{
				payload: { chall, result, setup, value },
			}: PayloadAction<{
				chall: keyof Chall;
				result: keyof ResultSetup;
				setup: keyof Setup;
				value: string | number;
			}>
		) => {
			if (state.ranking?.chall) {
				state.ranking.chall = {
					...state.ranking.chall,
					[chall]: {
						...state.ranking.chall[chall],
						[result]: {
							...state.ranking.chall[chall][result],
							value: {
								...state.ranking.chall[chall][result].value,
								[setup]: value,
							},
							changed: true,
							error: checkChallError({
								...state.ranking.chall[chall][result].value,
								[setup]: value,
							}),
						},
					},
				};
			}
		},
		removeRankingChallenge: (state) => {
			if (state.ranking) {
				state.ranking.chall = undefined;
			}
		},
		setSend: (state) => {
			state.send = true;
		},
		resetMessage: (state) => {
			state.message = undefined;
		},
		resetRanking: (state) => {
			state.ranking = undefined;
			state.send = false;
		},
		resetRankings: (state) => {
			state.rankings = [];
			state.message = undefined;
		},
		resetLeague: (state) => {
			state.league = undefined;
			state.send = false;
		},
		resetLeagues: (state) => {
			state.leagues = [];
			state.message = undefined;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getLeagues.fulfilled, (state, { payload }) => {
				state.leagues = payload ?? [];
				state.loading = false;
				state.error = undefined;
			})
			.addCase(getLeagues.pending, (state) => {
				state.loading = true;
				state.error = undefined;
			})
			.addCase(getLeagues.rejected, (state, { payload }) => {
				state.error = payload;
				state.loading = false;
				state.message = {
					header: 'Falha ao buscar ligas',
					content: payload?.message,
					error: true,
				};
			});
		builder
			.addCase(insertLeague.fulfilled, (state, { payload }) => {
				state.leagues = state.leagues
					.filter((leg) => leg.id !== payload.id)
					.concat(payload);
				state.league = formatEditLeague(payload);
				state.loading = false;
				state.error = undefined;
				state.message = {
					header: 'Salvo',
					content: 'Sua liga foi salva com sucesso.',
					success: true,
				};
			})
			.addCase(insertLeague.pending, (state) => {
				state.loading = true;
				state.send = false;
			})
			.addCase(insertLeague.rejected, (state, { payload }) => {
				state.error = payload;
				state.loading = false;
				state.message = {
					header: 'Falha ao salvar liga',
					content: payload?.message,
					error: true,
				};
			});
		builder
			.addCase(switchLeague.fulfilled, (state, { payload }) => {
				state.leagues = state.leagues.map((league) =>
					league.id === payload.id ? payload : league
				);
				state.loading = false;
				state.error = undefined;
			})
			.addCase(switchLeague.pending, (state) => {
				state.loading = true;
				state.send = false;
			})
			.addCase(switchLeague.rejected, (state, { payload }) => {
				state.error = payload;
				state.loading = false;
				state.message = {
					header: 'Falha ao alterar liga',
					content: payload?.message,
					error: true,
				};
			});
		builder
			.addCase(getRankings.fulfilled, (state, { payload }) => {
				state.rankings = payload ?? [];
				state.loading = false;
				state.error = undefined;
			})
			.addCase(getRankings.pending, (state) => {
				state.loading = true;
				state.error = undefined;
			})
			.addCase(getRankings.rejected, (state, { payload }) => {
				state.error = payload;
				state.loading = false;
				state.message = {
					header: 'Falha ao buscar rankings',
					content: payload?.message,
					error: true,
				};
			});
		builder
			.addCase(insertRanking.fulfilled, (state, { payload }) => {
				state.rankings = state.rankings
					.filter((rank) => rank.id !== payload.id)
					.concat(payload);
				state.ranking = formatEditRanking(payload);
				state.loading = false;
				state.error = undefined;
				state.message = {
					header: 'Salvo',
					content: 'Seu ranking foi salvo com sucesso.',
					success: true,
				};
			})
			.addCase(insertRanking.pending, (state) => {
				state.loading = true;
				state.send = false;
			})
			.addCase(insertRanking.rejected, (state, { payload }) => {
				state.error = payload;
				state.loading = false;
				state.message = {
					header: 'Falha ao salvar ranking',
					content: payload?.message,
					error: true,
				};
			});
		builder
			.addCase(switchRanking.fulfilled, (state, { payload }) => {
				state.rankings = state.rankings.map((rank) =>
					rank.id === payload.id ? payload : rank
				);
				state.loading = false;
				state.error = undefined;
			})
			.addCase(switchRanking.pending, (state) => {
				state.loading = true;
				state.send = false;
			})
			.addCase(switchRanking.rejected, (state, { payload }) => {
				state.error = payload;
				state.loading = false;
				state.message = {
					header: 'Falha ao alterar ranking',
					content: payload?.message,
					error: true,
				};
			});
	},
});

const checkChallError = (value?: Setup) => {
	switch (value?.format) {
		case 'percent':
			if (!value.of) return true;
			if (value.value > 100 || value.value < 0) return true;
			return false;
		case 'points':
			if (!value.value) return true;
			return false;
		default:
			return true;
	}
};

const formatEditCategory = (cat: Partial<Category>) => {
	return {
		id: cat.id,
		name: {
			value: cat.name ?? '',
			changed: false,
			error: checkValidity('name', cat.name),
			required: true,
		},
		en_US: {
			value: cat.en_US,
			changed: false,
			error: false,
			required: false,
		},
		pt_BR: {
			value: cat.pt_BR,
			changed: false,
			error: false,
			required: false,
		},
		descr: {
			value: cat.descr ?? '',
			changed: false,
			error: false,
			required: false,
		},
		gender: {
			value: cat.gender,
			changed: false,
			error: !cat.gender,
			required: true,
		},
		sport: {
			value: cat.sport,
			changed: false,
			error: !cat.sport,
			required: true,
		},
		active: {
			value: cat.active ?? true,
			changed: false,
			error: false,
			required: true,
		},
	} as EditCategory;
};

const formatEditAge = (age: Partial<Age>) => {
	return {
		id: age.id,
		name: {
			value: age.name ?? '',
			changed: false,
			error: checkValidity('name', age.name),
			required: true,
		},
		en_US: {
			value: age.en_US,
			changed: false,
			error: false,
			required: false,
		},
		pt_BR: {
			value: age.pt_BR,
			changed: false,
			error: false,
			required: false,
		},
		age: {
			value: age.age,
			changed: false,
			error: !age.age,
			required: true,
		},
		rule: {
			value: age.rule,
			changed: false,
			error: !age.rule,
			required: true,
		},
		sport: {
			value: age.sport,
			changed: false,
			error: !age.sport,
			required: true,
		},
		active: {
			value: age.active ?? true,
			changed: false,
			error: false,
			required: true,
		},
	} as EditAge;
};

const formatEditLeague = (
	league: Pick<EditLeague, 'id'> &
		Pick<
			League,
			| 'org'
			| 'name'
			| 'descr'
			| 'link'
			| 'poster'
			| 'ages'
			| 'cats'
			| 'rules'
			| 'documents'
			| 'visibility'
			| 'alteration'
			| 'active'
		>
) => {
	return {
		id: league.id,
		org: {
			value: league.org,
			changed: false,
			error: !league.org,
			required: true,
		},
		name: {
			value: league.name ?? '',
			changed: false,
			error: checkValidity('name', league.name),
			required: true,
		},
		descr: {
			value: league.descr,
			changed: false,
			error: false,
			required: false,
		},
		link: {
			value: league.link ?? '',
			changed: false,
			error: false,
			required: false,
		},
		poster: {
			value: league.poster,
			changed: false,
			error: false,
			required: false,
		},
		cats: (league.cats ?? []).map((cat) => formatEditCategory(cat)),
		ages: (league.ages ?? []).map((age) => formatEditAge(age)),
		rules: {
			value: league.rules ?? {},
			changed: false,
			error: false,
			required: false,
		},
		documents: Object.keys(league.documents ?? {}).reduce(
			(docs, doc) => ({
				...docs,
				[doc]: {
					value: league.documents?.[doc as Document],
					changed: false,
					error: false,
				},
			}),
			{}
		),
		visibility: {
			value: league.visibility ?? 'admins',
			changed: !league.visibility,
			error: false,
			required: false,
		},
		alteration: {
			value: league.alteration ?? 'admins',
			changed: !league.alteration,
			error: false,
			required: false,
		},
		active: league.active ?? true,
	} as EditLeague;
};

const formatEditRanking = (
	rank: Pick<EditRank, 'id'> &
		Pick<
			Ranking,
			| 'league'
			| 'name'
			| 'descr'
			| 'logo'
			| 'sport'
			| 'type'
			| 'period'
			| 'valid'
			| 'team'
			| 'unit'
			| 'cats'
			| 'ages'
			| 'chall'
			| 'active'
		>
) => {
	return {
		id: rank.id,
		league: rank.league,
		name: {
			value: rank.name ?? '',
			changed: false,
			error: checkValidity('name', rank.name),
			required: true,
		},
		descr: {
			value: rank.descr ?? '',
			changed: false,
			error: false,
			required: true,
		},
		sport: {
			value: rank.sport,
			changed: false,
			error: !rank.sport,
			required: true,
		},
		type: {
			value: rank.type ?? 'points',
			changed: !rank.type,
			error: false,
			required: true,
		},
		logo: {
			value: rank.logo,
			changed: false,
			error: false,
			required: false,
		},
		period: {
			value: rank.period ?? 'month',
			changed: !rank.period,
			error: false,
			required: true,
		},
		valid: {
			value: rank.valid ?? 12,
			changed: !rank.valid,
			error: false,
			required: true,
		},
		team: {
			value: rank.team,
			changed: false,
			error: !rank.team,
			required: true,
		},
		unit: {
			value: rank.unit,
			changed: false,
			error: !rank.unit,
			required: true,
		},
		cats: {
			value: rank.cats,
			changed: false,
			error: false,
			required: false,
		},
		ages: {
			value: rank.ages,
			changed: false,
			error: false,
			required: false,
		},
		...(rank.chall && {
			chall: Object.keys(rank.chall).reduce(
				(challenges, chall) => ({
					...challenges,
					[chall]: Object.keys(rank.chall?.[chall as keyof Chall] ?? {}).reduce(
						(results, res) => ({
							...results,
							[res]: {
								value:
									rank.chall?.[chall as keyof Chall]?.[
										res as keyof ResultSetup
									],
								changed: false,
								error: checkChallError(
									rank.chall?.[chall as keyof Chall]?.[res as keyof ResultSetup]
								),
								required: true,
							},
						}),
						{}
					),
				}),
				{}
			),
		}),
		active: rank.active ?? true,
	} as EditRank;
};

export const getLeagues = createAsyncThunk<
	League[],
	void,
	{ rejectValue: SerializedError }
>('leagues/getLeagues', async (_, { rejectWithValue, dispatch }) => {
	try {
		await dispatch(authRefresh());

		const { data } = await api.leagues.get();

		return data;
	} catch (error) {
		return rejectWithValue(
			(error as AxiosError).response?.data as SerializedError
		);
	}
});

export const insertLeague = createAsyncThunk<
	League,
	Partial<League>,
	{ rejectValue: SerializedError }
>('leagues/insertLeague', async (league, { dispatch, rejectWithValue }) => {
	try {
		await dispatch(authRefresh());

		const { data } = await api.leagues.post(league);

		return data;
	} catch (error) {
		return rejectWithValue(
			(error as AxiosError).response?.data as SerializedError
		);
	} finally {
		setTimeout(() => {
			dispatch(resetMessage());
		}, 10000);
	}
});

export const switchLeague = createAsyncThunk<
	League,
	{ league: string; activate: boolean },
	{ rejectValue: SerializedError }
>(
	'leagues/switchLeague',
	async ({ league, activate }, { dispatch, rejectWithValue }) => {
		try {
			await dispatch(authRefresh());

			const { data } = await api.leagues.switch(league, activate);

			return data;
		} catch (error) {
			return rejectWithValue(
				(error as AxiosError).response?.data as SerializedError
			);
		} finally {
			setTimeout(() => {
				dispatch(resetMessage());
			}, 10000);
		}
	}
);

export const getRankings = createAsyncThunk<
	Ranking[],
	string | undefined,
	{ rejectValue: SerializedError }
>('leagues/getRankings', async (league, { rejectWithValue, dispatch }) => {
	try {
		await dispatch(authRefresh());

		const { data } = await api.rankings.get(league);

		return data;
	} catch (error) {
		return rejectWithValue(
			(error as AxiosError).response?.data as SerializedError
		);
	}
});

export const insertRanking = createAsyncThunk<
	Ranking,
	Partial<Ranking>,
	{ rejectValue: SerializedError }
>('leagues/insertRanking', async (rank, { dispatch, rejectWithValue }) => {
	try {
		await dispatch(authRefresh());

		const { data } = await api.rankings.post(rank);

		return data;
	} catch (error) {
		return rejectWithValue(
			(error as AxiosError).response?.data as SerializedError
		);
	} finally {
		setTimeout(() => {
			dispatch(resetMessage());
		}, 10000);
	}
});

export const switchRanking = createAsyncThunk<
	Ranking,
	{ rank: string; activate?: boolean; publish?: boolean },
	{ rejectValue: SerializedError }
>(
	'leagues/switchRanking',
	async ({ rank, activate, publish }, { dispatch, rejectWithValue }) => {
		try {
			await dispatch(authRefresh());

			const { data } = await api.rankings.switch(rank, activate, publish);

			return data;
		} catch (error) {
			return rejectWithValue(
				(error as AxiosError).response?.data as SerializedError
			);
		} finally {
			setTimeout(() => {
				dispatch(resetMessage());
			}, 10000);
		}
	}
);

export const {
	editLeague,
	newLeague,
	changeLeague,
	newLeagueCategory,
	changeLeagueDocument,
	changeLeagueCategory,
	newLeagueAge,
	changeLeagueAge,
	newLeagueRule,
	changeLeagueRule,
	removeLeagueRule,
	newRanking,
	editRanking,
	changeRanking,
	changeRankingCats,
	changeRankingAges,
	defineRankingChallenge,
	changeRankingChallenge,
	removeRankingChallenge,
	setSend,
	resetMessage,
	resetRanking,
	resetRankings,
	resetLeague,
	resetLeagues,
} = leagueSlice.actions;

export default leagueSlice.reducer;
