import React from 'react';

import { Button, Header, Menu, Popup } from 'semantic-ui-react';

import packageJson from '../../../package.json';
import { useScreenSize } from '../../hooks';
import PoweredBy from '../../images/poweredby';
import { useAppSelector } from '../../store/hooks';
import { Landing, Media } from '../../store/landings';

const medias: Landing['medias'] = {
	facebook: 'https://www.facebook.com/ponto.esp.br/',
	twitter: 'https://twitter.com/PntEsporte',
	linkedin: 'https://www.linkedin.com/company/ponto-do-esporte/',
	instagram: 'https://www.instagram.com/pontodoesporte/',
	youtube: 'https://youtube.com/c/PontodoesporteBr',
};

const Footer = () => {
	const screenSize = useScreenSize();
	const { logged, landing, loading } = useAppSelector((state) => ({
		logged: state.user.logged,
		landing: state.lnd.landing,
		loading: state.lnd.loading,
	}));

	const backgroundImage = `url(${landing ? (landing?.bg_image ?? 'none') : '/img/pe/fake_brick.png'})`;

	const socialMedias = !landing ? medias : (landing.medias ?? {});

	if (logged && !landing && screenSize === 'mobile') return null;

	return (
		<Menu
			id="footermenu"
			inverted={loading || !landing || !!landing?.bg_color}
			attached
			borderless
			stackable
			size="mini"
			widths={screenSize === 'mobile' ? 2 : undefined}
			style={{
				backgroundImage,
				minHeight: '5vh',
			}}
		>
			<Menu.Item
				as="a"
				{...(landing && {
					href: 'https://www.pontodoesporte.com.br',
					target: '_blank',
				})}
			>
				<Popup
					on={['click']}
					content={`Versão ${packageJson.version}${(
						process.env.REACT_APP_ENV ?? process.env.NODE_ENV
					)?.slice(0, 1)}`}
					trigger={
						landing ? (
							<PoweredBy
								inverted={loading || !landing || !!landing?.bg_color}
							/>
						) : (
							<Header inverted>© Ponto do Esporte Gestão Esportiva</Header>
						)
					}
					position="top right"
					size="tiny"
				/>
			</Menu.Item>

			<Menu.Item
				position="right"
				style={{
					gap: '5px',
				}}
			>
				{Object.keys(socialMedias).map((media) => (
					<Button
						key={media}
						as="a"
						href={socialMedias[media as Media]}
						target="_blank"
						icon={media}
						color={media as Media}
						size="mini"
					/>
				))}
			</Menu.Item>
		</Menu>
	);
};

export default Footer;
