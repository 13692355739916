import {
	createAsyncThunk,
	createSlice,
	SerializedError,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { api } from '../../api';
import { authRefresh } from '../auth';
import { League, Ranking } from '../leagues';
import { Organization } from '../organizations';
import { Person } from '../people';
import { setRankings } from '../rankings';

export type Assortment = {
	league: League;
	organization: Organization;
	rankings: Ranking[];
	member?: Person;
};

interface AssortmentState {
	league?: League;
	organization?: Organization;
	member?: Person;
	loading: boolean;
	error?: SerializedError;
}

const initialState: AssortmentState = {
	loading: false,
	error: undefined,
};

export const assortmentSlice = createSlice({
	name: 'assortment',
	initialState,
	reducers: {
		resetAssortment: (state) => {
			state.league = undefined;
			state.organization = undefined;
			state.member = undefined;
			state.loading = false;
			state.error = undefined;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAssortment.fulfilled, (state, { payload }) => {
				state.league = payload.league;
				state.organization = payload.organization;
				state.member = payload.member;
				state.loading = false;
				state.error = undefined;
			})
			.addCase(getAssortment.pending, (state) => {
				state.loading = true;
				state.error = undefined;
			})
			.addCase(getAssortment.rejected, (state, { payload }) => {
				state.loading = false;
				state.error = payload;
			});
	},
});

export const getAssortment = createAsyncThunk<
	Assortment,
	string,
	{ rejectValue: SerializedError }
>('assortment/getAssortment', async (league, { rejectWithValue, dispatch }) => {
	try {
		await dispatch(authRefresh());

		const { data } = await api.assortment.get(league);

		if (data.rankings) dispatch(setRankings(data.rankings));

		return data;
	} catch (error) {
		return rejectWithValue(
			(error as AxiosError).response?.data as SerializedError
		);
	}
});

export const { resetAssortment } = assortmentSlice.actions;

export default assortmentSlice.reducer;
