import React from 'react';

import { Header, Icon, List } from 'semantic-ui-react';

const About: React.FC = () => {
	return (
		<>
			<p
				style={{
					fontSize: '21px',
				}}
			>
				Criado para fomentar o esporte, o Ponto do Esporte é uma plataforma de
				tecnologia que tem o objetivo de unir os elementos que formam um
				ambiente propício ao desenvolvimento esportivo oferecendo serviços aos
				empreendedores e aos praticantes de esportes.
			</p>

			<Header as="h3">
				<Icon name="soccer" size="mini" />
				<Header.Content>Miss&atilde;o</Header.Content>
			</Header>
			<p
				style={{
					fontSize: '18px',
				}}
			>
				O Ponto do Esporte existe para multiplicar o n&uacute;mero de
				participantes no esporte e criar um ambiente de desenvolvimento do
				esporte. Por isso, criamos ferramentas que ajudem as pessoas e entidades
				do esporte a interagirem, promovemos eventos voltados &agrave; melhoria
				do ecossistema do desporto, e promovemos estudos e treinamentos para
				entender e direcionar os profissionais do esporte.
			</p>

			<Header as="h3">
				<Icon name="basketball ball" size="mini" />
				<Header.Content>Vis&atilde;o</Header.Content>
			</Header>
			<p
				style={{
					fontSize: '18px',
				}}
			>
				Somos protagonistas de uma evolu&ccedil;&atilde;o: se, por um lado o
				esporte caminha com pouco incentivo na base e na forma&ccedil;&atilde;o
				de atletas, do outro temos profissionais que se dedicam e d&atilde;o sua
				vida para incrementar e melhorar o esporte. Todo esportista sonha desde
				pequeno em ser um grande campe&atilde;o, subir ao p&oacute;dio, receber
				uma medalha ou levantar um trof&eacute;u ao final de uma
				competi&ccedil;&atilde;o. Queremos investir na base do esporte para que
				isso seja poss&iacute;vel para cada vez mais gente. Os desafios ainda
				s&atilde;o gigantes! Por isso, idealizamos ferramentas capazes de
				integrar e aumentar o engajamento no esporte para que l&aacute; no
				futuro seja poss&iacute;vel colher os frutos. Para chegar l&aacute;,
				contamos com pessoas que acreditam no esporte como formador e ferramenta
				de educa&ccedil;&atilde;o para o indiv&iacute;duo.
			</p>

			<Header as="h3">
				<Icon name="volleyball ball" size="mini" />
				<Header.Content>Valores</Header.Content>
			</Header>
			<List
				bulleted
				style={{
					fontSize: '18px',
				}}
			>
				<List.Item content="Sonhamos com a capacidade de formarmos esportistas para a vida." />
				<List.Item content="Paix&atilde;o pelo esporte e pelo que ele significa para quem pratica." />
				<List.Item content="Inovamos para resolver os problemas de uma atividade t&atilde;o importante e ao mesmo tempo renegada." />
			</List>
		</>
	);
};

export default About;
