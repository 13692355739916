import {
	PayloadAction,
	SerializedError,
	createAsyncThunk,
	createSlice,
} from '@reduxjs/toolkit';

import { api } from '../../api';
import { authRefresh } from '../auth';
import { Organization } from '../organizations';

interface PlaceState {
	place?: Organization;
	loading: boolean;
	error?: SerializedError;
}

const initialState: PlaceState = {
	place: undefined,
	loading: false,
	error: undefined,
};

export const placeSlice = createSlice({
	name: 'places',
	initialState: initialState,
	reducers: {
		resetPlace: (state) => {
			state.place = undefined;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(
				getPlace.fulfilled,
				(state, { payload }: PayloadAction<Organization>) => {
					state.place = payload;
					state.loading = false;
					state.error = undefined;
				}
			)
			.addCase(getPlace.pending, (state) => {
				state.loading = true;
			})
			.addCase(getPlace.rejected, (state, action) => {
				state.error = action.error;
				state.loading = false;
			});
	},
});

export const getPlace = createAsyncThunk(
	'places/getPlace',
	async ({ unit }: { unit: string }, { rejectWithValue, dispatch }) => {
		try {
			await dispatch(authRefresh());

			const response = await api.places.get(unit);

			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const { resetPlace } = placeSlice.actions;

export default placeSlice.reducer;
