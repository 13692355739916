import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';

export const region = 'us-east-1';

export const UserPoolId = 'us-east-1_s3JR5nR6b';

export const identityPoolId = 'us-east-1:13f509d2-03ab-49cc-9e65-03cacbd19efa';

export const ClientId = '44knf7g380d1te7m1fujjo1erf';

export const roles = {
	AuthRole: 'arn:aws:iam::296767957826:role/CognitoPontodoEsporteAuthRole',
	UnauthRole: 'arn:aws:iam::296767957826:role/CognitoPontodoEsporteUnauthRole',
};

export const COGNITO_ID = `cognito-idp.${region}.amazonaws.com/${UserPoolId}`;

export const GOOGLE_CLIENT_ID =
	'347883810080-d12es4dmf1mljggri0k4i8j7onbvha46.apps.googleusercontent.com';

export const credentials = ({
	idToken,
	googleToken,
	fbToken,
	amazonToken,
	twitterToken,
}: {
	idToken?: string;
	googleToken?: string;
	fbToken?: string;
	amazonToken?: string;
	twitterToken?: string;
}) =>
	fromCognitoIdentityPool({
		identityPoolId,
		clientConfig: { region },
		logins: {
			...(idToken ? { [COGNITO_ID]: idToken } : {}),
			...(googleToken ? { 'accounts.google.com': googleToken } : {}),
			...(fbToken ? { 'graph.facebook.com': fbToken } : {}),
			...(amazonToken ? { 'www.amazon.com': amazonToken } : {}),
			...(twitterToken ? { 'api.twitter.com': twitterToken } : {}),
		},
	});
