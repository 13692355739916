import {
	PayloadAction,
	SerializedError,
	createAsyncThunk,
	createSlice,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { api } from '../../api';
import { authRefresh } from '../auth';
import { Enrollment } from '../enrollments';
import { Team } from '../groups';
import { Score, Sport } from '../sports';

export type HistoryType =
	| 'creation'
	| 'schedule'
	| 'location'
	| 'score'
	| 'result'
	| 'walkover'
	| 'winner'
	| 'comunication';

export type History = {
	time: string;
	type: HistoryType;
	user: string;
	data?: string | boolean | Team | Score | [Result, Result][];
};

export type Result = {
	games: number;
	tiebreak?: number;
	winner?: boolean;
};

export type Results = Result[];

export interface Match {
	id?: string;
	rank?: string;
	chall?: string;
	tourn?: string;
	group?: string;
	sport: Sport;
	date?: string;
	history?: History[];
	teams: Team[];
	number?: number;
	court?: string;
	winner?: string;
	walkover?: boolean;
	result?: Results[];
	score?: Score;
	active?: boolean;
}

export type MatchEditItem<EditType> = {
	value: EditType;
	changed: boolean;
};

export type MatchChangeItem<ChangeType> = {
	key: 'date' | 'court' | 'result' | 'score' | 'walkover' | 'winner';
	value: ChangeType;
};

export type MatchEdit = {
	id?: string;
	number?: number;
	sport: Sport;
	rank?: string;
	chall?: string;
	tourn?: string;
	group?: string;
	teams: Team[];
	date?: MatchEditItem<string | undefined>;
	court?: MatchEditItem<string | undefined>;
	result?: MatchEditItem<Results[] | undefined>;
	score?: MatchEditItem<Score | undefined>;
	walkover?: MatchEditItem<boolean | undefined>;
	winner?: MatchEditItem<string | undefined>;
	history?: History[];
	active?: boolean;
};

export type SortColumn = 'hour' | 'court' | 'number';

export type OrderColumn = 'ascending' | 'descending';

export type Schedule = {
	[date: string]: Match[];
};

interface MatchesState {
	matches: Match[];
	schedule?: Schedule;
	editing?: MatchEdit;
	loading: boolean;
	saving?: boolean;
	removing?: boolean;
	error?: SerializedError;
}

const initialState: MatchesState = {
	matches: [],
	loading: false,
	error: undefined,
};

export const matchSlice = createSlice({
	name: 'matches',
	initialState: initialState,
	reducers: {
		editMatch: (state, { payload }: PayloadAction<Match>) => {
			state.editing = {
				id: payload.id,
				number: payload.number,
				...(payload.tourn
					? { tourn: payload.tourn, group: payload.group }
					: {}),
				...(payload.rank ? { rank: payload.rank, chall: payload.chall } : {}),
				sport: payload.sport,
				teams: payload.teams,
				court: {
					changed: !!payload.court && !payload.id,
					value: payload.court,
				},
				date: {
					changed: !!payload.date && !payload.id,
					value: payload.date,
				},
				result: {
					changed: !!payload.result && !payload.id,
					value: payload.result,
				},
				score: {
					changed: !!payload.score && !payload.id,
					value: payload.score,
				},
				walkover: {
					changed: !!payload.walkover && !payload.id,
					value: payload.walkover,
				},
				winner: {
					changed: !!payload.winner && !payload.id,
					value: payload.winner,
				},
				history: payload.history ?? [],
				active: payload.active ?? true,
			};
		},
		changeMatch: (
			state,
			{
				payload,
			}: PayloadAction<
				MatchChangeItem<
					boolean | string | [Team, Team] | Results[] | Score | undefined
				>
			>
		) => {
			state.editing = {
				...(state.editing as MatchEdit),
				[payload.key]: {
					value: payload.value,
					changed:
						state.editing?.[payload.key]?.changed ||
						payload.value !== state.editing?.[payload.key]?.value,
				},
			};
		},
		insertMatchPeriod: (state) => {
			const countPlayedSets = state.editing?.result?.value?.length ?? 0;
			const setConfig = state.editing?.score?.value?.periods?.[countPlayedSets];
			const result = state.editing?.result?.value ?? [];

			state.editing = {
				...(state.editing as MatchEdit),
				result: {
					changed: true,
					value: result.concat([
						[
							{
								games: 0,
								winner: false,
								...(setConfig?.type === 'tiebreak' && { tiebreak: 0 }),
							},
							{
								games: 0,
								winner: false,
								...(setConfig?.type === 'tiebreak' && { tiebreak: 0 }),
							},
						],
					]),
				},
			};
		},
		removeMatchPeriod: (state) => {
			const result = state.editing?.result?.value ?? [];

			state.editing = {
				...(state.editing as MatchEdit),
				result: {
					changed: true,
					value: result.length > 0 ? result.slice(0, -1) : result,
				},
			};
		},
		increaseTeamPoints: (
			state,
			{
				payload: { periodNumber, teamNumber, increase = 1 },
			}: PayloadAction<{
				periodNumber: number;
				teamNumber: number;
				increase?: number;
			}>
		) => {
			const newResult = state.editing?.result?.value?.map((period, pIndex) =>
				pIndex === periodNumber
					? period.map((team, tIndex) =>
							tIndex === teamNumber
								? { ...team, games: team.games + increase }
								: team
						)
					: period
			);

			state.editing = {
				...(state.editing as MatchEdit),
				result: {
					changed: true,
					value: newResult,
				},
			};
		},
		decreaseTeamPoints: (
			state,
			{
				payload: { periodNumber, teamNumber, decrease = 1 },
			}: PayloadAction<{
				periodNumber: number;
				teamNumber: number;
				decrease?: number;
			}>
		) => {
			const newResult = state.editing?.result?.value?.map((period, pIndex) =>
				pIndex === periodNumber
					? period.map((team, tIndex) =>
							tIndex === teamNumber
								? {
										...team,
										games:
											team.games - decrease < 0 ? 0 : team.games - decrease,
									}
								: team
						)
					: period
			);

			state.editing = {
				...(state.editing as MatchEdit),
				result: {
					changed: true,
					value: newResult,
				},
			};
		},
		changeTeamPoints: (
			state,
			{
				payload: { periodNumber, teamNumber, pointsValue },
			}: PayloadAction<{
				periodNumber: number;
				teamNumber: number;
				pointsValue: number;
			}>
		) => {
			const newResult = state.editing?.result?.value?.map((period, pIndex) =>
				pIndex === periodNumber
					? period.map((team, tIndex) =>
							tIndex === teamNumber
								? {
										...team,
										games: pointsValue,
									}
								: team
						)
					: period
			);

			state.editing = {
				...(state.editing as MatchEdit),
				result: {
					changed: true,
					value: newResult,
				},
			};
		},
		increaseTeamScore: (
			state,
			{
				payload: { setNumber, teamNumber },
			}: PayloadAction<{ setNumber: number; teamNumber: number }>
		) => {
			const result = state.editing?.result?.value;
			const currentSet = result?.[setNumber];
			const setConfig = state.editing?.score?.value?.periods?.[setNumber];
			const setGamesLimit = setConfig?.limit ?? 0;
			const setGamesExtra = setConfig?.extra ?? 1;
			const setGamesWin = setConfig?.winner ?? 0;
			const setTieWin = setConfig?.tiebreak ?? 0;

			const setGamesDiff = (set?: Result[]) =>
				Math.abs((set?.[0].games ?? 0) - (set?.[1].games ?? 0));

			const setTieDiff = (set: Result[]) =>
				Math.abs((set?.[0].tiebreak ?? 0) - (set?.[1].tiebreak ?? 0));

			const canAddGame = (gameValue: number) =>
				!tiebreakInPlace(currentSet) &&
				setConfig?.type !== 'tiebreak' &&
				(gameValue < setGamesWin || setGamesDiff(currentSet) < 2) &&
				gameValue < setGamesLimit;

			const tiebreakInPlace = (set?: Result[]) =>
				set && set.every((set) => set.games === setGamesLimit - setGamesExtra);

			const tieHasWinner = (set: Result[]) =>
				setTieDiff(set) >= 2 &&
				set.some(
					(score) => (score.tiebreak ?? 0) >= (setConfig?.tiebreak ?? 0)
				);

			const checkTiebreak = (set: Result[]) =>
				tiebreakInPlace(set)
					? tieHasWinner(set) && setConfig?.type !== 'tiebreak'
						? set.map((score, index) => ({
								...score,
								games:
									(score?.tiebreak ?? 0) >
									(set[index === 0 ? 1 : 0]?.tiebreak ?? 0)
										? score.games + 1
										: score.games,
							}))
						: set.map((score) => ({ tiebreak: 0, ...score }))
					: set;

			const checkSetWinner = (set: Result[]) =>
				set.map((score, scoreIndex) => ({
					...score,
					winner:
						score.games === setGamesLimit ||
						(score.games >= setGamesWin &&
							score.games > set[scoreIndex === 0 ? 1 : 0].games &&
							setGamesDiff(set) >= 2) ||
						((score.tiebreak ?? 0) >= setTieWin &&
							(score.tiebreak ?? 0) >
								(set[scoreIndex === 0 ? 1 : 0].tiebreak ?? 0) &&
							tiebreakInPlace(set) &&
							setTieDiff(set) >= 2),
				}));

			state.editing = {
				...(state.editing as MatchEdit),
				result: {
					changed: true,
					value: result?.map((set, setIndex) =>
						!set.some((team) => team.winner) && setIndex === setNumber
							? checkSetWinner(
									checkTiebreak(
										set.map((team, teamIndex) =>
											teamIndex === teamNumber
												? {
														...team,
														games: canAddGame(team.games)
															? team.games + 1
															: team.games,
														...(tiebreakInPlace(set)
															? { tiebreak: (team.tiebreak ?? 0) + 1 }
															: {}),
													}
												: team
										)
									)
								)
							: set
					),
				},
			};
		},
		decreaseTeamScore: (
			state,
			{
				payload: { setNumber, teamNumber },
			}: PayloadAction<{ setNumber: number; teamNumber: number }>
		) => {
			const result = state.editing?.result?.value;
			const currentSet = result?.[setNumber];
			const setConfig = state.editing?.score?.value?.periods?.[setNumber];
			const setGamesLimit = setConfig?.limit ?? 0;
			const setGamesExtra = setConfig?.extra ?? 1;
			const setGamesWin = setConfig?.winner ?? 0;
			const setTieWin = setConfig?.tiebreak ?? 0;

			const setGamesDiff = (set?: Result[]) =>
				Math.abs((set?.[0].games ?? 0) - (set?.[1].games ?? 0));

			const setTieDiff = (set: Result[]) =>
				Math.abs((set?.[0].tiebreak ?? 0) - (set?.[1].tiebreak ?? 0));

			const tiebreakInPlace = (set?: Result[]) =>
				set && set.every((set) => set.games === setGamesLimit - setGamesExtra);

			const subtractGame = (gameValue: number) => {
				const canSubtract =
					gameValue > 0 &&
					(tiebreakInPlace(currentSet)
						? currentSet?.every((score) => (score.tiebreak ?? 0) <= 0)
						: true);

				return canSubtract ? { games: gameValue - 1 } : { games: gameValue };
			};
			const subtractTie = (tieValue?: number) =>
				tieValue && tieValue > 0
					? {
							tiebreak: tieValue - 1,
						}
					: {};

			const tieHasWinner = (set: Result[]) =>
				setTieDiff(set) >= 2 &&
				set.some(
					(score) => (score.tiebreak ?? 0) >= (setConfig?.tiebreak ?? 0)
				);

			const checkTiebreak = (set: Result[]) =>
				tiebreakInPlace(set) && tieHasWinner(set)
					? set.map((score, index) => ({
							...score,
							games:
								(score?.tiebreak ?? 0) >
								(set[index === 0 ? 1 : 0]?.tiebreak ?? 0)
									? score.games + 1
									: score.games,
						}))
					: set;

			const checkRemoveTiebreak = (set: Result[]): Result[] =>
				setConfig?.type !== 'tiebreak' &&
				set.every((score) => (score.tiebreak ?? 0) <= 0)
					? set.map((score) => {
							// eslint-disable-next-line @typescript-eslint/no-unused-vars
							const { tiebreak, ...scoreNoTie } = score;
							return scoreNoTie;
						})
					: set;

			const checkSetWinner = (set: Result[]) =>
				set.map((score, scoreIndex) => ({
					...score,
					winner:
						score.games === setGamesLimit ||
						(score.games >= setGamesWin &&
							score.games > set[scoreIndex === 0 ? 1 : 0].games &&
							setGamesDiff(set) >= 2) ||
						((score.tiebreak ?? 0) >= setTieWin &&
							(score.tiebreak ?? 0) >
								(set[scoreIndex === 0 ? 1 : 0].tiebreak ?? 0) &&
							tiebreakInPlace(set) &&
							setTieDiff(set) >= 2),
				}));

			state.editing = {
				...(state.editing as MatchEdit),
				result: {
					changed: true,
					value: result?.map((set, setIndex) =>
						!set.some((team) => team.winner) && setIndex === setNumber
							? checkSetWinner(
									checkTiebreak(
										checkRemoveTiebreak(set).map((team, teamIndex) =>
											teamIndex === teamNumber
												? {
														...team,
														...subtractGame(team.games),
														...subtractTie(team.tiebreak),
													}
												: team
										)
									)
								)
							: set
					),
				},
			};
		},
		changeTeamGames: (
			state,
			{
				payload: { setNumber, teamNumber, gamesValue },
			}: PayloadAction<{
				setNumber: number;
				teamNumber: number;
				gamesValue: number;
			}>
		) => {
			const result = state.editing?.result?.value;
			const currentSet = result?.[setNumber];
			const setConfig = state.editing?.score?.value?.periods?.[setNumber];
			const setGamesLimit = setConfig?.limit ?? 0;
			const setGamesExtra = setConfig?.extra ?? 1;
			const setGamesWin = setConfig?.winner ?? 0;
			const setTieWin = setConfig?.tiebreak ?? 0;
			const currentSetOpponentGames =
				currentSet?.[teamNumber === 0 ? 1 : 0].games ?? 0;

			const setGamesDiff = (set?: Result[]) =>
				Math.abs((set?.[0].games ?? 0) - (set?.[1].games ?? 0));

			const setTieDiff = (set: Result[]) =>
				Math.abs((set?.[0].tiebreak ?? 0) - (set?.[1].tiebreak ?? 0));

			const tiebreakInPlace = (set?: Result[]) =>
				set && set.every((set) => set.games === setGamesLimit - setGamesExtra);

			const checkSetWinner = (set: Result[]) =>
				set.map((score, scoreIndex) => ({
					...score,
					winner:
						score.games === setGamesLimit ||
						(score.games >= setGamesWin &&
							score.games > set[scoreIndex === 0 ? 1 : 0].games &&
							setGamesDiff(set) >= 2) ||
						((score.tiebreak ?? 0) >= setTieWin &&
							(score.tiebreak ?? 0) >
								(set[scoreIndex === 0 ? 1 : 0].tiebreak ?? 0) &&
							tiebreakInPlace(set) &&
							setTieDiff(set) >= 2),
				}));

			const checkTiebreak = (set: Result[]): Result[] =>
				set.map((score) => {
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					const { tiebreak, ...scoreNoTie } = score;
					const scoreNewTie = { tiebreak: 0, ...score };
					return !tiebreakInPlace(set) ? scoreNoTie : scoreNewTie;
				});

			const cantChangeGame = () => {
				if (gamesValue < 0) return true;
				if (gamesValue > (setConfig?.limit ?? 0)) return true;
				if (
					gamesValue > (setConfig?.winner ?? 0) ||
					currentSetOpponentGames > (setConfig?.winner ?? 0)
				) {
					return Math.abs(gamesValue - currentSetOpponentGames) > 2;
				}
				return false;
			};

			state.editing = {
				...(state.editing as MatchEdit),
				result: {
					changed: true,
					value: result?.map((set, setIndex) =>
						!set.some((team) => team.winner) && setIndex === setNumber
							? checkSetWinner(
									checkTiebreak(
										set.map((team, teamIndex) =>
											teamIndex === teamNumber && !cantChangeGame()
												? {
														...team,
														games: gamesValue,
													}
												: team
										)
									)
								)
							: set
					),
				},
			};
		},
		changeTeamTiebreak: (
			state,
			{
				payload: { setNumber, teamNumber, tieValue },
			}: PayloadAction<{
				setNumber: number;
				teamNumber: number;
				tieValue: number;
			}>
		) => {
			const result = state.editing?.result?.value;
			const currentSet = result?.[setNumber];
			const setConfig = state.editing?.score?.value?.periods?.[setNumber];
			const setGamesLimit = setConfig?.limit ?? 0;
			const setGamesExtra = setConfig?.extra ?? 1;
			const setGamesWin = setConfig?.winner ?? 0;
			const setTieWin = setConfig?.tiebreak ?? 0;
			const currentSetOpponentTie =
				currentSet?.[teamNumber === 0 ? 1 : 0].tiebreak ?? 0;

			const setGamesDiff = (set?: Result[]) =>
				Math.abs((set?.[0].games ?? 0) - (set?.[1].games ?? 0));

			const setTieDiff = (set: Result[]) =>
				Math.abs((set?.[0].tiebreak ?? 0) - (set?.[1].tiebreak ?? 0));

			const tiebreakInPlace = (set?: Result[]) =>
				set && set.every((set) => set.games === setGamesLimit - setGamesExtra);

			const checkSetWinner = (set: Result[]) =>
				set.map((score, scoreIndex) => ({
					...score,
					winner:
						score.games === setGamesLimit ||
						(score.games >= setGamesWin &&
							score.games > set[scoreIndex === 0 ? 1 : 0].games &&
							setGamesDiff(set) >= 2) ||
						((score.tiebreak ?? 0) >= setTieWin &&
							(score.tiebreak ?? 0) >
								(set[scoreIndex === 0 ? 1 : 0].tiebreak ?? 0) &&
							tiebreakInPlace(set) &&
							setTieDiff(set) >= 2),
				}));

			const cantChangeTie = () => {
				if (tieValue < 0) return true;
				if (
					tieValue > (setConfig?.tiebreak ?? 0) ||
					currentSetOpponentTie > (setConfig?.tiebreak ?? 0)
				) {
					return Math.abs(tieValue - currentSetOpponentTie) > 2;
				}
				return false;
			};

			state.editing = {
				...(state.editing as MatchEdit),
				result: {
					changed: true,
					value: result?.map((set, setIndex) =>
						!set.some((team) => team.winner) && setIndex === setNumber
							? checkSetWinner(
									set.map((team, teamIndex) =>
										teamIndex === teamNumber && !cantChangeTie()
											? {
													...team,
													tiebreak: tieValue,
												}
											: team
									)
								)
							: set
					),
				},
			};
		},
		matchWalkover: (state, { payload }: PayloadAction<string | undefined>) => {
			const walkover = state.editing?.walkover?.value;
			const winner = state.editing?.winner?.value;

			state.editing = {
				...(state.editing as MatchEdit),
				walkover: {
					changed: true,
					value: !walkover,
				},
				winner: {
					changed: winner !== payload,
					value: payload,
				},
			};
		},
		checkMatchWinner: (state, { payload }: PayloadAction<Results[]>) => {
			const sport = state.editing?.sport;
			const teams = state.editing?.teams;
			const score = state.editing?.score?.value;

			const teamsSetCount = teams?.map((team, index) =>
				payload.reduce(
					(total, period) => total + (period[index].winner ? 1 : 0),
					0
				)
			);

			const teamScoreCount = teams?.map((team, index) =>
				payload.reduce((total, period) => total + period[index].games, 0)
			);

			const teamWinner = teams?.find((team, index) => {
				switch (sport) {
					case 'tennis':
						return (
							(teamsSetCount?.[index] ?? 0) >=
							Math.floor((score?.periods?.length ?? 0) / 2) + 1
						);
					case 'volley':
						return (
							(teamsSetCount?.[index] ?? 0) >=
							Math.floor((score?.periods?.length ?? 0) / 2) + 1
						);
					default:
						return teamScoreCount?.every(
							(score, sIndex) =>
								sIndex === index || score < (teamScoreCount?.[index] ?? 0)
						);
				}
			});

			state.editing = {
				...(state.editing as MatchEdit),
				winner: {
					value: teamWinner?.id ?? teamWinner?.type ?? undefined,
					changed:
						state.editing?.winner !==
						(teamWinner?.id ?? teamWinner?.type ?? undefined),
				},
			};
		},
		matchesSort: (
			state,
			{
				payload,
			}: PayloadAction<{ date: string; col: SortColumn; order: OrderColumn }>
		) => {
			state.schedule = {
				...state.schedule,
				...(state.schedule?.[payload.date]
					? {
							[payload.date]: orderMatches(
								state.schedule?.[payload.date],
								payload.col,
								payload.order
							),
						}
					: {}),
			};
		},
		matchesSchedule: (
			state,
			{
				payload,
			}: PayloadAction<{
				matches: Match[];
				filters?: {
					search?: string;
					tourn?: Match['tourn'];
					canceled?: boolean;
				};
			}>
		) => {
			state.schedule = filterMatches(
				payload.matches,
				payload.filters?.search,
				payload.filters?.tourn,
				payload.filters?.canceled
			);
		},
		setMatches: (state, { payload }: PayloadAction<Match[]>) => {
			state.matches = payload ?? [];
		},
		resetSchedule: (state) => {
			state.schedule = undefined;
		},
		resetMatch: (state) => {
			state.editing = undefined;
		},
		resetMatches: (state) => {
			state.matches = [];
			state.schedule = undefined;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getMatches.fulfilled, (state, { payload }) => {
				state.matches = state.matches
					.filter((m) => payload.every((p) => p.id !== m.id))
					.concat(...payload);
				state.loading = false;
				state.error = undefined;
			})
			.addCase(getMatches.pending, (state) => {
				state.loading = true;
			})
			.addCase(getMatches.rejected, (state, { payload }) => {
				state.error = payload;
				state.loading = false;
			});
		builder
			.addCase(insertMatch.fulfilled, (state, { payload }) => {
				const matches = state.matches
					.filter((match) => match.id !== payload.id)
					.concat(payload);

				state.matches = matches;
				state.saving = false;
				state.editing = undefined;
				state.error = undefined;
			})
			.addCase(insertMatch.pending, (state) => {
				state.saving = true;
			})
			.addCase(insertMatch.rejected, (state, { payload }) => {
				state.saving = false;
				state.error = payload;
			});
		builder
			.addCase(deleteMatch.fulfilled, (state, { payload }) => {
				const matches = state.matches
					.filter((match) => match.id !== payload.id)
					.concat(payload);

				state.matches = matches;
				state.removing = false;
				state.editing = undefined;
				state.error = undefined;
			})
			.addCase(deleteMatch.pending, (state) => {
				state.removing = true;
			})
			.addCase(deleteMatch.rejected, (state, { payload }) => {
				state.error = payload;
				state.removing = false;
			});
	},
});

const orderSchedule = (schedule: Schedule) =>
	Object.keys(schedule).reduce(
		(sched, key) => ({ ...sched, [key]: orderMatches(schedule[key]) }),
		{} as Schedule
	);

const scheduleMatches = (matches: Match[]) =>
	matches.reduce((dates: Schedule, match) => {
		const matchDate = match.date
			? `${String(new Date(match.date).getFullYear()).padStart(
					4,
					'0'
				)}-${String(new Date(match.date).getMonth() + 1).padStart(
					2,
					'0'
				)}-${String(new Date(match.date).getDate()).padStart(2, '0')}`
			: undefined;

		return {
			...dates,
			...(matchDate
				? {
						[matchDate]: (dates[matchDate] ?? []).concat(match),
					}
				: {}),
		};
	}, {} as Schedule);

const orderMatches = (
	matches: Match[],
	col: SortColumn = 'hour',
	order?: OrderColumn
) =>
	matches.sort((a, b) => {
		switch (col) {
			case 'court':
				if (!a['court'] && !b['court']) return 0;
				if (!a['court']) return order === 'descending' ? 1 : -1;
				if (!b['court']) return order === 'descending' ? -1 : 1;
				return order === 'descending'
					? +b['court'] - +a['court']
					: +a['court'] - +b['court'];
			case 'number':
				if (!a['number'] && !b['number']) return 0;
				if (!a['number']) return order === 'descending' ? 1 : -1;
				if (!b['number']) return order === 'descending' ? -1 : 1;
				return order === 'descending'
					? +b['number'] - +a['number']
					: +a['number'] - +b['number'];
			default:
				if (!a['date'] && !b['date']) return 0;
				if (!a['date']) return order === 'descending' ? 1 : -1;
				if (!b['date']) return order === 'descending' ? -1 : 1;
				return order === 'descending'
					? new Date(b['date']).getTime() - new Date(a['date']).getTime()
					: new Date(a['date']).getTime() - new Date(b['date']).getTime();
		}
	});

const filterMatches = (
	matches: Match[],
	search?: string,
	tourn?: string,
	canceled?: boolean
) =>
	orderSchedule(
		scheduleMatches(
			matches.filter(
				(match) =>
					(canceled || match.active !== false) &&
					(!tourn || match.tourn === tourn) &&
					match.teams.some(
						(team) =>
							!search ||
							team.title?.toLowerCase().includes(search.toLowerCase())
					)
			)
		)
	);

export const updateTeamsTitle = (matches: Match[], enrollments: Enrollment[]) =>
	matches.map((match) => ({
		...match,
		teams: match.teams.map((team) => {
			const enroll = enrollments.find(
				(enroll) => team?.id && enroll.id === team?.id
			);

			return team
				? {
						...team,
						...(enroll ? { title: enroll.title } : {}),
					}
				: team;
		}),
	}));

export const matchResult = (
	teams: [Team, Team],
	result: Results[],
	revert: boolean,
	sport?: Sport
) => {
	const calcResultScore = () =>
		teams.map((team, teamIndex) =>
			result.reduce((total, period) => total + period[teamIndex].games, 0)
		);

	const setScore = (set: Results) =>
		set.map((score) =>
			!!score.tiebreak && score.games === 0 ? score.tiebreak : score.games
		);

	const tiebreakScore = (set: Results) =>
		set.map((score) => score.tiebreak ?? 0);

	const isFinalTiebreak = (set: Results) =>
		set.reduce(
			(tiebreak, score) => tiebreak || (!!score.tiebreak && score.games !== 0),
			false
		);

	const revertScore = (score: number[], revert: boolean) =>
		revert ? score.reverse() : score;

	const joinScore = (score: (string | number)[], symbol: string) =>
		score.join(symbol);

	switch (sport) {
		case 'tennis':
			return joinScore(
				result.map(
					(set) =>
						`${joinScore(revertScore(setScore(set), revert), 'x')} ${
							isFinalTiebreak(set)
								? `(${joinScore(revertScore(tiebreakScore(set), revert), 'x')})`
								: ''
						}`
				),
				' '
			);
		default:
			return joinScore(revertScore(calcResultScore(), revert), 'x');
	}
};

export const matchWalkoverResult = (match: Match) =>
	!match?.result || match?.result?.length === 0
		? !match?.winner
			? 'Duplo W.O.'
			: 'W.O.'
		: 'Desist.';

export const getMatches = createAsyncThunk<
	Match[],
	{ tourn: string; rank?: string } | { rank: string; tourn?: string },
	{ rejectValue: SerializedError }
>(
	'matches/getMatches',
	async ({ tourn, rank }, { rejectWithValue, dispatch }) => {
		try {
			await dispatch(authRefresh());

			const { data } = await api.matches.get({ tourn, rank });

			return data;
		} catch (error) {
			return rejectWithValue(
				(error as AxiosError).response?.data as SerializedError
			);
		}
	}
);

export const insertMatch = createAsyncThunk<
	Match,
	MatchEdit,
	{ rejectValue: SerializedError }
>('matches/insertMatch', async (edited, { rejectWithValue, dispatch }) => {
	try {
		await dispatch(authRefresh());

		const match: Match = {
			id: edited.id,
			sport: edited.sport,
			number: edited.number,
			teams: edited.teams,
			...(edited.rank ? { rank: edited.rank, chall: edited.chall } : {}),
			...(edited.tourn ? { tourn: edited.tourn, group: edited.group } : {}),
			...(edited.score?.changed
				? {
						score: edited.score.value,
					}
				: {}),
			...(edited.court?.changed
				? {
						court: edited.court.value,
					}
				: {}),
			...(edited.date?.changed
				? {
						date: edited.date.value,
					}
				: {}),
			...(edited.result?.changed
				? {
						result: edited.result.value,
					}
				: {}),
			...(edited.walkover?.changed
				? {
						walkover: edited.walkover.value,
					}
				: {}),
			...(edited.winner?.changed
				? {
						winner: edited.winner.value ?? '',
					}
				: {}),
		};

		const { data } = await api.matches.post(match);

		return data;
	} catch (error) {
		return rejectWithValue(
			(error as AxiosError).response?.data as SerializedError
		);
	}
});

export const deleteMatch = createAsyncThunk<
	Match,
	Pick<Match, 'id' | 'tourn' | 'group' | 'rank' | 'chall'>,
	{ rejectValue: SerializedError }
>('matches/deleteMatch', async (match, { rejectWithValue, dispatch }) => {
	try {
		await dispatch(authRefresh());

		const { data } = await api.matches.delete(match);

		return data;
	} catch (error) {
		return rejectWithValue(
			(error as AxiosError).response?.data as SerializedError
		);
	}
});

export const {
	editMatch,
	changeMatch,
	matchWalkover,
	insertMatchPeriod,
	removeMatchPeriod,
	increaseTeamPoints,
	decreaseTeamPoints,
	changeTeamPoints,
	increaseTeamScore,
	decreaseTeamScore,
	changeTeamGames,
	changeTeamTiebreak,
	checkMatchWinner,
	matchesSort,
	matchesSchedule,
	setMatches,
	resetSchedule,
	resetMatch,
	resetMatches,
} = matchSlice.actions;

export default matchSlice.reducer;
