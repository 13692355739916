import React, {
	Fragment,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';

import { useHistory } from 'react-router-dom';
import { Menu, Sidebar, Icon, Image, MenuItemProps } from 'semantic-ui-react';

import { AppRoute } from '../../app';
import { useAppSelector } from '../../store/hooks';
import { RightItem } from '../../store/rights';

interface SidebarProps {
	open: boolean;
	topMenuItems: AppRoute[];
	children: React.ReactNode;
	closeSidebar: () => void;
	openSidebar: () => void;
}

const SidebarMenu: React.FC<SidebarProps> = ({
	open,
	topMenuItems,
	closeSidebar,
	openSidebar,
}) => {
	const history = useHistory();
	const { logged, rights, loading, error } = useAppSelector((state) => ({
		logged: state.user.logged,
		rights: state.rgt.rights,
		loading: state.rgt.loading,
		error: state.rgt.error,
	}));

	const [activeTop, setActiveTop] = useState<string | number>();
	const [activeItem, setActiveItem] = useState<string | number>();
	const [menuTop, setMenuTop] = useState<number>(0);

	const onScroll = () => {
		setMenuTop(+window.scrollY);
	};

	useEffect(() => {
		window.addEventListener('scroll', onScroll);

		return () => window.removeEventListener('scroll', onScroll);
	}, []);

	const handleMenuClick = (
		event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
		data: MenuItemProps
	) => {
		if (data.item.redirect) handleLinkTo(data.item.link ?? `/${data.item.id}`);
		else if (
			menuItems[data.item.id]?.some((item) =>
				menuItems[item.id].some((sub) => sub.profile?.includes('admin'))
			)
		) {
			setActiveTop(
				!menuItems[data.item.id]
					? undefined
					: activeTop === data.item.id
						? undefined
						: data.item.id
			);
			setActiveItem(undefined);
		}
	};

	const handleItemClick = (
		event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
		data: MenuItemProps
	) => {
		setActiveItem(activeItem === data.item.id ? undefined : data.item.id);
	};

	const handleOpenSidebar = () => {
		openSidebar();
	};

	const handleCloseSidebar = useCallback(() => {
		setActiveTop(undefined);
		setActiveItem(undefined);
		closeSidebar();
	}, [closeSidebar]);

	const handleLinkTo = (domain: string, id?: string) => {
		history.push(`${domain}${id ? `/${id}` : ''}`);
		handleCloseSidebar();
	};

	const sortItems =
		(a: RightItem, b: RightItem) => (sort: Array<keyof RightItem>) =>
			sort.reduce(
				(result, compare) =>
					result === 0
						? (a[compare] ?? 0) > (b[compare] ?? 0)
							? 1
							: (a[compare] ?? 0) < (b[compare] ?? 0)
								? -1
								: 0
						: result,
				0
			);

	const menuItems = useMemo(
		() =>
			rights.reduce(
				(menu: { [key: string]: RightItem[] }, item: RightItem) => ({
					...menu,
					[item.super]: [...(menu[item.super] ?? []), item].sort((a, b) =>
						sortItems(a, b)(['nick', 'name'])
					),
				}),
				{}
			),
		[rights]
	);

	const isAdmin = (id: string) =>
		menuItems[id].map((item) => item.profile?.includes('admin'));

	useEffect(() => {
		if (!logged) handleCloseSidebar();
	}, [handleCloseSidebar, logged]);

	return (
		<Sidebar
			visible
			animation="overlay"
			width={!open ? 'very thin' : !activeTop ? 'wide' : 'very wide'}
			style={{ backgroundColor: 'white' }}
			onMouseOver={handleOpenSidebar}
			onMouseLeave={handleCloseSidebar}
		>
			<Menu
				vertical
				fluid
				icon={!open}
				style={{
					border: 'none',
					position: 'fixed',
					top: `${menuTop}px`,
				}}
			>
				{error ? (
					<Menu.Item style={{ color: 'red' }}>
						<Icon name="warning sign" color="red" />
						{open && error.message}
					</Menu.Item>
				) : loading ? (
					<Menu.Item>
						<Icon name="spinner" loading />
						{open && 'Loading'}
					</Menu.Item>
				) : (
					topMenuItems?.map((top) => (
						<Fragment key={top.id}>
							<Menu.Item item={top} onClick={handleMenuClick}>
								{open && top.name}
								<Icon name={top.icon} />
								{menuItems[top.id]?.some((item) =>
									menuItems[item.id].some((sub) =>
										sub.profile?.includes('admin')
									)
								) &&
									!top.redirect &&
									open && (
										<Icon
											name={activeTop === top.id ? 'angle down' : 'angle left'}
										/>
									)}
							</Menu.Item>

							{activeTop === top.id &&
								menuItems[top.id]
									?.filter((item) =>
										menuItems[item.id].some((sub) =>
											sub.profile?.includes('admin')
										)
									)
									?.map(
										(item) =>
											isAdmin(item.id) && (
												<Fragment key={item.id}>
													<Menu.Item
														item={item}
														style={{ paddingLeft: '2em' }}
														onClick={handleItemClick}
													>
														{item.img && (
															<Image
																src={item.img}
																verticalAlign="middle"
																style={{
																	height: '1rem',
																	marginRight: '5px',
																}}
															/>
														)}
														{`${item.name.slice(0, 45)}${
															item.name.length > 45 ? '...' : ''
														}`}
														{item?.nick ? ` (${item.nick})` : ''}
														{menuItems[item.id] && (
															<Icon
																name={
																	activeItem === item.id
																		? 'angle down'
																		: 'angle left'
																}
															/>
														)}
													</Menu.Item>

													{activeItem === item.id &&
														menuItems[item.id]
															?.filter((sub) => sub.profile?.includes('admin'))
															?.map((sub) => (
																<Menu.Item
																	key={sub.id}
																	style={{ paddingLeft: '3em' }}
																	onClick={() =>
																		handleLinkTo(
																			top.link ?? `/${top.id}`,
																			sub.id
																		)
																	}
																>
																	{sub.name}
																	{sub.img && (
																		<Image
																			src={sub.img}
																			size="mini"
																			floated="right"
																			style={{
																				margin: 0,
																				width: '1rem',
																			}}
																		/>
																	)}
																	{sub.sports?.map((sport) => (
																		<Image
																			key={sport}
																			src={`/img/symbols/${sport}.svg`}
																			size="mini"
																			floated="right"
																			style={{
																				margin: 0,
																				height: '1rem',
																			}}
																		/>
																	))}
																</Menu.Item>
															))}
												</Fragment>
											)
									)}
						</Fragment>
					))
				)}
			</Menu>
		</Sidebar>
	);
};

export default SidebarMenu;
