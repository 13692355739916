import {
	createAsyncThunk,
	createSlice,
	SerializedError,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { api } from '../../api';
import { authRefresh } from '../auth';

export type Redirect = {
	org: string;
	redirect: string;
	path: string;
	item: string;
	title?: string;
	descr?: string;
	poster?: string;
};

interface RedirectState {
	redirects?: Redirect[];
	redirect?: Redirect;
	loading: boolean;
	error?: SerializedError;
}

const initialState: RedirectState = {
	loading: false,
	error: undefined,
};

export const redirectSlice = createSlice({
	name: 'redirect',
	initialState,
	reducers: {
		resetRedirect: (state) => {
			state.redirects = undefined;
			state.redirect = undefined;
			state.loading = false;
			state.error = undefined;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getRedirect.fulfilled, (state, { payload }) => {
				state.redirect = payload;
				state.loading = false;
				state.error = undefined;
			})
			.addCase(getRedirect.pending, (state) => {
				state.loading = true;
				state.error = undefined;
			})
			.addCase(getRedirect.rejected, (state, { payload }) => {
				state.loading = false;
				state.error = payload;
			});
		builder
			.addCase(getRedirects.fulfilled, (state, { payload }) => {
				state.redirects = payload;
				state.loading = false;
				state.error = undefined;
			})
			.addCase(getRedirects.pending, (state) => {
				state.loading = true;
				state.error = undefined;
			})
			.addCase(getRedirects.rejected, (state, { payload }) => {
				state.loading = false;
				state.error = payload;
			});
	},
});

export const getRedirect = createAsyncThunk<
	Redirect,
	Pick<Redirect, 'org' | 'redirect'>,
	{ rejectValue: SerializedError }
>(
	'redirects/getRedirect',
	async ({ org, redirect }, { rejectWithValue, dispatch }) => {
		try {
			await dispatch(authRefresh());

			const { data } = await api.redirects.get(org, redirect);

			return data as Redirect;
		} catch (error) {
			return rejectWithValue(
				(error as AxiosError).response?.data as SerializedError
			);
		}
	}
);

export const getRedirects = createAsyncThunk<
	Redirect[],
	Pick<Redirect, 'org'>,
	{ rejectValue: SerializedError }
>('redirects/getRedirects', async ({ org }, { rejectWithValue, dispatch }) => {
	try {
		await dispatch(authRefresh());

		const { data } = await api.redirects.get(org);

		return data as Redirect[];
	} catch (error) {
		return rejectWithValue(
			(error as AxiosError).response?.data as SerializedError
		);
	}
});

export const { resetRedirect } = redirectSlice.actions;

export default redirectSlice.reducer;
