import {
	createAsyncThunk,
	createSlice,
	PayloadAction,
	SerializedError,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { SemanticCOLORS } from 'semantic-ui-react';

import { api } from '../../api';
import { Currency } from '../../components/icons/currency';
import { Profile } from '../access';
import { authRefresh } from '../auth';

export type Sport =
	| 'basket'
	| 'soccer'
	| 'tennis'
	| 'volley'
	| 'swim'
	| 'hand'
	| 'gymn';

export type Period = {
	en_US?: string;
	pt_BR?: string;
	type?: 'games' | 'tiebreak';
	limit?: number;
	winner?: number;
	tiebreak?: number;
	extra?: number;
	/** time (tempo)
	 * Usado para o caso de Score ser type === 'period'
	 * identifica quantos minutos para o período
	 */
	time?: number;
	/** interval (intervalo)
	 * Usado para o caso de Score ser type === 'period'
	 * identifica quantos minutos de intervalo após o periodo terminar
	 */
	interval?: number;
	/** result (resultado)
	 * Usado para o caso de Score ser type === 'race'
	 * identifica se o resultado inserido é por tempo ou por pontos
	 */
	result?: 'time' | 'points';
	/** best (melhor)
	 * Usado para o caso de Score ser type === 'race'
	 * identifica se para vencer o resultado da corrida é o maior ou menor de todos
	 */
	best?: 'max' | 'min';
	/** calculation (calculo)
	 * Usado para o caso de Score ser type === 'race'
	 * identifica o cálculo a ser feito para chegar ao resultado da corrida
	 */
	calculation?: 'sum' | 'avg';
};

export type Score = {
	id?: string;
	name?: string;
	type?: 'score' | 'period' | 'race';
	en_US?: string;
	pt_BR?: string;
	periods?: Period[];
	/** Deprecated
	 * Valores serão calculados a partir do número de períodos */
	winner?: number;
};

export type Destination = {
	destination: 'match' | 'lesson' | 'lease' | string;
	title?: string;
	color: SemanticCOLORS;
	pt_BR?: string;
	en_US?: string;
	allocable?: boolean;
	active?: boolean;
};

export type Criteria = {
	sign:
		| 'P'
		| 'W'
		| 'T'
		| 'L'
		| 'SS'
		| 'RG'
		| 'S'
		| 'SW'
		| 'SL'
		| 'GW'
		| 'GL'
		| 'SG'
		| 'HH';
	pt_BR: string;
	en_US?: string;
	wo?: number;
	win?: number;
	tie?: number;
	loss?: number;
	active: boolean;
};

export type Formation = {
	code?: string;
	/** Title in portuguese */
	pt_BR?: string;
	/** Title in english */
	en_US?: string;
	/** The allocation in witch the ocupation shoud occur */
	dest: Destination['destination'];
	title?: string;
	/** Team or group size that actually plays */
	size: number;
	/** Number of spare players that wayts outside */
	spare?: number;
	/** Number of coaches that can subscribe to the group */
	coach?: number;
	/** Number of staff of any kind that can be part of the group (medical personel, administrative people, etc.) */
	staff?: number;
	/** Value that shuld be paid by each individual or by the group */
	each?: boolean;
	/** If paiment must be done by individual, who should be charged */
	payors?: Profile[];
	/** Value that shuld be paid */
	price?: number;
	/** Currency that shuld be paid */
	currency?: Currency;
	active?: boolean;
};

export type Surface = {
	surface: 'wood' | 'asphalt' | 'clay' | 'grass' | 'beach' | 'sand';
	pt_BR?: string;
	en_US?: string;
};

export type SportItem = {
	sport: Sport;
	symbol: string;
	surfaces?: Surface[];
	teams?: Formation[];
	criteria?: Criteria[];
	destinations?: Destination[];
	scores: Score[];
	pt_BR: string;
	en_US?: string;
};

interface SportsState {
	sports: SportItem[];
	loading: boolean;
	error?: SerializedError;
}

const initialState: SportsState = {
	sports: [],
	loading: false,
	error: undefined,
};

const sportSlice = createSlice({
	name: 'sports',
	initialState,
	reducers: {
		setSports: (state, { payload }: PayloadAction<SportItem[]>) => {
			state.sports = payload;
		},
		resetSports: (state) => {
			state.sports = initialState.sports;
			state.loading = initialState.loading;
			state.error = initialState.error;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getSports.fulfilled, (state, { payload }) => {
				state.sports = payload;
				state.loading = false;
			})
			.addCase(getSports.pending, (state) => {
				state.loading = true;
			})
			.addCase(getSports.rejected, (state, { payload }) => {
				state.error = payload;
				state.loading = false;
			});
	},
});

export const getSports = createAsyncThunk<
	SportItem[],
	void,
	{ rejectValue: SerializedError }
>('sports/getSports', async (_, { rejectWithValue, dispatch }) => {
	try {
		await dispatch(authRefresh());

		const { data } = await api.sports.get();

		return data;
	} catch (error) {
		return rejectWithValue(
			(error as AxiosError).response?.data as SerializedError
		);
	}
});

export const { setSports, resetSports } = sportSlice.actions;

export default sportSlice.reducer;
