import React from 'react';

import { Segment, Grid, Header, Button } from 'semantic-ui-react';

import { ScreenSize } from '../../hooks';

const Descubra: React.FC<{ screenSize?: ScreenSize }> = ({ screenSize }) => (
	<Segment
		id="descubra"
		style={{
			backgroundImage: 'url("/img/pe/parallax.jpg")',
			backgroundRepeat: 'no-repeat',
			backgroundAttachment: 'fixed',
			backgroundSize: 'cover',
			padding: '100px 0px',
		}}
	>
		<Grid stackable container centered>
			<Grid.Row>
				<Header
					textAlign="center"
					inverted
					color="grey"
					style={{
						textTransform: 'uppercase',
						fontStyle: 'italic',
						fontWeight: 'bolder',
					}}
				>
					Não perca tempo e descubra agora o Ponto do Esporte!
				</Header>
			</Grid.Row>
			<Grid.Row columns={2}>
				<Grid.Column textAlign="center" width={4}>
					<Button
						color="black"
						size={screenSize !== 'mobile' ? 'big' : 'large'}
						content="Encontre onde jogar..."
					/>
				</Grid.Column>
				<Grid.Column textAlign="center" width={4}>
					<Button
						color="black"
						size={screenSize !== 'mobile' ? 'big' : 'large'}
						content="Cadastre suas quadras!"
					/>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	</Segment>
);

export default Descubra;
