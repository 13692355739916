import {
	PayloadAction,
	SerializedError,
	createAsyncThunk,
	createSlice,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { api } from '../../api';
import { authRefresh } from '../auth';
import { Enrollment, EnrollType } from '../enrollments';
import { Age, Category } from '../leagues';
import { Score } from '../sports';

type Teams = {
	[key in Extract<EnrollType, 'challenger' | 'challenged'>]?: Pick<
		Enrollment,
		'id' | 'title' | 'logo' | 'descr' | 'available'
	>;
};

export type Challenge = {
	id: string;
	rank: string;
	age?: Age;
	cat?: Category;
	score?: Score;
	teams?: Teams;
	created?: string;
	closed?: boolean;
	active?: true;
};

interface ChallengesState {
	challenges: Challenge[];
	filtered: Challenge[];
	editing?: Challenge;
	loading: boolean;
	error?: SerializedError;
}

const initialState: ChallengesState = {
	challenges: [],
	filtered: [],
	loading: false,
	error: undefined,
};

export const challengeSlice = createSlice({
	name: 'challenges',
	initialState: initialState,
	reducers: {
		editChallenge: (state, { payload }: PayloadAction<Challenge>) => {
			state.editing = payload;
		},
		changeChallenge: (
			state,
			{
				payload,
			}: PayloadAction<{
				field: keyof Challenge;
				value: Age | Category | Score;
			}>
		) => {
			if (state.editing) {
				state.editing = {
					...state.editing,
					[payload.field]: payload.value,
				};
			}
		},
		filterChallenges: (
			state,
			{ payload }: PayloadAction<string | undefined>
		) => {
			state.filtered = state.challenges.filter(
				(chall) =>
					!payload ||
					Object.values(chall.teams ?? {}).some((team) =>
						team.title?.includes(payload ?? '')
					)
			);
		},
		resetChallenge: (state) => {
			state.editing = undefined;
		},
		resetChallenges: (state) => {
			state.challenges = [];
			state.filtered = [];
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getChallenges.fulfilled, (state, { payload }) => {
				state.challenges = payload ?? [];
				state.filtered = payload ?? [];
				state.loading = false;
				state.error = undefined;
			})
			.addCase(getChallenges.pending, (state) => {
				state.loading = true;
				state.error = undefined;
			})
			.addCase(getChallenges.rejected, (state, { payload }) => {
				state.error = payload;
				state.loading = false;
			});
		builder
			.addCase(insertChallenge.fulfilled, (state, { payload }) => {
				state.challenges = [
					...state.challenges.map((challenge) =>
						challenge.id === payload.id ? payload : challenge
					),
					...(!state.challenges.some((challenge) => challenge.id === payload.id)
						? [payload]
						: []),
				];
				state.filtered = [
					...state.filtered.map((challenge) =>
						challenge.id === payload.id ? payload : challenge
					),
					...(!state.filtered.some((challenge) => challenge.id === payload.id)
						? [payload]
						: []),
				];
				state.loading = false;
				state.error = undefined;
			})
			.addCase(insertChallenge.pending, (state) => {
				state.loading = true;
				state.error = undefined;
			})
			.addCase(insertChallenge.rejected, (state, { payload }) => {
				state.error = payload;
				state.loading = false;
			});
		builder
			.addCase(closeChallenge.fulfilled, (state, { payload }) => {
				state.challenges = state.challenges.map((challenge) =>
					challenge.id === payload.id ? payload : challenge
				);
				state.filtered = state.filtered.map((challenge) =>
					challenge.id === payload.id ? payload : challenge
				);
				state.loading = false;
				state.error = undefined;
			})
			.addCase(closeChallenge.pending, (state) => {
				state.loading = true;
				state.error = undefined;
			})
			.addCase(closeChallenge.rejected, (state, { payload }) => {
				state.error = payload;
				state.loading = false;
			});
	},
});

export const getChallenges = createAsyncThunk<
	Challenge[],
	{ rank: string },
	{ rejectValue: SerializedError }
>(
	'challenges/getChallenges',
	async ({ rank }, { rejectWithValue, dispatch }) => {
		try {
			await dispatch(authRefresh());

			const { data } = await api.challenges.get(rank);

			return data;
		} catch (error) {
			return rejectWithValue(
				(error as AxiosError).response?.data as SerializedError
			);
		}
	}
);

export const insertChallenge = createAsyncThunk<
	Challenge,
	Partial<Challenge> & { team?: Partial<Enrollment> },
	{ rejectValue: SerializedError }
>(
	'challenges/insertChallenge',
	async (
		{ rank, id, age, cat, score, teams },
		{ rejectWithValue, dispatch }
	) => {
		try {
			await dispatch(authRefresh());

			const { data } = await api.challenges.post({
				rank,
				id,
				age,
				cat,
				score,
				teams,
			});

			dispatch(editChallenge(data));

			return data;
		} catch (error) {
			return rejectWithValue(
				(error as AxiosError).response?.data as SerializedError
			);
		}
	}
);

export const closeChallenge = createAsyncThunk<
	Challenge,
	Challenge,
	{ rejectValue: SerializedError }
>(
	'challenges/closeChallenge',
	async (challenge: Challenge, { rejectWithValue, dispatch }) => {
		try {
			await dispatch(authRefresh());

			const { data } = await api.challenges.close(challenge);

			return data;
		} catch (error) {
			return rejectWithValue(
				(error as AxiosError).response?.data as SerializedError
			);
		}
	}
);

export const {
	editChallenge,
	filterChallenges,
	changeChallenge,
	resetChallenge,
	resetChallenges,
} = challengeSlice.actions;

export default challengeSlice.reducer;
