import {
	SerializedError,
	createAsyncThunk,
	createSlice,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { api } from '../../api';
import { authRefresh } from '../auth';
import { League, Ranking } from '../leagues';
import { Organization } from '../organizations';

export type Classification = {
	league: League;
	organization: Organization;
	rankings: Ranking[];
};

interface ClassifyState {
	classification?: Classification;
	loading: boolean;
	error?: SerializedError;
}

const initialState: ClassifyState = {
	classification: undefined,
	loading: false,
	error: undefined,
};

export const classifySlice = createSlice({
	name: 'classifications',
	initialState: initialState,
	reducers: {
		resetClassify: (state) => {
			state.classification = undefined;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getClassify.fulfilled, (state, { payload }) => {
				state.classification = payload;
				state.loading = false;
				state.error = undefined;
			})
			.addCase(getClassify.pending, (state) => {
				state.loading = true;
			})
			.addCase(getClassify.rejected, (state, { payload }) => {
				state.error = payload;
				state.loading = false;
			});
	},
});

export const getClassify = createAsyncThunk<
	Classification,
	{ rank: string },
	{ rejectValue: SerializedError }
>(
	'classifications/getClassify',
	async ({ rank }, { rejectWithValue, dispatch }) => {
		try {
			await dispatch(authRefresh());

			const response = await api.classifications.get(rank);

			return response.data;
		} catch (error) {
			return rejectWithValue(
				(error as AxiosError).response?.data as SerializedError
			);
		}
	}
);

export const { resetClassify } = classifySlice.actions;

export default classifySlice.reducer;
