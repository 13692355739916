import React, { useEffect, useState } from 'react';

import { BrowserRouter } from 'react-router-dom';

import Footer from './components/footer';
import Loader from './components/loader';
import Header from './components/menus/header';
import { useDomain, useScreenSize } from './hooks';
import Router from './routers/router';
import { authLogin } from './store/auth';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { getLanding, resetLanding } from './store/landings';
import { RightItem } from './store/rights';

export type Item =
	| 'app'
	| 'quem_somos'
	| 'onde_jogar'
	| 'descubra'
	| 'beneficios'
	| 'user'
	| 'login';

type Responsive = {
	mobile: boolean;
	desktop: boolean;
};

export type PlaceViewType = 'courts' | 'weeks' | 'directory';
export type EventViewType = 'draws' | 'registrations' | 'schedule';
export type LeagueViewType = 'ranking' | 'challenges';

export type ViewRoutes = {
	[route in PlaceViewType | EventViewType | LeagueViewType]?: AppRoute;
};

type InstitucionalType = { institucional?: boolean };
type ShowMenu = { menu?: boolean };
type ViewTypes = { views?: ViewRoutes };

export type RouteType =
	| 'home'
	| 'terms'
	| 'policy'
	| 'about'
	| 'contact'
	| 'dashboard'
	| 'places'
	| 'championship'
	| 'classification'
	| 'occasion';

export type AppRoute = Pick<
	RightItem,
	'id' | 'name' | 'icon' | 'redirect' | 'link' | 'super' | 'profile'
> &
	Responsive &
	ViewTypes &
	ShowMenu &
	InstitucionalType;

export type AppRoutes = {
	[route in RouteType]: AppRoute;
};

const routes: AppRoutes = {
	home: {
		id: 'home',
		name: 'Home',
		redirect: false,
		link: '/',
		super: 'top',
		mobile: false,
		desktop: false,
		menu: false,
		institucional: true,
	},
	terms: {
		id: 'terms',
		name: 'Termos de uso',
		redirect: false,
		link: '/terms',
		super: 'top',
		mobile: false,
		desktop: false,
		menu: false,
		institucional: true,
	},
	policy: {
		id: 'policy',
		name: 'Política de privacidade',
		redirect: false,
		link: '/policy',
		super: 'top',
		mobile: false,
		desktop: false,
		menu: false,
		institucional: true,
	},
	about: {
		id: 'about',
		name: 'Sobre nós',
		redirect: false,
		link: '/about',
		super: 'top',
		mobile: false,
		desktop: false,
		menu: false,
		institucional: true,
	},
	contact: {
		id: 'contact',
		name: 'Contato',
		redirect: false,
		link: '/contact',
		super: 'top',
		mobile: false,
		desktop: false,
		menu: false,
		institucional: true,
	},
	dashboard: {
		id: 'dashboard',
		name: 'Painel',
		icon: 'dashboard',
		redirect: true,
		link: '/dashboard',
		super: 'top',
		mobile: true,
		desktop: true,
		menu: true,
		institucional: false,
	},
	places: {
		id: 'places',
		name: 'Meus locais',
		icon: 'pin',
		redirect: false,
		link: '/places',
		super: 'top',
		mobile: false,
		desktop: true,
		menu: true,
		institucional: false,
		views: {
			courts: {
				id: 'courts',
				name: 'Quadras',
				icon: 'map marker alternate',
				super: 'places',
				mobile: true,
				desktop: true,
				profile: ['admin'],
			},
			directory: {
				id: 'directory',
				name: 'Cadastro',
				icon: 'address book outline',
				super: 'places',
				mobile: false,
				desktop: true,
				profile: ['admin'],
			},
			weeks: {
				id: 'weeks',
				name: 'Semanas',
				icon: 'calendar alternate',
				super: 'places',
				mobile: false,
				desktop: false,
				profile: ['admin'],
			},
		},
	},
	championship: {
		id: 'events',
		name: 'Meus eventos',
		icon: 'trophy',
		redirect: false,
		link: '/championship',
		super: 'top',
		mobile: true,
		desktop: true,
		menu: true,
		institucional: false,
		views: {
			registrations: {
				id: 'registrations',
				name: 'Inscrições',
				icon: 'users',
				super: 'events',
				mobile: true,
				desktop: true,
				profile: ['admin', 'player'],
			},
			draws: {
				id: 'draws',
				name: 'Chaves',
				icon: 'table',
				super: 'events',
				mobile: true,
				desktop: true,
				profile: ['admin', 'player', 'viewer'],
			},
			schedule: {
				id: 'schedule',
				name: 'Chamadas',
				icon: 'clock',
				super: 'events',
				mobile: true,
				desktop: true,
				profile: ['admin'],
			},
		},
	},
	classification: {
		id: 'leagues',
		name: 'Minhas ligas',
		icon: 'flag',
		redirect: false,
		link: '/classification',
		super: 'top',
		mobile: true,
		desktop: true,
		menu: true,
		institucional: false,
		views: {
			ranking: {
				id: 'ranking',
				name: 'Ranking',
				icon: 'ordered list',
				super: 'leagues',
				mobile: true,
				desktop: true,
				profile: ['admin', 'player'],
			},
			challenges: {
				id: 'challenges',
				name: 'Desafios',
				icon: 'handshake',
				super: 'leagues',
				mobile: true,
				desktop: true,
				profile: ['admin', 'player'],
			},
		},
	},
	occasion: {
		id: 'occasion',
		name: 'Evento',
		desktop: false,
		mobile: false,
		icon: 'trophy',
		redirect: false,
		link: '/occasion',
		institucional: false,
		super: 'top',
	},
};

const institucionalRoutes = Object.values(routes).reduce(
	(routes, route) => [
		...routes,
		...(route.institucional && !!route.link ? [route.link] : []),
	],
	[] as string[]
);

const App: React.FC = () => {
	const screenSize = useScreenSize();
	const { sub } = useDomain();
	const dispatch = useAppDispatch();

	const { logged, loading } = useAppSelector((state) => ({
		logged: state.user.logged,
		loading:
			state.plc.loading ||
			state.cmp.loading ||
			state.cls.loading ||
			state.lnd.loading,
	}));

	const RefreshToken =
		sessionStorage.getItem('RefreshToken') ||
		localStorage.getItem('RefreshToken');

	const [activeItem, setActiveItem] = useState<Item>('app');

	const [sidebar, setSidebar] = useState<boolean>(false);

	const isLanding = sub && sub !== 'hom' && sub !== 'www';

	const handleMenuClick = (name: Item) => {
		setActiveItem(name);
	};

	const handleOpenSidebar = () => {
		setSidebar(true);
	};

	const handleCloseSidebar = () => {
		setSidebar(false);
	};

	useEffect(() => {
		if (!logged) setSidebar(false);
	}, [logged]);

	useEffect(() => {
		if (!logged && RefreshToken) {
			dispatch(authLogin({ RefreshToken }));
		}
	}, [RefreshToken, logged, dispatch]);

	useEffect(() => {
		if (isLanding) {
			console.info(`Iniciando landing page ${sub}`);
			dispatch(getLanding({ id: sub }));
		} else {
			dispatch(resetLanding());
		}
	}, [dispatch, isLanding, sub]);

	return (
		<BrowserRouter>
			<Header
				activeItem={activeItem}
				openSidebar={sidebar}
				screenSize={screenSize}
				handleMenuClick={handleMenuClick}
				handleOpenSidebar={handleOpenSidebar}
				handleCloseSidebar={handleCloseSidebar}
			/>
			<Router
				routes={routes}
				activeItem={activeItem}
				openSidebar={sidebar}
				screenSize={screenSize}
				institucionalRoutes={institucionalRoutes}
				handleOpenSidebar={handleOpenSidebar}
				handleCloseSidebar={handleCloseSidebar}
			/>
			<Footer institucionalRoutes={institucionalRoutes} />
			<Loader open={loading} images={['/img/pe/loading.gif']} />
		</BrowserRouter>
	);
};

export default App;
