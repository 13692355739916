import React, { Component } from 'react';

import { Segment, Header, Icon, Modal, Button, List } from 'semantic-ui-react';

interface IProps {
	children: React.ReactNode;
}

class ErrorBoundary extends Component<IProps> {
	state: {
		open: boolean;
		error?: {
			message: string;
		};
		info?: {
			componentStack: string;
		};
	} = {
		open: false,
		error: undefined,
		info: undefined,
	};

	componentDidCatch(
		error: { message: string },
		info: { componentStack: string }
	) {
		this.setState({
			open: true,
			error,
			info,
		});
	}

	handleClose = () => this.setState({ open: false });

	render() {
		return this.state.error ? (
			<Segment placeholder textAlign="center">
				<Header>
					<Icon name="warning" />
					{this.state.error?.message}
				</Header>
				<Modal
					open={this.state.open}
					centered
					dimmer="blurring"
					onClose={this.handleClose}
				>
					<Modal.Header>{this.state.error.message}</Modal.Header>
					<Modal.Content scrolling>
						<p>{this.state.info?.componentStack}</p>
					</Modal.Content>
					<Modal.Actions>
						<List link horizontal floated="left">
							<List.Item as="a" data-openwidget-action="maximize">
								Fale conosco
							</List.Item>
						</List>
						<Button
							icon="checkmark"
							content="Fechar"
							onClick={this.handleClose}
						/>
					</Modal.Actions>
				</Modal>
			</Segment>
		) : (
			this.props.children
		);
	}
}

export default ErrorBoundary;
