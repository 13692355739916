import React, { useEffect } from 'react';

import { Segment } from 'semantic-ui-react';
import { useHeadSafe } from 'unhead';

import Descubra from './descubra';
import QuemSomos from './quemsomos';
import Vantagens from './vantagens';
import { Item } from '../../app';
import { useScreenSize } from '../../hooks';
import ErrorBoundary from '../errors/boundary';

export type HomeProps = {
	activeItem: Item;
};

const Home: React.FC<HomeProps> = ({ activeItem }) => {
	useHeadSafe({
		templateParams: {
			site: {
				name: 'Ponto do Esporte',
				url: 'https://www.pontodoesporte.com.br',
			},
		},
		titleTemplate: '%site.name',
		link: [
			{
				rel: 'shortcut icon',
				href: '/img/pe.png',
			},
		],
		meta: [
			{
				name: 'description',
				content: 'A plataforma para gestão de eventos e ligas esportivas',
			},
			{
				property: 'og:url',
				content: 'https://www.pontodoesporte.com.br',
			},
			{
				property: 'og:type',
				content: 'website',
			},
			{
				property: 'og:locale',
				content: 'pt_BR',
			},
		],
	});

	const screenSize = useScreenSize();

	useEffect(() => {
		const element = document.getElementById(activeItem);
		const y = element?.getBoundingClientRect()?.top ?? 0 + window.scrollY;
		window.scroll({
			top: y,
			behavior: 'smooth',
		});
	}, [activeItem]);

	useEffect(() => {
		const element = document.getElementById('home');
		const y = element?.getBoundingClientRect()?.top ?? 0 + window.scrollY;
		window.scroll({
			top: y,
			behavior: 'smooth',
		});
	}, []);

	return (
		<Segment
			id="home"
			basic
			style={{
				padding: 0,
				fontSize: screenSize !== 'mobile' ? '1.5rem' : '1.2rem',
			}}
		>
			<ErrorBoundary>
				<QuemSomos screenSize={screenSize} />
				<Descubra />
				<Vantagens screenSize={screenSize} />
			</ErrorBoundary>
		</Segment>
	);
};

export default Home;
