import React, { useEffect } from 'react';

import { Link } from 'react-router-dom';
import {
	Card,
	Header,
	Icon,
	Image,
	Progress,
	Statistic,
} from 'semantic-ui-react';

import Counter from './counter';
import { ScreenSize } from '../../hooks';
import { useAppDispatch } from '../../store/hooks';
import { RightItem } from '../../store/rights';
import { getStat, StatisticItem } from '../../store/statistics';

interface iTourn {
	tourn: RightItem;
	stat?: StatisticItem;
	screenSize?: ScreenSize;
}

const Tourn: React.FC<iTourn> = ({ tourn, stat, screenSize }) => {
	const dispatch = useAppDispatch();

	const entryDate = stat?.stats?.find(
		(stat) => stat.type === 'date' && stat.id === 'entry'
	);

	const beforeEntry =
		entryDate?.value &&
		new Date().getTime() < new Date(entryDate.value).getTime();

	const startDate = stat?.stats?.find(
		(stat) => stat.type === 'date' && stat.id === 'begin'
	);

	const endDate = stat?.stats?.find(
		(stat) => stat.type === 'date' && stat.id === 'end'
	);

	const gamesTotal = stat?.stats?.find(
		(stat) => stat.type === 'count' && stat.id === 'matches'
	);

	const gamesPlayed = stat?.stats?.find(
		(stat) => stat.type === 'count' && stat.id === 'played'
	);

	const walkovers = stat?.stats?.find(
		(stat) => stat.type === 'count' && stat.id === 'walkover'
	);

	const enrollments = stat?.stats?.find(
		(stat) => stat.type === 'count' && stat.id === 'enrolls'
	);

	const warning = gamesTotal?.value === 0;

	const success =
		!warning && (gamesPlayed?.value || 0) === (gamesTotal?.value || 0);

	const error =
		!success &&
		!!endDate?.value &&
		new Date(endDate?.value).valueOf() < new Date().valueOf();

	useEffect(() => {
		dispatch(getStat({ id: tourn.id, type: 'tourn' }));
	}, [dispatch, tourn.id]);

	return (
		<Card
			as={Link}
			to={`/championship/${tourn.id}`}
			centered={screenSize === 'mobile'}
		>
			<Card.Content
				as={Header}
				textAlign={screenSize !== 'mobile' ? 'left' : 'center'}
			>
				{tourn.name}
				{tourn.img && screenSize !== 'mobile' && (
					<Image src={tourn.img} size="mini" floated="right" />
				)}
			</Card.Content>
			{stat?.loading ? (
				<Card.Content textAlign="center">
					<Icon name="spinner" color="black" loading size="big" />
				</Card.Content>
			) : (
				<Card.Content textAlign="center">
					{screenSize === 'mobile' && (
						<Header textAlign="center" size="tiny">
							{tourn.img && <Image src={tourn.img} centered />}
							<Header.Content>
								{beforeEntry
									? new Date(entryDate.value).toLocaleDateString()
									: startDate?.value
										? new Date(startDate?.value).toLocaleDateString()
										: ''}
								<Header.Subheader>
									{beforeEntry ? entryDate.name : startDate?.name}
								</Header.Subheader>
							</Header.Content>
						</Header>
					)}
					{screenSize !== 'mobile' && entryDate && (
						<Statistic size="mini">
							<Statistic.Label>{entryDate?.name}</Statistic.Label>
							<Statistic.Value>
								{entryDate?.value
									? new Date(entryDate?.value).toLocaleDateString()
									: ''}
							</Statistic.Value>
						</Statistic>
					)}
					{screenSize !== 'mobile' && startDate && (
						<Statistic size="mini">
							<Statistic.Label>{startDate?.name}</Statistic.Label>
							<Statistic.Value>
								{startDate?.value
									? new Date(startDate?.value).toLocaleDateString()
									: ''}
							</Statistic.Value>
						</Statistic>
					)}
					{screenSize !== 'mobile' && endDate && (
						<Statistic size="mini">
							<Statistic.Label>{endDate?.name}</Statistic.Label>
							<Statistic.Value>
								{endDate?.value
									? new Date(endDate?.value).toLocaleDateString()
									: ''}
							</Statistic.Value>
						</Statistic>
					)}
				</Card.Content>
			)}
			{tourn.profile?.includes('admin') && screenSize !== 'mobile' && (
				<Card.Description textAlign="center">
					<Statistic size="mini">
						<Statistic.Value>
							<Icon.Group size="small">
								<Icon name="edit outline" />
							</Icon.Group>{' '}
							<Counter
								initial={0}
								target={+(enrollments?.value ?? 0)}
								easing="quadratic"
							/>
						</Statistic.Value>
						<Statistic.Label>{enrollments?.name}</Statistic.Label>
					</Statistic>
					<Statistic size="mini">
						<Statistic.Value>
							<Icon.Group size="small">
								<Icon name="calendar outline" />
							</Icon.Group>{' '}
							<Counter
								initial={0}
								target={+(gamesTotal?.value ?? 0)}
								easing="quadratic"
							/>
						</Statistic.Value>
						<Statistic.Label>jogos</Statistic.Label>
					</Statistic>
					<Statistic size="mini">
						<Statistic.Value>
							<Icon.Group size="small">
								<Icon name="clock outline" />
								<Icon corner name="x" />
							</Icon.Group>{' '}
							<Counter
								initial={0}
								target={+(walkovers?.value ?? 0)}
								easing="quadratic"
							/>
						</Statistic.Value>
						<Statistic.Label>{walkovers?.name}</Statistic.Label>
					</Statistic>
				</Card.Description>
			)}
			{tourn.profile?.includes('admin') && screenSize !== 'mobile' && (
				<Card.Content extra>
					<Progress
						label={`${gamesPlayed?.name} / ${gamesTotal?.name}`}
						percent={(
							((+(gamesPlayed?.value ?? 0) + +(walkovers?.value ?? 0)) /
								+(gamesTotal?.value ?? 0)) *
							100
						).toFixed(0)}
						progress
						indicating={true}
						success={success}
						warning={warning}
						error={error}
					/>
				</Card.Content>
			)}
		</Card>
	);
};

export default Tourn;
