import React from 'react';

import { Grid, Header, Image } from 'semantic-ui-react';

import { ScreenSize } from '../../hooks';

const QuemSomos: React.FC<{ screenSize?: ScreenSize }> = ({ screenSize }) => {
	return (
		<Grid
			id="quem_somos"
			container
			stackable
			style={{ padding: `${screenSize === 'mobile' ? '60px' : '100px'} 0px` }}
		>
			<Grid.Row centered>
				<Header
					textAlign="center"
					style={{
						textTransform: 'uppercase',
						fontStyle: 'italic',
						fontWeight: 'bolder',
					}}
				>
					Quem somos
				</Header>
			</Grid.Row>
			<Grid.Row columns="equal">
				<Grid.Column width={4}>
					<Image
						src="img/pontodoesporte.jpg"
						centered
						size={screenSize !== 'mobile' ? 'medium' : 'small'}
					/>
				</Grid.Column>
				<Grid.Column>
					<p>
						O Ponto do Esporte é uma plataforma que auxilia quem é promotor do
						esporte a melhorar seus processos e criar uma interação maior com
						seus jogadores, facilitando a vida de esportistas e donos de
						quadras. Integra pessoas interessadas em praticar o esporte aos
						diferentes locais e eventos, criando uma otimização o uso das
						dependências.
					</p>
					<p>
						Criamos ferramentas que ajudem as pessoas e entidades do esporte a
						interagirem, promovemos eventos voltados à melhoria do ecossistema
						do desporto, e realizamos estudos e treinamentos para entender e
						direcionar os profissionais do esporte.
					</p>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);
};

export default QuemSomos;
