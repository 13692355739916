import {
	SerializedError,
	createAsyncThunk,
	createSlice,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { api } from '../../api';
import { Permission, Profile } from '../access';
import { authRefresh } from '../auth';

type Stat = {
	id:
		| 'entry'
		| 'begin'
		| 'end'
		| 'courts'
		| 'enrolls'
		| 'matches'
		| 'played'
		| 'walkover'
		| 'balance';
	name: string;
	type: 'count' | 'date';
	value: number | string;
};

export interface StatisticItem {
	type: Permission;
	id: string;
	name?: string;
	nick?: string;
	img?: string;
	super?: string;
	place?: string;
	profile?: Profile[];
	stats?: Stat[];
	loading?: boolean;
	error?: SerializedError;
}

interface StatsState {
	stats?: StatisticItem[];
	loading: boolean;
	error?: SerializedError;
}

const initialState: StatsState = {
	loading: false,
};

export const statSlice = createSlice({
	name: 'statistics',
	initialState,
	reducers: {
		resetStats: (state) => {
			state.stats = undefined;
			state.loading = false;
			state.error = undefined;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getStat.fulfilled, (state, { payload }) => {
				state.stats = (state.stats ?? []).map((stat) =>
					stat.id === payload.id ? payload : stat
				);
			})
			.addCase(getStat.pending, (state, { meta }) => {
				state.stats = (state.stats ?? []).concat({
					...meta.arg,
					loading: true,
				});
			})
			.addCase(getStat.rejected, (state, { payload, meta }) => {
				state.stats = (state.stats ?? []).map((stat) =>
					stat.id === meta.arg.id
						? { ...stat, loading: false, error: payload }
						: stat
				);
			});
	},
});

export const getStat = createAsyncThunk<
	StatisticItem,
	{ id: string; type: Permission },
	{ rejectValue: SerializedError }
>('statistics/getStat', async ({ id, type }, { dispatch, rejectWithValue }) => {
	try {
		await dispatch(authRefresh());

		const { data } = await api.statistics.get(id, type);

		return data;
	} catch (error) {
		return rejectWithValue(
			(error as AxiosError).response?.data as SerializedError
		);
	}
});

export const { resetStats } = statSlice.actions;

export default statSlice.reducer;
